import { z } from 'zod';
import { ProcessSchema } from './Process';

export const StepSchema = z.object({
  id: z.number(),
  name: z.string().nonempty(),
  process: ProcessSchema,
  isDeleted: z.boolean().optional().nullable(),
  orderPosition: z.number().optional(),
});

export type Step = z.infer<typeof StepSchema>;
