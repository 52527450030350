import { Outlet, Route, Routes } from 'react-router-dom';
import { ServerErrorBox } from '@top-solution/microtecnica-mui';
import { AuthGuard } from '@top-solution/microtecnica-utils';
import { NotFoundPage, UnauthorizedPage } from '..';
import { FullPageProgress } from '../../components/Progress';
import { AndonPlantDashboard } from '../Andon';
import { CellRouter } from '../Cell';
import KaizenListByPlant from '../Kaizen/KaizenListByPlant';
import { PlantProvider } from './PlantContext';
import PlantPage from './PlantPage';
import { usePlantFromUrl } from './usePlantFromUrl';

function PlantWrapper(): JSX.Element {
  const { error, plant, isLoading } = usePlantFromUrl();

  if (error) {
    return <ServerErrorBox error={error} sx={{ marginX: 'auto' }} />;
  }

  if (isLoading) {
    return <FullPageProgress />;
  }
  return (
    <PlantProvider plant={plant!}>
      <Outlet />
    </PlantProvider>
  );
}

export default function PlantRouter(): JSX.Element {
  return (
    <AuthGuard unauthorizedFallback={<UnauthorizedPage />}>
      <Routes>
        <Route path="/:plantId" element={<PlantWrapper />}>
          <Route path="cells" element={<PlantPage />} />
          <Route path="andon" element={<AndonPlantDashboard />} />
          <Route path="kaizen" element={<KaizenListByPlant />} />
          <Route path="cells/*" element={<CellRouter />} />
          <Route path="*" element={<NotFoundPage />} />
        </Route>
      </Routes>
    </AuthGuard>
  );
}
