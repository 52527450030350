import React, { useCallback } from 'react';
import { SettingsItem } from '../../../../entities/SettingsItem';
import {
  useCreateKaizenITMutation,
  useReadKaizenITListQuery,
  useRemoveKaizenITMutation,
  useUpdateKaizenITMutation,
} from '../../../../services/kaizenITApi';

import { ItemAddDialog, ItemEditDialog } from '../ItemDialog';
import SettingsList from '../SettingsList';

export default function KaizenITList(): React.ReactElement {
  const { data, isLoading, error } = useReadKaizenITListQuery();
  const [remove, removeState] = useRemoveKaizenITMutation();
  const [create, createState] = useCreateKaizenITMutation();
  const [update, updateState] = useUpdateKaizenITMutation();

  const handleRemove = useCallback(
    async (vendor: SettingsItem) => {
      return remove(vendor);
    },
    [remove],
  );

  const handleUpdate = useCallback(
    async (vendor: SettingsItem) => {
      return update(vendor);
    },
    [update],
  );

  const handleCreate = useCallback(
    async (values: SettingsItem) => {
      return create({ ...values });
    },
    [create],
  );
  return (
    <SettingsList
      title="IT"
      items={data?.filter((i) => !i.isDeleted)}
      readListRequest={{ isLoading, error }}
      itemRemove={handleRemove}
      itemRemoveRequest={removeState}
      addProps={{
        AddDialog: ItemAddDialog,
        submit: handleCreate,
        request: createState,
      }}
      editPros={{
        EditDialog: ItemEditDialog,
        submit: handleUpdate,
        request: updateState,
      }}
    />
  );
}
