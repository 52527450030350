import { useCallback, useMemo, useState } from 'react';
import {
  GridFilterModel,
  GridLogicOperator,
  GridPinnedColumnFields,
  GridRowModel,
  GridSortModel,
} from '@mui/x-data-grid-premium';
import {
  DataGrid,
  DataGridProps,
  ErrorAlert,
  GridToolbarExportAll,
  muiFiltersToPagedRequestFilters,
  usePagination,
} from '@top-solution/microtecnica-mui';
import { Cell } from '../../entities/Cell';
import { useLazyReadKaizenListQuery, useReadKaizenListQuery } from '../../services/kaizenApi';
import { usePlant } from '../Plant/PlantContext';
import { useColumns } from './columns';

const pinnedColumns: GridPinnedColumnFields = { right: ['actions'] };

type KaizenListGridProps = Omit<
  DataGridProps,
  | 'rows'
  | 'columns'
  | 'loading'
  | 'error'
  | 'pagination'
  | 'paginationMode'
  | 'rowCount'
  | 'page'
  | 'onPageChange'
  | 'pageSize'
  | 'onPageSizeChange'
  | 'sortingMode'
  | 'sortModel'
  | 'onSortModelChange'
  | 'filterMode'
  | 'onFilterModelChange'
  | 'filterModel'
> & {
  columns?: DataGridProps['columns'];
  cell?: Cell;
};

export default function KaizenListGrid(props: KaizenListGridProps): JSX.Element {
  const { columns, cell, ...gridProps } = props;
  const { plant } = usePlant();
  const { paginationModel, setPaginationModel } = usePagination(0);
  const [sortModel, setSortModel] = useState<GridSortModel>([{ field: 'id', sort: 'desc' }]);
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [],
    logicOperator: GridLogicOperator.And,
  });

  const readParams = useMemo(
    () => ({
      ...(cell ? { cellId: cell.id } : { plantId: plant.id }),
      limit: paginationModel.pageSize,
      offset: paginationModel.pageSize * paginationModel.page,
      sort: sortModel.map(({ field, sort }) => `${sort === 'desc' ? '-' : ''}${field}`),
      filters: muiFiltersToPagedRequestFilters(filterModel.items),
    }),
    [cell, filterModel.items, paginationModel.page, paginationModel.pageSize, plant.id, sortModel]
  );

  const handleSortModelChange = useCallback(
    (sortModel: GridSortModel) => {
      setSortModel(sortModel);
      setPaginationModel({ page: 0 });
    },
    [setPaginationModel]
  );

  const handleFilterModelChange = useCallback(
    (filterModel: GridFilterModel) => {
      setFilterModel(filterModel);
      setPaginationModel({ page: 0 });
    },
    [setPaginationModel]
  );
  const kaizenColumns = useColumns();
  const { isLoading, data, error } = useReadKaizenListQuery(readParams);

  const rows = useMemo<GridRowModel[]>(() => data?.data.map((item) => ({ ...item })) ?? [], [data]);

  function ExportAllToolbar() {
    const [readList] = useLazyReadKaizenListQuery();

    return (
      <GridToolbarExportAll
        onRowsRequest={async () => {
          const res = await readList({
            ...readParams,
            limit: 10 ** 5,
            offset: 0,
          }).unwrap();

          return res.data;
        }}
      />
    );
  }

  if (error) {
    return <ErrorAlert error={error} />;
  }

  return (
    <DataGrid
      density="compact"
      rows={rows}
      columns={columns || kaizenColumns}
      loading={isLoading}
      pagination
      paginationMode="server"
      rowCount={data?.total ?? 0}
      paginationModel={paginationModel}
      onPaginationModelChange={setPaginationModel}
      sortingMode="server"
      sortModel={sortModel}
      onSortModelChange={handleSortModelChange}
      filterMode="server"
      onFilterModelChange={handleFilterModelChange}
      filterModel={filterModel}
      pinnedColumns={pinnedColumns}
      slots={{ footer: ExportAllToolbar }}
      {...gridProps}
    />
  );
}
