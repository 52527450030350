import React, { useMemo, useState, useCallback } from 'react';
import Button from '@mui/material/Button';
import { GridActionsCellItem, GridColDef, GridRowParams, MuiEvent } from '@mui/x-data-grid-premium';
import { DataGridWrapper } from '@top-solution/microtecnica-mui';
import { useAuth } from '@top-solution/microtecnica-utils';
import { DeleteIcon, DetailsIcon, EditIcon, StateMachineIcon } from '../../components/Icons';
import { KAIZEN_STATUS, Kaizen } from '../../entities/Kaizen';
import { useCell } from '../Cell/CellContext';
import { useColumns } from './columns';
import { KaizenAddButton } from './KaizenAddButton';
import { KaizenDeleteConfirm } from './KaizenDeleteConfirm';
import { KaizenDetailDialog } from './KaizenDetail';
import { KaizenDialog } from './KaizenDialog';
import KaizenListGrid from './KaizenListGrid';
import { KaizenStatusDialog } from './KaizenStatusDialog';

export default function KaizenListByCell(): React.ReactElement {
  const { username, isAdmin } = useAuth();
  const { cell } = useCell();
  const kaizenColumns = useColumns();
  const [kaizenToRemove, setKaizenToRemove] = useState<Kaizen | null>(null);
  const [kaizenToUpdate, setKaizenToUpdate] = useState<Kaizen | null>(null);
  const [kaizenToUpdateStatus, setKaizenToUpdateStatus] = useState<Kaizen | null>(null);
  const [kaizenToView, setKaizenToView] = useState<Kaizen | null>(null);

  const handleDetailClick = useCallback(
    ({ row }: GridRowParams<Kaizen>) =>
      (event: MuiEvent<React.MouseEvent>) => {
        event.stopPropagation();
        setKaizenToView(row);
      },
    []
  );

  const handleEditClick = useCallback(
    ({ row }: GridRowParams<Kaizen>) =>
      (event: MuiEvent<React.MouseEvent>) => {
        event.stopPropagation();
        setKaizenToUpdate(row);
      },
    []
  );

  const handleStatusUpdateClick = useCallback(
    ({ row }: GridRowParams<Kaizen>) =>
      (event: MuiEvent<React.MouseEvent>) => {
        event.stopPropagation();
        setKaizenToUpdateStatus(row);
      },
    []
  );

  const handleDeleteClick = useCallback(
    ({ row }: GridRowParams<Kaizen>) =>
      (event: MuiEvent<React.MouseEvent>) => {
        event.stopPropagation();
        setKaizenToRemove(row);
      },
    []
  );

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        headerName: 'ID',
        field: 'id',
        width: 60,
        type: 'number',
        renderCell: ({ row }) => (
          <Button size="small" color="primary" onClick={() => setKaizenToView(row)}>
            {row.id}
          </Button>
        ),
      },
      ...kaizenColumns.filter((c) => c.field !== 'cell'),
      {
        field: 'actions',
        type: 'actions',
        width: 120,
        renderHeader: () => <KaizenAddButton />,
        getActions: (params: GridRowParams<Kaizen>) => [
          params.row.status.id === KAIZEN_STATUS.OPEN && params.row.openBy === username ? (
            <GridActionsCellItem icon={<EditIcon />} key="edit" label="Modifica" onClick={handleEditClick(params)} />
          ) : (
            <GridActionsCellItem
              icon={<DetailsIcon />}
              key="view"
              label="Dettaglio"
              onClick={handleDetailClick(params)}
            />
          ),
          cell.kaizensAdminList?.find((m) => m === username) ? (
            <GridActionsCellItem
              icon={<StateMachineIcon />}
              key="edit-status"
              label="Modifica Status"
              onClick={handleStatusUpdateClick(params)}
            />
          ) : (
            <></>
          ),
          <GridActionsCellItem
            icon={<DeleteIcon />}
            key="delete"
            label="Elimina"
            onClick={handleDeleteClick(params)}
            disabled={
              !isAdmin && (params.row.status.id !== KAIZEN_STATUS.TAKEN_CHARGE || params.row.openBy !== username)
            }
          />,
        ],
      },
    ],
    [
      cell.kaizensAdminList,
      handleDeleteClick,
      handleDetailClick,
      handleEditClick,
      handleStatusUpdateClick,
      isAdmin,
      kaizenColumns,
      username,
    ]
  );

  return (
    <>
      <DataGridWrapper>
        <KaizenListGrid columns={columns} sessionStorageId={`KaizenListByCell${cell.name}`} cell={cell} />
      </DataGridWrapper>
      {kaizenToUpdate && (
        <KaizenDialog
          onClose={() => setKaizenToUpdate(null)}
          open={Boolean(kaizenToUpdate)}
          initialValues={{ ...kaizenToUpdate, typeId: kaizenToUpdate.type.id }}
          kaizen={kaizenToUpdate}
        />
      )}
      {kaizenToRemove && (
        <KaizenDeleteConfirm
          open={Boolean(kaizenToRemove)}
          data={kaizenToRemove}
          onClose={() => setKaizenToRemove(null)}
        />
      )}
      {kaizenToUpdateStatus && (
        <KaizenStatusDialog
          open={Boolean(kaizenToUpdateStatus)}
          kaizen={kaizenToUpdateStatus}
          onClose={() => setKaizenToUpdateStatus(null)}
        />
      )}
      {kaizenToView && (
        <KaizenDetailDialog open={Boolean(kaizenToView)} kaizen={kaizenToView} onClose={() => setKaizenToView(null)} />
      )}
    </>
  );
}
