import { z } from 'zod';

const LevelSchema = z.object({
  id: z.number(),
  time: z.number(),
});

export const ContactSchema = z.object({
  id: z.number(),
  level: LevelSchema,
  name: z.string().nonempty(),
  lastname: z.string().nonempty(),
  telephone: z.string().nonempty(),
});

export type Contact = z.infer<typeof ContactSchema>;
