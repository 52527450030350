import { useMemo } from 'react';
import { GridSingleSelectColDef } from '@mui/x-data-grid-premium';
import { Turnback } from '../../entities/Turnback';
import { useReadCustomerListQuery } from '../../services/customerApi';

export function useTurnbackCustomerDataGridColumn(): GridSingleSelectColDef {
  const { data } = useReadCustomerListQuery();

  const valueOptions = useMemo(() => data?.map((v) => ({ value: v.id, label: v.name })), [data]);
  return useMemo(
    () => ({
      field: 'customerId',
      headerName: 'Customer',
      type: 'singleSelect',
      valueOptions,
      valueGetter: (value, row: Turnback) => {
        return row.customer?.id;
      },
    }),
    [valueOptions]
  );
}
