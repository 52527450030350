import { styled } from '@mui/material/styles';

export const Term = styled('dt')(({ theme }) => ({
  color: theme.palette.grey[600],
}));

export const Value = styled('dd')(({ theme }) => ({
  margin: theme.spacing(0, 0, 2, 1),
  fontSize: theme.typography.body1.fontSize,
}));
