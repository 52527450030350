import { ReactNode, createContext, useContext } from 'react';
import { Cell } from '../../entities/Cell';

interface CellContextType {
  cell: Cell;
}

export const CellContext = createContext<CellContextType>({
  cell: null as unknown as Cell,
});

export function CellProvider(props: { cell: Cell; children: ReactNode | ReactNode[] }): JSX.Element {
  return <CellContext.Provider value={{ cell: props.cell }}>{props.children}</CellContext.Provider>;
}

export const useCell = () => {
  return useContext(CellContext);
};
