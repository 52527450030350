import React, { useCallback } from 'react';
import { DropResult } from '@hello-pangea/dnd';
import { Process } from '../../../../entities/Process';
import { SettingsItemForm } from '../../../../entities/SettingsItem';
import { Step } from '../../../../entities/Step';
import {
  useCreateStepMutation,
  useRemoveStepMutation,
  useUpdateProcessStepListMutation,
  useUpdateStepMutation,
} from '../../../../services/stepApi';
import { ItemAddDialog, ItemEditDialog } from '../ItemDialog';
import SettingsList, { BasicRequest } from '../SettingsList';
import reorder from './reorder';

interface StepSettingsListProps {
  process?: Process;
  stepList?: Step[];
  readListRequest: BasicRequest;
  onSelectedStepChange: (step: Step) => void;
  selectedStep?: Step;
}

export default function StepSettingsList(props: StepSettingsListProps): React.ReactElement {
  const { stepList, readListRequest, selectedStep, onSelectedStepChange, process } = props;

  const [remove, removeState] = useRemoveStepMutation();
  const [create, createState] = useCreateStepMutation();
  const [update, updateState] = useUpdateStepMutation();
  const [updateList] = useUpdateProcessStepListMutation();

  const handleRemove = useCallback(
    async (step: Step) => {
      return remove({ ...step, processId: process!.id });
    },
    [remove, process],
  );

  const handleUpdate = useCallback(
    async (step: Step) => {
      return update({ ...step, processId: process!.id });
    },
    [process, update],
  );

  const handleCreate = useCallback(
    async (values: SettingsItemForm) => {
      return create({ ...values, processId: process!.id });
    },
    [create, process],
  );

  const handleDragEnd = useCallback(
    async (result: DropResult) => {
      if (!result.destination || !stepList) {
        return;
      }
      if (result.source.index === result.destination.index) {
        return;
      }

      const newStepList: Step[] = reorder(stepList, result.source.index, result.destination.index);
      return updateList({
        processId: process!.id,
        stepList: newStepList.map((p, i) => ({ ...p, orderPosition: i })),
      });
    },
    [stepList, updateList, process],
  );

  return (
    <SettingsList
      title="Step"
      emptyText="Seleziona un processo per gestirne gli step"
      items={stepList}
      readListRequest={readListRequest}
      selectedItem={selectedStep}
      onSelectedItemChange={onSelectedStepChange}
      itemRemove={handleRemove}
      itemRemoveRequest={removeState}
      addProps={{
        AddDialog: ItemAddDialog,
        submit: handleCreate,
        request: createState,
      }}
      editPros={{
        EditDialog: ItemEditDialog,
        submit: handleUpdate,
        request: updateState,
      }}
      isDroppable
      onDragEnd={handleDragEnd}
    />
  );
}
