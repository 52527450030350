import { useMemo } from 'react';
import Chip from '@mui/material/Chip';
import { GridRenderCellParams, GridSingleSelectColDef } from '@mui/x-data-grid-premium';
import { ANDON_STATUS } from '../../entities/Andon';

export function AndonStatusChip(props: { status: ANDON_STATUS }): React.ReactElement {
  const { status } = props;

  const style = useMemo(() => {
    switch (status) {
      case ANDON_STATUS.OPEN:
        return { bgcolor: 'info.main', color: 'info.contrastText' };
      case ANDON_STATUS.IN_PROGRESS:
        return { bgcolor: 'success.main', color: 'success.contrastText' };
      case ANDON_STATUS.CLOSED:
        return { bgcolor: 'warning.main', color: 'warning.contrastText' };
      default:
        return { bgcolor: 'common.white', color: 'common.black', border: 1 };
    }
  }, [status]);

  return (
    <Chip
      label={status}
      sx={{
        textTransform: 'capitalize',
        width: 120,
        ...style,
      }}
    />
  );
}

export function useAndonStatusDataGridColumn(): GridSingleSelectColDef {
  const valueOptions = useMemo(() => Object.values(ANDON_STATUS).map((s) => ({ value: s, label: s })), []);
  return useMemo(
    () => ({
      field: 'status',
      headerName: 'Stato',
      type: 'singleSelect',
      valueOptions,
      renderCell: ({ value }: GridRenderCellParams) => <AndonStatusChip status={value} />,
    }),
    [valueOptions],
  );
}
