import React, { useEffect } from 'react';
import { skipToken } from '@reduxjs/toolkit/query';
import { usePreviousDifferent } from 'rooks';
import { useSound } from 'use-sound';
import Box, { BoxProps } from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { SxProps } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { AuthGuard } from '@top-solution/microtecnica-utils';
import { CheckIcon } from '../../components/Icons';
import { Layout } from '../../components/Layout';
import { ANDON_STATUS, Andon } from '../../entities/Andon';
import { useReadAndonDashboardListQuery, useReadAndonDashboardPlantListQuery } from '../../services/andonApi';
import UnauthorizedPage from '../Error/UnauthorizedPage';
import { usePlant } from '../Plant/PlantContext';
import { andonDashboard } from '../sections';
import alarmSfx from './alarm.mp3';
import { TechnicalSupport } from './TechnicalSupport';
import useTime from './useTime';
import WaitingTime from './WaitingTime';

type LabelValueProps = BoxProps & {
  label: string;
  value: React.ReactNode;
};

function LabelValue(props: LabelValueProps) {
  const { label, value, sx, ...boxProps } = props;
  return (
    <Box sx={{ color: 'inherit', ...sx }} {...boxProps}>
      <Box component={'span'}>{label}</Box>
      <Box sx={{ fontWeight: 700 }}>{value}</Box>
    </Box>
  );
}

const centeredContentSx: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
};

const breadcrumbs = [{ title: andonDashboard.title, url: andonDashboard.url }];

export function AndonDashboard(): React.ReactElement {
  const { data: andonList, error, isLoading } = useReadAndonDashboardListQuery();

  return (
    <AuthGuard unauthorizedFallback={<UnauthorizedPage />}>
      <Layout title="Dashboard Andon" error={error} inProgress={isLoading} breadcrumbs={breadcrumbs} maxWidth={false}>
        <AndonDashboardContent andonList={andonList?.data || []} />
      </Layout>
    </AuthGuard>
  );
}

export function AndonPlantDashboard(): React.ReactElement {
  const { plant } = usePlant();
  const { data: andonList, error, isLoading } = useReadAndonDashboardPlantListQuery(plant?.id ?? skipToken);

  const plantBreadcrumbs = [
    {
      title: plant?.name,
      url: `/plants/${plant?.id}/cells`, // TODO: Store this in section and replace plantId
    },
    { title: andonDashboard.title },
  ];

  return (
    <AuthGuard unauthorizedFallback={<UnauthorizedPage />}>
      <Layout
        title="Dashboard Andon"
        error={error}
        inProgress={isLoading}
        breadcrumbs={plantBreadcrumbs}
        maxWidth={false}
        disableGutters
      >
        <AndonDashboardContent andonList={andonList?.data || []} />
      </Layout>
    </AuthGuard>
  );
}

export function AndonDashboardContent(props: { andonList: Andon[] }): React.ReactElement {
  const { andonList } = props;
  const time = useTime();
  const [playSound] = useSound(alarmSfx);
  const previousAndonList = usePreviousDifferent(andonList);

  useEffect(() => {
    if (
      previousAndonList &&
      andonList &&
      andonList.filter(
        (andon) => andon.status !== ANDON_STATUS.CLOSED && !previousAndonList.find(({ id }) => id === andon.id)
      ).length > 0
    ) {
      playSound();
    }
  }, [andonList, playSound, previousAndonList]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        height: 'calc(100vh - 64px)',
      }}
    >
      {andonList && andonList.length === 0 ? (
        <Typography
          sx={{
            alignSelf: 'center',
            border: 1,
            borderColor: 'success.main',
            color: 'success.dark',
            fontWeight: 500,
            fontSize: '5vw',
            textAlign: 'center',
            padding: 4,
          }}
        >
          Non sono presenti ANDON
          <CheckIcon sx={{ display: 'block', marginX: 'auto', marginTop: 2, height: '6vw', width: '6vw' }} />
        </Typography>
      ) : (
        andonList?.map((andon) => (
          <Box
            key={andon.id}
            sx={{
              display: 'flex',
              alignItems: 'center',
              flex: '1 0 0%',
              marginY: 1,
              maxHeight: '50%',
              fontSize: '2vw',
              color: 'text.main',
              bgcolor:
                andon.status === ANDON_STATUS.OPEN
                  ? 'error.light'
                  : andon.status === ANDON_STATUS.IN_PROGRESS
                    ? 'warning.main'
                    : 'success.main',
            }}
          >
            <Grid container>
              <Grid item xs={12} sm={5} p={2} sx={centeredContentSx}>
                <LabelValue
                  label="Reparto"
                  value={`${andon.plant?.name}/${andon.workstation.cell.name}`}
                  sx={{ marginBottom: 2 }}
                />
                <LabelValue label="Postazione macchina" value={andon.workstation.name} sx={{ fontSize: '1.5vw' }} />
                <LabelValue label="Problema" value={andon.problemType.name} sx={{ fontSize: '1.5vw' }} />
              </Grid>
              <Grid item xs={12} sm={4} p={2} sx={centeredContentSx}>
                <Typography variant="h6">Supporto tecnico</Typography>
                <Box sx={{ fontWeight: 700 }}>
                  <TechnicalSupport andon={andon} />
                </Box>
              </Grid>
              <Grid item xs={12} sm={3} p={2} sx={centeredContentSx}>
                <Typography sx={{ fontSize: '2vw' }}>
                  {andon.status === ANDON_STATUS.OPEN
                    ? 'Durata attesa'
                    : andon.status === ANDON_STATUS.IN_PROGRESS
                      ? 'Durata intervento'
                      : 'Durata totale'}
                </Typography>
                <Typography sx={{ fontSize: '5vw', fontWeight: 700 }}>
                  <WaitingTime
                    start={
                      andon.status === ANDON_STATUS.IN_PROGRESS && andon.arrivalTime
                        ? new Date(andon.arrivalTime)
                        : andon.createDate
                          ? new Date(andon.createDate)
                          : undefined
                    }
                    end={andon.status === ANDON_STATUS.CLOSED && andon.closingTime ? new Date(andon.closingTime) : time}
                    shortFormat
                  />
                </Typography>
              </Grid>
            </Grid>
          </Box>
        ))
      )}
    </Box>
  );
}
