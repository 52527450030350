import { PlantSchema } from '@top-solution/microtecnica-utils';
import { z } from 'zod';
import { AttachmentSchema } from './Attachment';
import { CategorySchema } from './Category';
import { CellSchema } from './Cell';
import { CustomerSchema } from './Customer';
import { VendorSchema } from './Vendor';

export enum TURNBACK_STATUS {
  OPEN = 'aperto',
  WIP = 'wip',
  CLOSED = 'chiuso',
}

export const TurnbackSchema = z.object({
  id: z.number(),
  status: z.nativeEnum(TURNBACK_STATUS),
  isRtpr: z.boolean(),
  responsibleCell: CellSchema.optional(),
  reportedBy: z.string().nonempty(),
  plant: PlantSchema.omit({ slug: true }),
  partNumber: z.string().min(2, 'Inserire una valore valido').optional().or(z.literal('')),
  openingComments: z.string().optional(),
  openBy: z.string().nonempty(),
  occurrences: z.number(),
  notificationEmailList: z.array(z.string().email()).optional(),
  customer: CustomerSchema.optional(),
  createDate: z.string(),
  closingComments: z.string().optional(),
  closedBy: z.string().optional(),
  closeDate: z.string().optional(),
  cell: CellSchema,
  category: CategorySchema,
  attachmentList: z.array(AttachmentSchema).optional(),
  vendor: VendorSchema.optional(),
});

export const TurnbackAddFormSchema = TurnbackSchema.pick({
  occurrences: true,
  openingComments: true,
  partNumber: true,
  reportedBy: true,
  isRtpr: true,
  attachmentList: true,
}).extend({
  createDate: z.date(),
  categoryId: z.number(),
  processId: z.number(),
  stepId: z.number(),
  customerId: z.number().nullish(),
  vendorId: z.number().nullish(),
  responsibleCellId: z.number().nullish(),
  notificationEmailList: z.array(
    z.string().email({
      message: 'Deve contenere solo email valide',
    }),
  ),
});

export const TurnbackStatusFormSchema = TurnbackSchema.pick({
  openingComments: true,
  closingComments: true,
  status: true,
  isRtpr: true,
});

export const TurnbackStatSchema = z.object({
  month: z.string(),
  totalTb: z.number(),
  processes: z.array(z.object({ id: z.number(), name: z.string(), totalTb: z.number() })),
  steps: z.array(z.object({ id: z.number(), name: z.string(), processId: z.number(), totalTb: z.number() })),
  categories: z.array(z.object({ id: z.number(), name: z.string(), stepId: z.number(), totalTb: z.number() })),
  partNumbers: z.array(z.object({ pn: z.string().nonempty(), categoryId: z.number(), totalTb: z.number() })),
});

export type Turnback = z.infer<typeof TurnbackSchema>;
export type TurnbackAddForm = z.infer<typeof TurnbackAddFormSchema>;
export type TurnbackStatusForm = z.infer<typeof TurnbackStatusFormSchema>;
export type TurnbackStat = z.infer<typeof TurnbackStatSchema>;
