import { z } from 'zod';
import { CellSchema } from './Cell';

export const ProcessSchema = z.object({
  id: z.number(),
  name: z.string().nonempty(),
  cell: CellSchema,
  isDeleted: z.boolean().optional().nullable(),
  orderPosition: z.number().optional(),
});

export type Process = z.infer<typeof ProcessSchema>;
