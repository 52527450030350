import { Category, CategorySchema } from '../entities/Category';
import { Cell } from '../entities/Cell';
import { SettingsItemForm } from '../entities/SettingsItem';
import { Step } from '../entities/Step';
import { api, TAG_TYPES } from './baseApi';

const url = 'turnback-categories';

const categoryApi = api.injectEndpoints({
  endpoints: (builder) => ({
    readCategoryListByStepId: builder.query<Category[], Step['id']>({
      query: (id) => ({ url: `${url}?stepId=eq:${id}` }),
      transformResponse: (data) => {
        return CategorySchema.array()
          .parse(data)
          .sort((a, b) => (a.orderPosition ?? 0) - (b.orderPosition ?? 0));
      },
      providesTags: (_response, _error, id) => [{ type: TAG_TYPES.CATEGORY, id: `STEP-CATEGORY-LIST-${id}` }],
      keepUnusedDataFor: 3600,
    }),
    readCategoryListByCellId: builder.query<Category[], Cell['id']>({
      query: (id) => ({ url: `${url}?cellId=eq:${id}` }),
      transformResponse: (data) => {
        return CategorySchema.array().parse(data);
      },
      providesTags: () => [{ type: TAG_TYPES.CATEGORY, id: `STEP-CATEGORY-LIST` }],
      keepUnusedDataFor: 3600,
    }),
    updateStepCategoryList: builder.mutation<void, { stepId: Step['id']; categoryList: Category[] }>({
      query: ({ ...body }) => ({
        url: `${url}`,
        method: 'PATCH',
        body: body.categoryList.map((p) => ({ id: p.id, orderPosition: p.orderPosition })),
      }),
      invalidatesTags: (_response, _error, { stepId }) => [
        { type: TAG_TYPES.PROCESS, id: `STEP-CATEGORY-LIST-${stepId}` },
        { type: TAG_TYPES.PROCESS, id: `STEP-CATEGORY-LIST` },
      ],
      async onQueryStarted({ stepId, ...patch }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          categoryApi.util.updateQueryData('readCategoryListByStepId', stepId, (draft) => {
            Object.assign(draft, patch.categoryList);
          }),
        );
        queryFulfilled.catch(patchResult.undo);
      },
    }),
    readCategoryList: builder.query<Category[], void>({
      query: () => ({ url: `${url}` }),
      transformResponse: (data) => {
        return CategorySchema.array().parse(data);
      },
      providesTags: () => [{ type: TAG_TYPES.CATEGORY, id: `STEP-CATEGORY-LIST` }],
      keepUnusedDataFor: 3600,
    }),
    createCategory: builder.mutation<{ id: number }, SettingsItemForm & { stepId: Step['id'] }>({
      query: (body) => ({
        url: `${url}`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (_response, _error, { stepId }) => [
        { type: TAG_TYPES.CATEGORY, id: `STEP-CATEGORY-LIST-${stepId}` },
        { type: TAG_TYPES.CATEGORY, id: `STEP-CATEGORY-LIST` },
      ],
    }),
    updateCategory: builder.mutation<void, SettingsItemForm & { id: Category['id']; stepId: Step['id'] }>({
      query: (body) => ({
        url: `${url}/${body.id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (_response, _error, { stepId }) => [
        { type: TAG_TYPES.CATEGORY, id: `STEP-CATEGORY-LIST-${stepId}` },
        { type: TAG_TYPES.CATEGORY, id: `STEP-CATEGORY-LIST` },
      ],
    }),
    removeCategory: builder.mutation<void, { id: Category['id']; stepId: Step['id'] }>({
      query: (body) => ({
        url: `${url}/${body.id}`,
        method: 'DELETE',
        body,
      }),
      invalidatesTags: (_response, _error, { stepId }) => [
        { type: TAG_TYPES.CATEGORY, id: `STEP-CATEGORY-LIST-${stepId}` },
        { type: TAG_TYPES.CATEGORY, id: `STEP-CATEGORY-LIST` },
      ],
    }),
  }),
  overrideExisting: false,
});

export const {
  useLazyReadCategoryListByStepIdQuery,
  useReadCategoryListByCellIdQuery,
  useReadCategoryListQuery,
  useCreateCategoryMutation,
  useRemoveCategoryMutation,
  useUpdateCategoryMutation,
  useUpdateStepCategoryListMutation,
  useLazyReadCategoryListByCellIdQuery,
  useLazyReadCategoryListQuery,
} = categoryApi;
