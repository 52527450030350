import React, { useCallback } from 'react';
import { DropResult } from '@hello-pangea/dnd';
import { Cell } from '../../../../entities/Cell';
import { Process } from '../../../../entities/Process';
import { SettingsItemForm } from '../../../../entities/SettingsItem';
import {
  useRemoveProcessMutation,
  useCreateProcessMutation,
  useUpdateProcessMutation,
  useUpdateCellProcessListMutation,
} from '../../../../services/processApi';
import { ItemAddDialog, ItemEditDialog } from '../ItemDialog';
import SettingsList, { BasicRequest } from '../SettingsList';
import reorder from './reorder';

interface ProcessSettingsListProps {
  cell: Cell;
  processList: Process[] | undefined;
  readListRequest: BasicRequest;
  onSelectedProcessChange: (process: Process) => void;
  selectedProcess?: Process;
}

export default function ProcessSettingsList(props: ProcessSettingsListProps): React.ReactElement {
  const { cell, processList, readListRequest, selectedProcess, onSelectedProcessChange } = props;

  const [remove, removeState] = useRemoveProcessMutation();
  const [create, createState] = useCreateProcessMutation();
  const [update, updateState] = useUpdateProcessMutation();
  const [updateList] = useUpdateCellProcessListMutation();

  const handleRemove = useCallback(
    async (process: Process) => {
      return remove({ ...process, cellId: cell.id });
    },
    [cell, remove],
  );

  const handleUpdate = useCallback(
    async (process: Process) => {
      return update({ ...process, cellId: cell.id });
    },
    [cell, update],
  );

  const handleCreate = useCallback(
    async (values: SettingsItemForm) => {
      return create({ ...values, cellId: cell.id });
    },
    [cell.id, create],
  );

  const handleDragEnd = useCallback(
    async (result: DropResult) => {
      if (!result.destination || !processList) {
        return;
      }
      if (result.source.index === result.destination.index) {
        return;
      }

      const newProcessList: Process[] = reorder(processList, result.source.index, result.destination.index);
      return updateList({ cellId: cell.id, processList: newProcessList.map((p, i) => ({ ...p, orderPosition: i })) });
    },
    [processList, updateList, cell.id],
  );

  return (
    <SettingsList
      title="Processi"
      items={processList}
      readListRequest={readListRequest}
      selectedItem={selectedProcess}
      onSelectedItemChange={onSelectedProcessChange}
      itemRemove={handleRemove}
      itemRemoveRequest={removeState}
      addProps={{
        AddDialog: ItemAddDialog,
        submit: handleCreate,
        request: createState,
      }}
      editPros={{
        EditDialog: ItemEditDialog,
        submit: handleUpdate,
        request: updateState,
      }}
      isDroppable
      onDragEnd={handleDragEnd}
    />
  );
}
