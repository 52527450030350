import { AuthGuard } from '@top-solution/microtecnica-utils';
import Stack from '@mui/material/Stack';
import { UnauthorizedPage } from '..';

import { Layout } from '../../components/Layout';
import { ChoosePlant } from './ChoosePlant';

export default function HomePage(): JSX.Element {
  return (
    <AuthGuard unauthorizedFallback={<UnauthorizedPage />}>
      <Layout sx={{ padding: 2 }} disableGutters>
        <Stack
          sx={{
            gap: 2,
            flexDirection: 'column',
            '.MuiStack-root': {
              flexDirection: 'row',
            },
          }}
        >
          <ChoosePlant />
        </Stack>
      </Layout>
    </AuthGuard>
  );
}
