import React, { useCallback, useMemo } from 'react';
import { Problem } from '../../../../entities/Problem';
import { SettingsItemForm } from '../../../../entities/SettingsItem';
import {
  useCreateProblemMutation,
  useReadProblemListQuery,
  useRemoveProblemMutation,
  useUpdateProblemMutation,
} from '../../../../services/problemApi';
import { ItemAddDialog, ItemEditDialog } from '../ItemDialog';
import SettingsList from '../SettingsList';

export default function ProblemSettingsList(props: {
  onSelectedProblemIdChange: (problemId: number) => void;
  selectedProblemId: number | null;
}): React.ReactElement {
  const { selectedProblemId, onSelectedProblemIdChange } = props;
  const { data, isLoading, error } = useReadProblemListQuery();
  const [remove, removeState] = useRemoveProblemMutation();
  const [create, createState] = useCreateProblemMutation();
  const [update, updateState] = useUpdateProblemMutation();

  const selectedProblem = useMemo(() => data?.find(({ id }) => id === selectedProblemId), [data, selectedProblemId]);

  const handleRemove = useCallback(
    async (problem: Problem) => {
      return remove(problem);
    },
    [remove],
  );

  const handleUpdate = useCallback(
    async (problem: Problem) => {
      return update(problem);
    },
    [update],
  );

  const handleCreate = useCallback(
    async (values: SettingsItemForm) => {
      return create({ ...values });
    },
    [create],
  );

  return (
    <SettingsList
      title="Problem"
      items={data?.filter((i) => !i.isDeleted)}
      selectedItem={selectedProblem}
      onSelectedItemChange={({ id }) => onSelectedProblemIdChange(id)}
      readListRequest={{ isLoading, error }}
      itemRemove={handleRemove}
      itemRemoveRequest={removeState}
      addProps={{
        AddDialog: ItemAddDialog,
        submit: handleCreate,
        request: createState,
      }}
      editPros={{
        EditDialog: ItemEditDialog,
        submit: handleUpdate,
        request: updateState,
      }}
    />
  );
}
