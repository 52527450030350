import { z } from 'zod';
import { CellSchema } from './Cell';

export const WorkstationSchema = z.object({
  id: z.number(),
  name: z.string().nonempty(),
  cell: CellSchema,
  isDeleted: z.boolean().optional().nullable(),
});

export type Workstation = z.infer<typeof WorkstationSchema>;
