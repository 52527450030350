export const COLORS = [
  '#2E86AB',
  '#A23B72',
  '#F18F01',
  '#C73E1D',
  '#748E54',
  '#FFA69E',
  '#9CAFB7',
  '#7B435B',
  '#F39B6D',
  '#E65F5C',
  '#B5D99C',
  '#B48291',
];
