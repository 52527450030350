import { Navigate, Route, Routes } from 'react-router-dom';
import { NotFoundPage } from '..';
import ChartsWrapper from './Charts/ChartsWrapper';
import TurnbackListByCell from './TurnbackListByCell';
import TurnbackListByResponsibleCell from './TurnbackListByResponsibleCell';
import TurnbackPage from './TurnbackPage';

export default function TurnbackRouter(): JSX.Element {
  return (
    <Routes>
      <Route path="/" element={<TurnbackPage />}>
        <Route path="self" element={<TurnbackListByCell />} />
        <Route path="responsible" element={<TurnbackListByResponsibleCell />} />
        <Route path="charts" element={<ChartsWrapper />} />
        <Route index element={<Navigate to="self" replace={true} />} />
        <Route path="*" element={<NotFoundPage />} />
      </Route>
    </Routes>
  );
}
