import React, { useCallback } from 'react';
import { DropResult } from '@hello-pangea/dnd';
import { Category } from '../../../../entities/Category';
import { Process } from '../../../../entities/Process';
import { SettingsItemForm } from '../../../../entities/SettingsItem';
import { Step } from '../../../../entities/Step';
import {
  useCreateCategoryMutation,
  useRemoveCategoryMutation,
  useUpdateCategoryMutation,
  useUpdateStepCategoryListMutation,
} from '../../../../services/categoryApi';
import { ItemAddDialog, ItemEditDialog } from '../ItemDialog';
import SettingsList, { BasicRequest } from '../SettingsList';
import reorder from './reorder';

interface StepSettingsListProps {
  process?: Process;
  step?: Step;
  categoryList?: Category[];
  readListRequest: BasicRequest;
  onSelectedCategoryChange: (category: Category) => void;
  selectedCategory?: Category;
}

export default function StepSettingsList(props: StepSettingsListProps): React.ReactElement {
  const { categoryList, readListRequest, step, onSelectedCategoryChange, selectedCategory } = props;

  const [remove, removeState] = useRemoveCategoryMutation();
  const [create, createState] = useCreateCategoryMutation();
  const [update, updateState] = useUpdateCategoryMutation();
  const [updateList] = useUpdateStepCategoryListMutation();

  const handleRemove = useCallback(
    async (category: Category) => {
      return remove({ ...category, stepId: step!.id });
    },
    [remove, step],
  );

  const handleUpdate = useCallback(
    async (category: Category) => {
      return update({ ...category, stepId: step!.id });
    },
    [step, update],
  );

  const handleCreate = useCallback(
    async (values: SettingsItemForm) => {
      return create({ ...values, stepId: step!.id });
    },
    [create, step],
  );

  const handleDragEnd = useCallback(
    async (result: DropResult) => {
      if (!result.destination || !categoryList) {
        return;
      }
      if (result.source.index === result.destination.index) {
        return;
      }

      const newCategoryList: Category[] = reorder(categoryList, result.source.index, result.destination.index);
      return updateList({
        stepId: step!.id,
        categoryList: newCategoryList.map((p, i) => ({ ...p, orderPosition: i })),
      });
    },
    [categoryList, updateList, step],
  );

  return (
    <SettingsList
      title="Categorie"
      emptyText="Seleziona uno step per gestirne le categorie"
      items={categoryList}
      readListRequest={readListRequest}
      selectedItem={selectedCategory}
      onSelectedItemChange={onSelectedCategoryChange}
      itemRemove={handleRemove}
      itemRemoveRequest={removeState}
      addProps={{
        AddDialog: ItemAddDialog,
        submit: handleCreate,
        request: createState,
      }}
      editPros={{
        EditDialog: ItemEditDialog,
        submit: handleUpdate,
        request: updateState,
      }}
      isDroppable
      onDragEnd={handleDragEnd}
    />
  );
}
