import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import { GridActionsCellItem, GridColDef, GridRowParams, MuiEvent } from '@mui/x-data-grid-premium';
import { DataGridWrapper } from '@top-solution/microtecnica-mui';
import { useAuth } from '@top-solution/microtecnica-utils';
import { DetailsIcon, StateMachineIcon } from '../../components/Icons';
import { Cell } from '../../entities/Cell';
import { Turnback } from '../../entities/Turnback';
import { useLazyReadTurnbackByIdQuery } from '../../services/turnbackApi';
import { useCell } from '../Cell/CellContext';
import { useColumns } from './columns';
import { TurnbackDetailDialog } from './TurnbackDetail';
import TurnbackListGrid from './TurnbackListGrid';
import { TurnbackStatusDialog } from './TurnbackStatusDialog';

export default function TurnbackListByResponsibleCell(): React.ReactElement {
  const { username, email } = useAuth();
  const { cell } = useCell();
  const turnbackColumns = useColumns(true);
  const [turnbackToUpdateStatus, setTurnbackToUpdateStatus] = useState<Turnback | null>(null);
  const [turnbackToView, setTurnbackToView] = useState<Turnback | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [getTurnbackById, { data: turnbackFromQueryParam }] = useLazyReadTurnbackByIdQuery();

  const isManager = useMemo(
    () => Boolean(cell.managerList?.find((responsible) => responsible === username)),
    [cell, username]
  );

  useEffect(() => {
    if (searchParams.get('id')) {
      getTurnbackById({ id: Number(searchParams.get('id')) });
      if (turnbackFromQueryParam) {
        setTurnbackToView(turnbackFromQueryParam);
        setSearchParams();
      }
    }
  }, [getTurnbackById, searchParams, setSearchParams, turnbackFromQueryParam]);

  const isEditableByRtprManager = useCallback(
    (responsibleCell: Cell | undefined, isRtpr: boolean) => {
      return (
        responsibleCell &&
        responsibleCell.isRtpr &&
        !!responsibleCell.managerList?.find((m) => m === username) &&
        isRtpr
      );
    },
    [username]
  );

  const handleDetailClick = useCallback(
    ({ row }: GridRowParams<Turnback>) =>
      (event: MuiEvent<React.MouseEvent>) => {
        event.stopPropagation();
        setTurnbackToView(row);
      },
    []
  );

  const handleStatusUpdateClick = useCallback(
    ({ row }: GridRowParams<Turnback>) =>
      (event: MuiEvent<React.MouseEvent>) => {
        event.stopPropagation();
        setTurnbackToUpdateStatus(row);
      },
    []
  );

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        headerName: 'ID',
        field: 'id',
        width: 60,
        type: 'number',
        renderCell: ({ row }) => (
          <Button size="small" color="primary" onClick={() => setTurnbackToView(row)}>
            {row.id}
          </Button>
        ),
      },
      ...turnbackColumns.filter((c) => c.field !== 'responsibleCellId'),
      {
        field: 'actions',
        type: 'actions',
        width: 120,
        getActions: (params: GridRowParams<Turnback>) => [
          <GridActionsCellItem
            icon={<DetailsIcon />}
            key="view"
            label="Dettaglio"
            onClick={handleDetailClick(params)}
          />,
          <GridActionsCellItem
            icon={<StateMachineIcon />}
            key="edit-status"
            label="Modifica Status"
            onClick={handleStatusUpdateClick(params)}
            disabled={
              params.row.openBy !== username &&
              !isManager &&
              !isEditableByRtprManager(params.row.responsibleCell, !!params.row.isRtpr) &&
              !(params.row.notificationEmailList || []).includes(email || '')
            }
          />,
        ],
      },
    ],
    [email, handleDetailClick, handleStatusUpdateClick, isEditableByRtprManager, isManager, turnbackColumns, username]
  );

  return (
    <>
      <DataGridWrapper offsetHeight={120}>
        <TurnbackListGrid columns={columns} sessionStorageId={`TurnbackListByCell${cell.name}`} isResponsibleCell />
      </DataGridWrapper>
      {turnbackToUpdateStatus && (
        <TurnbackStatusDialog
          open={Boolean(turnbackToUpdateStatus)}
          turnback={turnbackToUpdateStatus}
          onClose={() => setTurnbackToUpdateStatus(null)}
        />
      )}
      {turnbackToView && (
        <TurnbackDetailDialog
          open={Boolean(turnbackToView)}
          turnback={turnbackToView}
          onClose={() => setTurnbackToView(null)}
        />
      )}
    </>
  );
}
