import React, { useCallback } from 'react';
import { Controller, UseFormReturn, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { ErrorAlert } from '@top-solution/microtecnica-mui';
import { LoadingButton } from '@mui/lab';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { SaveIcon } from '../../../../components/Icons';
import { Support, SupportForm, SupportFormSchema } from '../../../../entities/Support';
import { BasicRequest } from '../SettingsList';

type SupporDialogProps = Omit<DialogProps, 'onClose' | 'onSubmit'> & {
  onClose: () => void;
  request: BasicRequest;
};
export type SupportEditDialogProps = SupporDialogProps & {
  item: Support;
  onSubmit: (data: Support) => void;
};
export type SupportAddDialogProps = SupporDialogProps & {
  onSubmit: (data: SupportForm) => void;
};

function SupportFormComponent(props: { form: UseFormReturn<SupportForm> }): React.ReactElement {
  const { form } = props;

  return (
    <>
      <Controller
        control={form.control}
        name="telephone"
        render={({ field, fieldState: { error } }) => (
          <TextField
            label="Telefono"
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} //TODO: validate telephone?
            error={Boolean(error)}
            helperText={error?.message ?? ' '}
            {...field}
            required
            fullWidth
          />
        )}
      />
      <Controller
        control={form.control}
        name="lastname"
        render={({ field, fieldState: { error } }) => (
          <TextField
            label="Cognome"
            error={Boolean(error)}
            helperText={error?.message ?? ' '}
            {...field}
            required
            fullWidth
          />
        )}
      />
      <Controller
        control={form.control}
        name="name"
        render={({ field, fieldState: { error } }) => (
          <TextField
            label="Nome"
            error={Boolean(error)}
            helperText={error?.message ?? ' '}
            {...field}
            required
            fullWidth
          />
        )}
      />
      <Controller
        control={form.control}
        name="levelId"
        render={({ field, fieldState: { error } }) => (
          <TextField
            select
            label="Livello"
            error={Boolean(error)}
            helperText={error?.message ?? ' '}
            {...field}
            required
            fullWidth
          >
            <MenuItem value={1}>1</MenuItem>
            <MenuItem value={2}>2</MenuItem>
            <MenuItem value={3}>3</MenuItem>
          </TextField>
        )}
      />
    </>
  );
}

function SupportDialog(
  props: Omit<DialogProps, 'onClose' | 'onSubmit'> & {
    onClose: () => void;
    request: BasicRequest;
    onSubmit: (data: SupportForm) => Promise<void>;
    defaultValues: SupportForm;
  },
): React.ReactElement {
  const { open, title, onSubmit, request, onClose, defaultValues, ...dialogProps } = props;

  const form = useForm<SupportForm>({
    defaultValues,
    resolver: zodResolver(SupportFormSchema),
  });

  const onHandleSubmit = useCallback(
    async (data: SupportForm) => {
      await onSubmit(data);
      onClose();
    },
    [onClose, onSubmit],
  );

  return (
    <Dialog open={open} {...dialogProps}>
      <form onSubmit={form.handleSubmit(onHandleSubmit)} noValidate>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent sx={{ py: 1 }}>
          <Box sx={{ py: 1 }}>
            <SupportFormComponent form={form} />
          </Box>
          {request.error && <ErrorAlert error={request.error} />}
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={() => onClose()}>
            Annulla
          </Button>
          <LoadingButton
            type="submit"
            color="primary"
            variant="contained"
            loading={request.isLoading}
            loadingPosition="start"
            startIcon={<SaveIcon />}
          >
            Salva
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export function SupportEditDialog(props: SupportEditDialogProps): React.ReactElement {
  const { item, onSubmit, ...dialogProps } = props;

  const onHandleSubmit = useCallback(
    async (data: SupportForm) => {
      await onSubmit({ ...item, ...data, level: { id: data.levelId, time: 1 } });
    },
    [onSubmit, item],
  );

  return (
    <SupportDialog onSubmit={onHandleSubmit} defaultValues={{ ...item, levelId: item.level.id }} {...dialogProps} />
  );
}

export function SupportAddDialog(props: SupportAddDialogProps): React.ReactElement {
  const { onSubmit, ...dialogProps } = props;

  const onHandleSubmit = useCallback(
    async (data: SupportForm) => {
      await onSubmit(data);
    },
    [onSubmit],
  );

  return (
    <SupportDialog
      onSubmit={onHandleSubmit}
      defaultValues={{ lastname: '', levelId: 1, name: '', telephone: '' }}
      {...dialogProps}
    />
  );
}
