import { useCallback } from 'react';
import { ConfirmDialogProps, DeleteConfirmDialog } from '@top-solution/microtecnica-mui';
import { Turnback } from '../../entities/Turnback';
import { useRemoveTurnbackMutation } from '../../services/turnbackApi';

type TurnbackDeleteConfirmProps = Omit<ConfirmDialogProps, 'onClose' | 'title' | 'onConfirm'> & {
  data: Turnback;
  onClose: (value?: Turnback) => void;
};

export function TurnbackDeleteConfirm(props: TurnbackDeleteConfirmProps): JSX.Element {
  const { data, onClose, TransitionProps, ...confirmProps } = props;
  const [remove, removeStatus] = useRemoveTurnbackMutation();

  const handleConfirm = useCallback(async () => {
    await remove({
      id: data.id,
    }).unwrap();
    onClose(data);
  }, [remove, data, onClose]);

  return (
    <DeleteConfirmDialog
      confirmText="confermo"
      title="Eliminazione Turnback"
      description={`Il Turnback con id: “${data.id}” verrà eliminato`}
      inProgress={removeStatus.isLoading}
      error={removeStatus.error}
      onConfirm={handleConfirm}
      onClose={() => onClose()}
      TransitionProps={{
        ...TransitionProps,
        onExit: (node) => {
          removeStatus.reset();
          TransitionProps?.onExit?.(node);
        },
      }}
      {...confirmProps}
    />
  );
}
