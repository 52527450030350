import React, { useCallback } from 'react';
import { Panel, PanelGroup } from 'react-resizable-panels';
import { format } from 'date-fns';
import { sub } from 'date-fns/sub';
import { useDidMount } from 'rooks';
import Box from '@mui/material/Box';
import { DateRange, DateRangePicker } from '@mui/x-date-pickers-pro';
import { useLazyReadTurnbackStatsQuery } from '../../../services/turnbackApi';
import { useCell } from '../../Cell/CellContext';
import { ChartContextProvider } from './ChartContext';
import ChartFilters from './ChartFilters';
import GenericChart from './GenericChart';
import { ResizeHandle } from './ResizeHandler';
import TotalsChart from './TotalsChart';

export default function ChartsWrapper(): React.ReactElement {
  const { cell } = useCell();
  const [getStats, { data, error, isLoading }] = useLazyReadTurnbackStatsQuery();

  useDidMount(function () {
    getStats({
      cellId: cell.id,
      startDate: format(
        sub(new Date(), {
          years: 1,
        }),
        'yyyy-MM-dd'
      ),
      endDate: format(new Date(), 'yyyy-MM-dd'),
    });
  });

  const onChange = useCallback(
    (values: DateRange<Date>) => {
      if (values[0] && values[1])
        getStats({
          cellId: cell.id,
          startDate: format(values[0], 'yyyy-MM-dd'),
          endDate: format(values[1], 'yyyy-MM-dd'),
        });
    },
    [cell.id, getStats]
  );

  return (
    <>
      <Box mb={2} sx={{ position: 'absolute', top: '12px', left: 0 }}>
        <DateRangePicker
          localeText={{ start: 'Inzio', end: 'Fine' }}
          onChange={onChange}
          slotProps={{
            textField: {
              fullWidth: false,
              size: 'small',
            },
          }}
          disableFuture
          defaultValue={[
            sub(new Date(), {
              years: 1,
            }),
            new Date(),
          ]}
        />
      </Box>

      <ChartContextProvider data={data} isLoading={isLoading} error={error}>
        <PanelGroup direction="horizontal">
          <Panel defaultSize={80} order={1}>
            <Box sx={{ width: '100%', height: '400px' }}>
              <TotalsChart />
            </Box>
            <Box sx={{ width: '100%', height: '400px' }}>
              <GenericChart properties="processes" />
            </Box>
            <Box sx={{ width: '100%', height: '400px' }}>
              <GenericChart properties="steps" />
            </Box>
            <Box sx={{ width: '100%', height: '400px' }}>
              <GenericChart properties="categories" />
            </Box>
            <Box sx={{ width: '100%', height: '400px' }}>
              <GenericChart properties="partNumbers" />
            </Box>
          </Panel>
          <ResizeHandle />
          <Panel defaultSize={20} order={2}>
            <ChartFilters />
          </Panel>
        </PanelGroup>
      </ChartContextProvider>
    </>
  );
}
