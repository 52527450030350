import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import { FileIcon } from '../../components/Icons';
import { Term, Value } from '../../components/Layout/Description';
import { UserName } from '../../components/User/UserName';
import { Turnback } from '../../entities/Turnback';
import { formatDateShort } from '../../utils/date';
import { useCell } from '../Cell/CellContext';
import { usePlant } from '../Plant/PlantContext';
import { TurnbackStatusChip } from './useTurnbackStatusDataGridColumn';

type TurnbackDetailDialogProps = DialogProps & {
  turnback: Turnback;
  onClose: (data: Turnback | null) => void;
};

export function TurnbackDetail(props: { turnback: Turnback; showMore?: boolean }): JSX.Element {
  const { turnback, showMore } = props;
  const { plant } = usePlant();
  const { cell } = useCell();

  const moreDetails = (
    <Grid container component="dl">
      <Grid item xs={6}>
        <Term>Reparto responsabile:</Term>
        <Value>{turnback.responsibleCell?.name || '-'}</Value>
      </Grid>
      <Grid item xs={6}>
        <Term>RTPR:</Term>
        <Value>{turnback.isRtpr ? 'SI' : 'NO'}</Value>
      </Grid>
      <Grid item xs={4}>
        <Term>Processo:</Term>
        <Value>{turnback.category.step.process.name}</Value>
      </Grid>
      <Grid item xs={4}>
        <Term>Step:</Term>
        <Value>{turnback.category.step.name}</Value>
      </Grid>
      <Grid item xs={4}>
        <Term>Categoria:</Term>
        <Value>{turnback.category.name}</Value>
      </Grid>
      <Grid item xs={6}>
        <Term>Customer:</Term>
        <Value>{turnback.customer?.name || '-'}</Value>
      </Grid>
      <Grid item xs={6}>
        <Term>Vendor:</Term>
        <Value>{turnback.vendor?.name || '-'}</Value>
      </Grid>
      <Grid item xs={6}>
        <Term>Part Number:</Term>
        <Value>{turnback.partNumber || '-'}</Value>
      </Grid>
      <Grid item xs={6}>
        <Term>Occorrenze:</Term>
        <Value>{turnback.occurrences}</Value>
      </Grid>
      {Boolean(turnback.notificationEmailList) && (
        <Grid item xs={12}>
          <Term>Destinatari notifiche:</Term>
          <Value>{turnback.notificationEmailList?.toString()}</Value>
        </Grid>
      )}
      {Boolean(turnback.openingComments) && (
        <Grid item xs={12}>
          <Term>Note apertura:</Term>
          <Value>{turnback.openingComments}</Value>
        </Grid>
      )}
      {Boolean(turnback.closingComments) && (
        <Grid item xs={12}>
          <Term>Note chiusura:</Term>
          <Value>{turnback.closingComments}</Value>
        </Grid>
      )}
      {turnback.attachmentList && turnback.attachmentList.length > 0 && (
        <Grid item xs={12}>
          <Term>Allegati:</Term>
          <Value>
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }} mt={1}>
              {turnback.attachmentList.map((a) => (
                <Chip
                  key={a.id}
                  label={a.name}
                  sx={{ marginRight: 0.5, marginBottom: 0.5 }}
                  icon={<FileIcon />}
                  onClick={() => window.open(`${window.location.origin}${a.path}`, '_blank')?.focus()}
                />
              ))}
            </Box>
          </Value>
        </Grid>
      )}
      {turnback.notificationEmailList && turnback.notificationEmailList.length > 0 && (
        <Grid item xs={12}>
          <Term>Destinatari notifiche:</Term>
          <Value>
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }} mt={1}>
              {turnback.notificationEmailList.map((a, i) => (
                <Chip key={i} label={a} sx={{ marginRight: 0.5, marginBottom: 0.5 }} />
              ))}
            </Box>
          </Value>
        </Grid>
      )}
    </Grid>
  );

  return (
    <Grid container component="dl">
      {showMore && (
        <Grid item xs={12}>
          <Term>Stato:</Term>
          <Value>
            <TurnbackStatusChip status={turnback.status} />
          </Value>
        </Grid>
      )}
      <Grid item xs={3}>
        <Term>ID:</Term>
        <Value>{turnback.id}</Value>
      </Grid>
      <Grid item xs={3}>
        <Term>Sito:</Term>
        <Value>{plant.name}</Value>
      </Grid>
      <Grid item xs={3}>
        <Term>Reparto:</Term>
        <Value>{cell.name}</Value>
      </Grid>
      <Grid item xs={3}>
        <Term>Segnalato da:</Term>
        <Value>
          <UserName username={turnback.reportedBy} />
        </Value>
      </Grid>
      <Grid item xs={3}>
        <Term>Data Apertura:</Term>
        <Value>{formatDateShort(turnback.createDate)}</Value>
      </Grid>
      <Grid item xs={3}>
        <Term>Aperto da:</Term>
        <Value>
          <UserName username={turnback.openBy} />
        </Value>
      </Grid>
      {Boolean(turnback.closedBy) && (
        <>
          <Grid item xs={3}>
            <Term>Data Chiusura:</Term>
            <Value>{formatDateShort(turnback.closeDate)}</Value>
          </Grid>
          <Grid item xs={3}>
            <Term>Chiuso da:</Term>
            <Value>
              <UserName username={turnback.closedBy!} />
            </Value>
          </Grid>
        </>
      )}

      {showMore && moreDetails}
    </Grid>
  );
}

export function TurnbackDetailDialog(props: TurnbackDetailDialogProps): JSX.Element {
  const { turnback, onClose, ...dialogProps } = props;

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      {...dialogProps}
      TransitionProps={{
        ...dialogProps.TransitionProps,
        onExit: (event) => {
          dialogProps.TransitionProps?.onExit?.(event);
        },
      }}
    >
      <DialogTitle>Dettaglio Turnback</DialogTitle>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column' }}>
        <TurnbackDetail turnback={turnback} showMore />
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="contained" onClick={() => onClose(null)}>
          Chiudi
        </Button>
      </DialogActions>
    </Dialog>
  );
}
