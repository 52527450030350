import { z } from 'zod';

export const SettingsItemSchema = z.object({
  id: z.number(),
  name: z.string().nonempty(),
  isDeleted: z.boolean().optional().nullable(),
});

export const SettingsItemFormSchema = SettingsItemSchema.omit({
  id: true,
});

export type SettingsItem = z.infer<typeof SettingsItemSchema>;
export type SettingsItemForm = z.infer<typeof SettingsItemFormSchema>;
