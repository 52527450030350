import { Link, Outlet, matchPath, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

function useRouteMatch(patterns: readonly string[]) {
  const { pathname } = useLocation();

  for (const pattern of patterns) {
    const possibleMatch = matchPath(`/plants/:plantId/cells/:cellId/turnback/${pattern}`, pathname);
    if (possibleMatch !== null) {
      return pattern;
    }
  }

  return null;
}

export default function TurnbackPage(): JSX.Element {
  const currentTab = useRouteMatch(['self', 'responsible', 'charts']);

  return (
    <Box sx={{ position: 'relative' }}>
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end', position: 'relative' }} mb={1}>
        <Tabs value={currentTab || 'self'} aria-label="Tab tb navigation" textColor="primary" indicatorColor="primary">
          <Tab label="TB inseriti dalla mia cella" value="self" component={Link} to="self" />
          <Tab label="TB inseriti verso la mia cella" value="responsible" component={Link} to="responsible" />
          <Tab label="Grafici" value="charts" component={Link} to="charts" />
        </Tabs>
      </Box>
      <Outlet />
    </Box>
  );
}
