import React, { useCallback } from 'react';
import { SettingsItem } from '../../../../entities/SettingsItem';
import {
  useCreateKaizenSPQDCMutation,
  useReadKaizenSPQDCListQuery,
  useRemoveKaizenSPQDCMutation,
  useUpdateKaizenSPQDCMutation,
} from '../../../../services/kaizenSPQDCApi';
import { ItemAddDialog, ItemEditDialog } from '../ItemDialog';
import SettingsList from '../SettingsList';

export default function KaizenSPQDCList(): React.ReactElement {
  const { data, isLoading, error } = useReadKaizenSPQDCListQuery();
  const [remove, removeState] = useRemoveKaizenSPQDCMutation();
  const [create, createState] = useCreateKaizenSPQDCMutation();
  const [update, updateState] = useUpdateKaizenSPQDCMutation();

  const handleRemove = useCallback(
    async (vendor: SettingsItem) => {
      return remove(vendor);
    },
    [remove],
  );

  const handleUpdate = useCallback(
    async (vendor: SettingsItem) => {
      return update(vendor);
    },
    [update],
  );

  const handleCreate = useCallback(
    async (values: SettingsItem) => {
      return create({ ...values });
    },
    [create],
  );
  return (
    <SettingsList
      title="SPQDC"
      items={data?.filter((i) => !i.isDeleted)}
      readListRequest={{ isLoading, error }}
      itemRemove={handleRemove}
      itemRemoveRequest={removeState}
      addProps={{
        AddDialog: ItemAddDialog,
        submit: handleCreate,
        request: createState,
      }}
      editPros={{
        EditDialog: ItemEditDialog,
        submit: handleUpdate,
        request: updateState,
      }}
    />
  );
}
