import { useCallback, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { ErrorAlert } from '@top-solution/microtecnica-mui';
import { ZodIssueCode } from 'zod';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { SaveIcon } from '../../components/Icons';
import { Turnback, TurnbackStatusFormSchema, TurnbackStatusForm, TURNBACK_STATUS } from '../../entities/Turnback';
import { useUpdateTurnbackStatusMutation } from '../../services/turnbackApi';
import { TurnbackDetail } from './TurnbackDetail';

type TurnbackStatusDialogProps = DialogProps & {
  turnback: Turnback;
  onClose: () => void;
};

export function TurnbackStatusDialog(props: TurnbackStatusDialogProps): JSX.Element {
  const { turnback, onClose, ...dialogProps } = props;
  const [update, updateStatus] = useUpdateTurnbackStatusMutation();
  const statusList = useMemo(() => Object.values(TURNBACK_STATUS), []);

  const TurnbackStatusFormSchemaWithRefine = useMemo(
    () =>
      TurnbackStatusFormSchema.superRefine((data, ctx) => {
        if (data.status === TURNBACK_STATUS.CLOSED && (data.closingComments?.length ?? 0) < 5) {
          ctx.addIssue({
            code: ZodIssueCode.too_small,
            minimum: 5,
            inclusive: true,
            type: 'string',
            path: ['closingComments'],
            message: 'Il commento è obbligatorio per chiudere il turnback e deve essere più lungo di 5 caratteri',
          });
        }
      }),
    [],
  );

  const form = useForm<TurnbackStatusForm>({
    defaultValues: { closingComments: '', ...turnback },
    resolver: zodResolver(TurnbackStatusFormSchemaWithRefine),
  });
  const { control, watch } = form;

  const onSubmit = useCallback(
    async (data: TurnbackStatusForm) => {
      await update({
        ...data,
        id: turnback.id,
      }).unwrap();

      onClose();
    },
    [update, turnback.id, onClose],
  );

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      {...dialogProps}
      TransitionProps={{
        ...dialogProps.TransitionProps,
        onExit: (event) => {
          updateStatus.reset();
          form.reset();
          dialogProps.TransitionProps?.onExit?.(event);
        },
      }}
    >
      <form onSubmit={form.handleSubmit(onSubmit)} noValidate>
        <DialogTitle>Aggiorna Stato</DialogTitle>
        <DialogContent sx={{ display: 'flex', flexDirection: 'column', '.MuiFormControl-root': { mt: 1 } }}>
          <TurnbackDetail turnback={turnback} />
          <Controller
            control={control}
            name="status"
            render={({ field: { onChange, ref, ...field }, fieldState: { error } }) => (
              <TextField
                label="Stato"
                onChange={(e) => onChange(e.target.value)}
                select
                SelectProps={{ ref }}
                error={Boolean(error)}
                helperText={error?.message || ' '}
                {...field}
              >
                {statusList
                  ?.filter((s) => (turnback.isRtpr ? true : s !== TURNBACK_STATUS.WIP))
                  .map((s) => (
                    <MenuItem key={s} value={s}>
                      {s}
                    </MenuItem>
                  ))}
              </TextField>
            )}
          />
          {watch('status') === TURNBACK_STATUS.OPEN && (
            <Controller
              control={control}
              name="openingComments"
              render={({ field, fieldState: { error } }) => (
                <TextField
                  label="Note apertura"
                  error={Boolean(error)}
                  helperText={error?.message || ' '}
                  sx={{ flex: 1 }}
                  {...field}
                />
              )}
            />
          )}
          {watch('status') === TURNBACK_STATUS.CLOSED && (
            <Controller
              control={control}
              name="closingComments"
              render={({ field, fieldState: { error } }) => (
                <TextField
                  label="Note chiusura"
                  error={Boolean(error)}
                  helperText={error?.message || ' '}
                  sx={{ flex: 1 }}
                  {...field}
                />
              )}
            />
          )}
          {updateStatus.error && <ErrorAlert error={updateStatus.error} />}
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={() => onClose()}>
            Annulla
          </Button>
          <LoadingButton
            type="submit"
            color="primary"
            variant="contained"
            loading={updateStatus.isLoading}
            loadingPosition="start"
            startIcon={<SaveIcon />}
          >
            Salva
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
}
