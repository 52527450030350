import { useCallback } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Cell } from 'recharts';
import { useCurrentPng } from 'recharts-to-png';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useChartContext } from './ChartContext';
import { COLORS } from './utils';

export default function TotalsChart() {
  const { data } = useChartContext();
  const [getAreaPng, { ref }] = useCurrentPng();
  const handleDownload = useCallback(async () => {
    const png = await getAreaPng();
    if (png) {
      const download = document.createElement('a');
      download.href = png;
      download.download = 'totali.png';
      download.click();
    }
  }, [getAreaPng]);

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography>Totali</Typography>
        <Button color="primary" variant="contained" size="small" onClick={() => handleDownload()}>
          Salva
        </Button>
      </Box>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          data={data}
          margin={{
            top: 25,
            right: 0,
            left: 0,
            bottom: 30,
          }}
          ref={ref}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="month" />
          <YAxis />
          <Tooltip />
          <Bar dataKey="totalTb">
            {data.map((entry, index) => {
              return <Cell fill={COLORS[index % COLORS.length]} key={index} />;
            })}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </>
  );
}
