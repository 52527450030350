import { useMemo } from 'react';
import Chip from '@mui/material/Chip';
import { GridRenderCellParams, GridSingleSelectColDef } from '@mui/x-data-grid-premium';
import { KAIZEN_STATUS, Kaizen } from '../../entities/Kaizen';
import { useReadKaizenStatusListQuery } from '../../services/kaizenApi';

export function KaizenStatusChip(props: { status: KAIZEN_STATUS }): React.ReactElement {
  const { status } = props;
  const { data } = useReadKaizenStatusListQuery();

  const style = useMemo(() => {
    switch (status) {
      case KAIZEN_STATUS.SUSPENDED:
        return { bgcolor: 'info.main', color: 'info.contrastText' };
      case KAIZEN_STATUS.IMPLEMENTABLE:
        return { bgcolor: 'success.main', color: 'success.contrastText' };
      case KAIZEN_STATUS.NOT_IMPLEMENTABLE:
        return { bgcolor: 'error.main', color: 'error.contrastText' };
      case KAIZEN_STATUS.TAKEN_CHARGE:
        return { bgcolor: 'warning.light', color: 'warning.contrastText' };
      default:
        return { bgcolor: 'common.white', color: 'common.black', border: 1 };
    }
  }, [status]);

  return (
    <Chip
      label={data?.find((k) => k.id === status)?.name}
      sx={{
        textTransform: 'capitalize',
        width: 160,
        ...style,
      }}
    />
  );
}

export function useKaizenStatusDataGridColumn(): GridSingleSelectColDef {
  const { data } = useReadKaizenStatusListQuery();
  const valueOptions = useMemo(() => data?.map((v) => ({ value: v.id, label: v.name })), [data]);
  return useMemo(
    () => ({
      field: 'kaizenStatusId',
      headerName: 'Stato',
      type: 'singleSelect',
      valueOptions,
      valueGetter: (value, row) => {
        return row.status.id;
      },
      renderCell: ({ value }: GridRenderCellParams<Kaizen['status']>) => <KaizenStatusChip status={value} />,
    }),
    [valueOptions]
  );
}
