import {
  PagedRequestParams,
  PagedResponse,
  Plant,
  createPagedResponseSchema,
  formatQueryParams,
} from '@top-solution/microtecnica-utils';
import { Cell } from '../entities/Cell';
import {
  Turnback,
  TurnbackAddForm,
  TurnbackSchema,
  TurnbackStat,
  TurnbackStatSchema,
  TurnbackStatusForm,
} from '../entities/Turnback';
import { api, TAG_TYPES } from './baseApi';
import { combineSearchParams } from './utils';

const url = 'turnbacks';

const turnbackApi = api.injectEndpoints({
  endpoints: (builder) => ({
    readTurnbackList: builder.query<
      PagedResponse<Turnback>,
      PagedRequestParams & { responsibleCellId?: Cell['id']; cellId?: Cell['id'] }
    >({
      query: ({ cellId, responsibleCellId, ...params }) => {
        const cellParam = new URLSearchParams(cellId ? `?cellId=eq:${cellId}` : '');
        const responsibleParam = new URLSearchParams(
          responsibleCellId ? `?responsibleCellId=eq:${responsibleCellId}` : ''
        );
        const combined = combineSearchParams(cellParam, responsibleParam);
        return {
          url: `${url}`,
          params: combineSearchParams(formatQueryParams(params), combined),
        };
      },
      transformResponse: (data) => createPagedResponseSchema(TurnbackSchema).parse(data),
      providesTags: () => {
        return [TAG_TYPES.TURNBACK];
      },
    }),
    readTurnbackById: builder.query<Turnback, { id: Turnback['id'] }>({
      query: ({ id }) => {
        const cellParam = new URLSearchParams(`?id=eq:${id}`);
        return {
          url: `${url}`,
          params: cellParam,
        };
      },
      transformResponse: (data) => TurnbackSchema.array().parse(data)[0],
      providesTags: () => {
        return [TAG_TYPES.TURNBACK];
      },
    }),
    createTurnback: builder.mutation<
      { id: number },
      Omit<TurnbackAddForm, 'attachmentList'> & { plantId: Plant['id']; cellId: Cell['id']; attachmentList?: number[] }
    >({
      query: (body) => ({
        url: `${url}`,
        method: 'POST',
        body,
      }),
      invalidatesTags: () => [TAG_TYPES.TURNBACK, { type: TAG_TYPES.TURNBACK, id: `STATS` }],
    }),
    updateTurnback: builder.mutation<void, Omit<Turnback, 'attachmentList'> & { attachmentList?: number[] }>({
      query: (body) => ({
        url: `${url}/${body.id}`,
        method: 'PUT',
        body: { ...body, cellId: body.cell.id, plantId: body.plant.id },
      }),
      invalidatesTags: () => [TAG_TYPES.TURNBACK],
    }),
    removeTurnback: builder.mutation<void, { id: Turnback['id'] }>({
      query: (body) => ({
        url: `${url}/${body.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => [TAG_TYPES.TURNBACK, { type: TAG_TYPES.TURNBACK, id: `STATS` }],
    }),
    updateTurnbackStatus: builder.mutation<void, TurnbackStatusForm & { id: Turnback['id'] }>({
      query: (body) => ({
        url: `${url}/${body.id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: () => [TAG_TYPES.TURNBACK],
    }),
    readTurnbackStats: builder.query<TurnbackStat[], { startDate: string; endDate: string; cellId: Cell['id'] }>({
      query: ({ cellId, startDate, endDate }) => {
        return {
          url: `${url}/stats`,
          params: new URLSearchParams(`?cellId=${cellId}&startDate=${startDate}&endDate=${endDate}`),
        };
      },
      transformResponse: (data) => TurnbackStatSchema.array().parse(data),
      providesTags: () => [{ type: TAG_TYPES.TURNBACK, id: `STATS` }],
    }),
  }),
  overrideExisting: false,
});

export const {
  useReadTurnbackListQuery,
  useLazyReadTurnbackListQuery,
  useLazyReadTurnbackByIdQuery,
  useCreateTurnbackMutation,
  useUpdateTurnbackMutation,
  useRemoveTurnbackMutation,
  useUpdateTurnbackStatusMutation,
  useLazyReadTurnbackStatsQuery,
} = turnbackApi;
