import { Cell } from '../entities/Cell';
import { Process, ProcessSchema } from '../entities/Process';
import { SettingsItemForm } from '../entities/SettingsItem';
import { api, TAG_TYPES } from './baseApi';

const url = 'processes';

const processApi = api.injectEndpoints({
  endpoints: (builder) => ({
    readCellProcessList: builder.query<Process[], Cell['id']>({
      query: (id) => ({ url: `${url}?cellId=eq:${id}` }),
      transformResponse: (data) => {
        return ProcessSchema.array()
          .parse(data)
          .sort((a, b) => (a.orderPosition ?? 0) - (b.orderPosition ?? 0));
      },
      providesTags: (_response, _error, id) => [{ type: TAG_TYPES.PROCESS, id: `CELL-PROCESS-LIST-${id}` }],
      keepUnusedDataFor: 3600,
    }),
    readProcessList: builder.query<Process[], void>({
      query: () => ({ url: `${url}` }),
      transformResponse: (data) => {
        return ProcessSchema.array().parse(data);
      },
      providesTags: () => [{ type: TAG_TYPES.PROCESS, id: `PROCESS-LIST` }],
      keepUnusedDataFor: 3600,
    }),
    updateCellProcessList: builder.mutation<void, { cellId: Cell['id']; processList: Process[] }>({
      query: ({ ...body }) => ({
        url: `${url}`,
        method: 'PATCH',
        body: body.processList.map((p) => ({ id: p.id, orderPosition: p.orderPosition })),
      }),
      invalidatesTags: (_response, _error, { cellId }) => [
        { type: TAG_TYPES.PROCESS, id: `CELL-PROCESS-LIST-${cellId}` },
        { type: TAG_TYPES.PROCESS, id: `PROCESS-LIST` },
      ],
      async onQueryStarted({ cellId, processList }, { dispatch, queryFulfilled }) {
        // Perform an optimistic update
        const patchResult = dispatch(
          processApi.util.updateQueryData('readCellProcessList', cellId, (draft) => {
            // Map for quick lookup of process by id
            const processMap = new Map(processList.map((p) => [p.id, p]));

            // Update the draft (optimistic)
            return draft
              .map((process) => {
                const update = processMap.get(process.id);
                if (update) {
                  return { ...process, ...update };
                }
                return process;
              })
              .sort((a, b) => (a.orderPosition ?? 0) - (b.orderPosition ?? 0));
          }),
        );

        try {
          // Await the server's response
          await queryFulfilled;
        } catch (error) {
          // If the server request fails, undo the optimistic update
          patchResult.undo();
        }
      },
    }),
    createProcess: builder.mutation<{ id: number }, SettingsItemForm & { cellId: Cell['id'] }>({
      query: (body) => ({
        url: `${url}`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (_response, _error, { cellId }) => [
        { type: TAG_TYPES.PROCESS, id: `CELL-PROCESS-LIST-${cellId}` },
        { type: TAG_TYPES.PROCESS, id: `PROCESS-LIST` },
      ],
    }),
    updateProcess: builder.mutation<void, SettingsItemForm & { cellId: Cell['id']; id: Process['id'] }>({
      query: (body) => ({
        url: `${url}/${body.id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (_response, _error, { cellId }) => [
        { type: TAG_TYPES.PROCESS, id: `CELL-PROCESS-LIST-${cellId}` },
        { type: TAG_TYPES.PROCESS, id: `PROCESS-LIST` },
      ],
    }),
    removeProcess: builder.mutation<void, { cellId: Cell['id']; id: Process['id'] }>({
      query: (body) => ({
        url: `${url}/${body.id}`,
        method: 'DELETE',
        body,
      }),
      invalidatesTags: (_response, _error, { cellId }) => [
        { type: TAG_TYPES.PROCESS, id: `CELL-PROCESS-LIST-${cellId}` },
        { type: TAG_TYPES.PROCESS, id: `PROCESS-LIST` },
      ],
    }),
  }),
  overrideExisting: false,
});

export const {
  useReadCellProcessListQuery,
  useReadProcessListQuery,
  useLazyReadCellProcessListQuery,
  useLazyReadProcessListQuery,
  useCreateProcessMutation,
  useRemoveProcessMutation,
  useUpdateProcessMutation,
  useUpdateCellProcessListMutation,
} = processApi;
