import { useState } from 'react';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { AddIcon } from '../../components/Icons';
import { AndonAddForm } from '../../entities/Andon';

import { AndonDialog } from './AndonDialog';

const initialValues: AndonAddForm = {
  reported_by: '',
  problem_type_id: null,
  workstation_id: null,
  opening_comments: '',
} as unknown as AndonAddForm;

export function AndonAddButton(props: IconButtonProps): JSX.Element {
  const [dialogOpen, setDialogOpen] = useState(false);

  return (
    <>
      <Tooltip title="Aggiungi Andon">
        <IconButton color="primary" onClick={() => setDialogOpen(true)} {...props}>
          <AddIcon />
        </IconButton>
      </Tooltip>
      {dialogOpen && (
        <AndonDialog open={dialogOpen} initialValues={initialValues} onClose={() => setDialogOpen(false)} />
      )}
    </>
  );
}
