import React, { forwardRef, useMemo } from 'react';

import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete';
import TextField, { TextFieldProps } from '@mui/material/TextField';

type GenericAutocompleteProps<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
> = {
  data: { id: number; name: string; isDeleted?: boolean | null }[];
  loading: boolean;
  loadingError: FetchBaseQueryError | SerializedError | undefined;
} & Omit<AutocompleteProps<number, Multiple, DisableClearable, FreeSolo>, 'options' | 'renderInput' | 'onInputChange'> &
  Pick<TextFieldProps, 'label' | 'error' | 'helperText' | 'variant' | 'required' | 'sx'>;

function GenericAutocompleteComponent<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>(
  props: GenericAutocompleteProps<Multiple, DisableClearable, FreeSolo>,
  ref: React.Ref<HTMLInputElement>,
): JSX.Element {
  const { value, label, error, helperText, variant, required, sx, data, loading, loadingError, ...autocompleteProps } =
    props;

  const disabledListValues = useMemo(() => {
    if (value) {
      const currentValueDisabled = data.find((d) => d.id === value && d.isDeleted);
      return currentValueDisabled ? [currentValueDisabled.id] : [];
    }
    return [];
  }, [value, data]);

  return (
    <Autocomplete
      value={value}
      options={[...disabledListValues, ...(data ? data.filter((i) => !i.isDeleted).map(({ id }) => id) : [])]}
      getOptionLabel={(id) => {
        return data?.find((t) => t.id === id)?.name || 'unknown';
      }}
      sx={sx}
      getOptionDisabled={(o) => !!disabledListValues.find((i) => i === o)}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant={variant}
          error={Boolean(loadingError) || error}
          helperText={(loadingError && 'message' in loadingError && loadingError.message) || helperText || undefined}
          required={required}
          inputRef={ref}
        />
      )}
      loading={loading}
      {...autocompleteProps}
    />
  );
}

export const GenericAutocomplete = forwardRef(GenericAutocompleteComponent) as typeof GenericAutocompleteComponent;
