import React, { useMemo, useState } from 'react';
import Grid from '@mui/material/Grid';
import { useLazyReadCategoryListByStepIdQuery } from '../../../../services/categoryApi';
import { useReadCellProcessListQuery } from '../../../../services/processApi';
import { useLazyReadStepListByProcessIdQuery } from '../../../../services/stepApi';
import { useCell } from '../../CellContext';
import CategorySettingsList from './CategorySettingsList';
import ProcessSettingsList from './ProcessSettingsList';
import StepSettingsList from './StepSettingsList';

export default function TurnbackSettings(): React.ReactElement {
  const { cell } = useCell();

  const { data: processList, isLoading, error } = useReadCellProcessListQuery(cell.id);

  const [selectedProcessId, setSelectedProcessId] = useState(null as null | number);
  const [selectedStepId, setSelectedStepId] = useState(null as null | number);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null as null | number);
  const [getStepList, { data: stepList, error: stepError, isLoading: isLoadingStep }] =
    useLazyReadStepListByProcessIdQuery();
  const [getCategoryList, { data: categoryList, error: categoryError, isLoading: isLoadingCategory }] =
    useLazyReadCategoryListByStepIdQuery();

  const selectedProcess = useMemo(
    () => processList?.find(({ id }) => id === selectedProcessId),
    [processList, selectedProcessId],
  );

  const selectedStep = useMemo(() => stepList?.find(({ id }) => id === selectedStepId), [selectedStepId, stepList]);

  const selectedCategory = useMemo(
    () => categoryList?.find(({ id }) => id === selectedCategoryId),
    [categoryList, selectedCategoryId],
  );
  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <ProcessSettingsList
          cell={cell}
          processList={processList?.filter((i) => !i.isDeleted)}
          readListRequest={{ isLoading, error }}
          selectedProcess={selectedProcess}
          onSelectedProcessChange={(process) => {
            setSelectedProcessId(process.id);
            getStepList(process.id);
            setSelectedStepId(null);
            setSelectedCategoryId(null);
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <StepSettingsList
          process={selectedProcess}
          stepList={stepList?.filter((i) => !i.isDeleted)}
          readListRequest={{ isLoading: isLoadingStep, error: stepError }}
          selectedStep={selectedStep}
          onSelectedStepChange={(step) => {
            setSelectedStepId(step.id);
            getCategoryList(step.id);
            setSelectedCategoryId(null);
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <CategorySettingsList
          step={selectedStep}
          categoryList={categoryList?.filter((i) => !i.isDeleted)}
          readListRequest={{ isLoading: isLoadingCategory, error: categoryError }}
          selectedCategory={selectedCategory}
          onSelectedCategoryChange={(cat) => setSelectedCategoryId(cat.id)}
        />
      </Grid>
    </Grid>
  );
}
