import { useMemo } from 'react';
import Chip from '@mui/material/Chip';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { GridSingleSelectColDef } from '@mui/x-data-grid-premium';
import { createMultipleValuesFilterOperators } from '@top-solution/microtecnica-mui';
import { HelpIcon } from '../../components/Icons';
import { KaizenSettings } from '../../entities/Kaizen';
import { SettingsItem } from '../../entities/SettingsItem';
import { useReadKaizenSettingsQuery } from '../../services/kaizenApi';
import { useReadKaizenITListQuery } from '../../services/kaizenITApi';
import { useReadKaizenSPQDCListQuery } from '../../services/kaizenSPQDCApi';

const NoMaxWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 'none',
  },
});

export function KaizenImageComponent(props: { type: keyof KaizenSettings }): JSX.Element | null {
  const { type } = props;
  const { data, isLoading, error } = useReadKaizenSettingsQuery();

  if (isLoading || error) return null;

  return (
    <NoMaxWidthTooltip
      title={
        <>
          {data && (
            <div style={{ height: '500px', width: '500px' }}>
              <img alt={type} src={`${data[type]?.path}`} style={{ height: '100%', width: '100%' }} />
            </div>
          )}
        </>
      }
    >
      <div>
        <HelpIcon fontSize="small" color="info" />
      </div>
    </NoMaxWidthTooltip>
  );
}

export function useKaizenItColumn(): GridSingleSelectColDef {
  const { data } = useReadKaizenITListQuery();
  const valueOptions = useMemo(() => data?.map((v) => ({ value: v.name, label: v.name })), [data]);
  return useMemo(
    () => ({
      field: 'it',
      headerName: 'IT',
      type: 'singleSelect',
      valueOptions: valueOptions,
      filterOperators: createMultipleValuesFilterOperators<SettingsItem>((item) => item.name),
      renderCell: ({ value }) => (
        <>
          {value.map((item: string) => (
            <Chip size="small" key={item} label={item} />
          ))}
        </>
      ),
      flex: 1,
      filterable: true,
      sortable: false,
      valueFormatter: (value: string | string[]) => (Array.isArray(value) ? value.map((v) => v).join(', ') : value),
    }),
    [valueOptions]
  );
}

export function useKaizenSPQDCColumn(): GridSingleSelectColDef {
  const { data } = useReadKaizenSPQDCListQuery();
  const valueOptions = useMemo(() => data?.map((v) => ({ value: v.name, label: v.name })), [data]);
  return useMemo(
    () => ({
      field: 'spqdc',
      headerName: 'SPQDC',
      type: 'singleSelect',
      valueOptions: valueOptions,
      filterOperators: createMultipleValuesFilterOperators<SettingsItem>((item) => item.name),
      renderCell: ({ value }) => {
        return (
          <>
            {value.map((item: string) => (
              <Chip size="small" key={item} label={item} />
            ))}
          </>
        );
      },
      flex: 1,
      filterable: true,
      sortable: false,
      valueFormatter: (value: string | string[]) => (Array.isArray(value) ? value.map((v) => v).join(', ') : value),
    }),
    [valueOptions]
  );
}
