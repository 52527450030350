import React, { useMemo } from 'react';

import Typography from '@mui/material/Typography';
import { DataGrid, DataGridWrapper } from '@top-solution/microtecnica-mui';
import { AuthGuard, useAuth } from '@top-solution/microtecnica-utils';
import { userColumn } from '../../components/DataGrid/userColumn';
import { Layout } from '../../components/Layout';
import { UserRole } from '../../entities/User';
import { useReadKaizenListByUserQuery } from '../../services/kaizenApi';
import UnauthorizedPage from '../Error/UnauthorizedPage';
import { kaizenList } from '../sections';

const percentageFormatter = new Intl.NumberFormat('it-IT', {
  style: 'percent',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const breadcrumbs = [{ title: kaizenList.title, url: kaizenList.url }];

function DataGridTitle(total: number | undefined) {
  return (
    <Typography variant="h5" m={1}>
      Totale Kaizen: {total}
    </Typography>
  );
}

export default function KaizenListByUser(): React.ReactElement {
  const { isLoading, data, error } = useReadKaizenListByUserQuery();
  const { hasRole } = useAuth();

  const rows = useMemo(
    () =>
      data?.byUser
        ?.map((kaizen) => ({
          ...kaizen,
          percentage: kaizen.counter / data.total,
        }))
        .sort((a, b) => b.counter - a.counter) || [],
    [data]
  );

  const columns = useMemo(
    () => [
      {
        ...userColumn,
        headerName: 'Utente',
        flex: 1,
      },
      {
        headerName: 'Kaizen inseriti',
        field: 'counter',
        flex: 1,
      },
      {
        headerName: '% sul totale',
        field: 'percentage',
        width: 100,
        valueFormatter: (value: number) => {
          return percentageFormatter.format(value);
        },
      },
    ],
    []
  );

  return (
    <AuthGuard unauthorizedFallback={<UnauthorizedPage />}>
      {hasRole(UserRole.USER) ? (
        <UnauthorizedPage />
      ) : (
        <Layout title="Conteggio Kaizen" breadcrumbs={breadcrumbs} error={error} disableGutters>
          <DataGridWrapper>
            <DataGrid
              slots={{
                toolbar: () => DataGridTitle(data?.total),
              }}
              getRowId={(data) => data.username}
              density="compact"
              rows={rows ?? []}
              columns={columns}
              sessionStorageId="KaizenListByUserDataGrid"
              loading={isLoading}
              pagination
              paginationMode="client"
              autoPageSize
              rowCount={rows.length ?? 0}
              sortingMode="client"
              filterMode="client"
            />
          </DataGridWrapper>
        </Layout>
      )}
    </AuthGuard>
  );
}
