import React, { forwardRef } from 'react';
import { Plant, useReadPlantsQuery } from '@top-solution/microtecnica-utils';
import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete';
import TextField, { TextFieldProps } from '@mui/material/TextField';

export type PlantAutocompleteProps<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
> = Omit<
  AutocompleteProps<Plant['id'], Multiple, DisableClearable, FreeSolo>,
  'options' | 'renderInput' | 'onInputChange'
> &
  Pick<TextFieldProps, 'label' | 'error' | 'helperText' | 'variant' | 'required' | 'sx'>;

function PlantAutocompleteComponent<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>(props: PlantAutocompleteProps<Multiple, DisableClearable, FreeSolo>, ref: React.Ref<HTMLInputElement>): JSX.Element {
  const { value, label, error, helperText, variant, required, sx, ...autocompleteProps } = props;
  const { data, isFetching, error: loadingError } = useReadPlantsQuery();

  return (
    <Autocomplete
      value={value}
      options={data?.list.map(({ id }) => id) ?? []}
      getOptionLabel={(id) => {
        return data?.map[id as string].name || 'unknown';
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant={variant}
          error={Boolean(loadingError) || error}
          helperText={(loadingError && 'message' in loadingError && loadingError.message) || helperText || undefined}
          required={required}
          inputRef={ref}
          sx={sx}
        />
      )}
      loading={isFetching}
      {...autocompleteProps}
    />
  );
}

export const PlantAutocomplete = forwardRef(PlantAutocompleteComponent) as typeof PlantAutocompleteComponent;
