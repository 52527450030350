import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button, { ButtonProps } from '@mui/material/Button';

type ChooseGridButtonProps = ButtonProps & {
  label: string;
  icon?: string;
  to?: string;
};

export default function ChooseGridButton(props: ChooseGridButtonProps): React.ReactElement {
  const { label, icon, to, sx, ...buttonProps } = props;
  const [imageError, setImageError] = useState(false);
  const src = useMemo(() => (imageError ? '/icons/not_found.svg' : icon), [icon, imageError]);

  return (
    <Button
      size="large"
      color="primary"
      variant="outlined"
      component={to ? Link : 'button'}
      to={to}
      sx={{
        width: 200,
        height: 200,
        margin: 1,
        padding: 1,
        ...sx,
      }}
      {...buttonProps}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
        {icon !== '' && (
          <img
            src={src}
            alt=""
            style={{ height: 90, width: 150, objectFit: 'scale-down', marginBottom: 8 }}
            onError={() => setImageError(true)}
          />
        )}
        <span>{label}</span>
      </Box>
    </Button>
  );
}
