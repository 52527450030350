import { useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { ErrorAlert, UserAutocomplete } from '@top-solution/microtecnica-mui';
import { useAuth } from '@top-solution/microtecnica-utils';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import FormRow from '../../components/Form/FormRow';
import { GenericAutocomplete } from '../../components/Form/GenericAutocomplete';
import { SaveIcon } from '../../components/Icons';
import { Term, Value } from '../../components/Layout/Description';
import { UserName } from '../../components/User/UserName';
import { AndonAddForm, AndonAddFormSchema } from '../../entities/Andon';
import { useCreateAndonMutation } from '../../services/andonApi';
import { useReadProblemListQuery } from '../../services/problemApi';
import { useReadCellWorkstationListQuery } from '../../services/workstationApi';
import { formatDateShort } from '../../utils/date';
import { useCell } from '../Cell/CellContext';
import { usePlant } from '../Plant/PlantContext';

type AndonDialogProps = DialogProps & {
  initialValues: AndonAddForm;
  onClose: () => void;
};

export function AndonDialog(props: AndonDialogProps): JSX.Element {
  const { initialValues, onClose, ...dialogProps } = props;
  const [create, createStatus] = useCreateAndonMutation();
  const { cell } = useCell();
  const { plant } = usePlant();
  const { data: problemList, isFetching: loadingProblems, error: problemError } = useReadProblemListQuery();
  const {
    data: workstationList,
    isFetching: loadingWorkstation,
    error: workstationError,
  } = useReadCellWorkstationListQuery(cell.id);
  const { username } = useAuth();

  const form = useForm<AndonAddForm>({
    defaultValues: { ...initialValues, reportedBy: username || '' },
    resolver: zodResolver(AndonAddFormSchema),
  });
  const { control } = form;

  const onSubmit = useCallback(
    async (data: AndonAddForm) => {
      await create({ ...data, plantId: plant.id, cellId: cell.id }).unwrap();

      onClose();
    },
    [create, plant.id, cell.id, onClose],
  );

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      {...dialogProps}
      TransitionProps={{
        ...dialogProps.TransitionProps,
        onExit: (event) => {
          createStatus.reset();
          form.reset();
          dialogProps.TransitionProps?.onExit?.(event);
        },
      }}
    >
      <form onSubmit={form.handleSubmit(onSubmit)} noValidate>
        <DialogTitle>{'Nuovo Andon'}</DialogTitle>
        <DialogContent sx={{ display: 'flex', flexDirection: 'column', '.MuiFormControl-root': { mt: 1 } }}>
          <Grid container component="dl">
            <Grid item xs={6}>
              <Term>Creato da:</Term>
              <Value>{username && <UserName username={username} />}</Value>
            </Grid>
            <Grid item xs={6}>
              <Term>Data:</Term>
              <Value>{formatDateShort(new Date())}</Value>
            </Grid>
          </Grid>
          <Controller
            control={control}
            name="reportedBy"
            render={({ field: { onChange, ...field }, fieldState: { error } }) => (
              <UserAutocomplete
                label="Segnalato da"
                onChange={(_, value) => onChange(value)}
                disableClearable
                error={Boolean(error)}
                helperText={error?.message ?? ' '}
                {...field}
                required
              />
            )}
          />
          <FormRow>
            <Controller
              control={control}
              name="problemTypeId"
              render={({ field: { onChange, ...field }, fieldState: { error } }) => (
                <GenericAutocomplete
                  data={problemList || []}
                  loading={loadingProblems}
                  loadingError={problemError}
                  label="Problem"
                  onChange={(_, value) => onChange(value)}
                  disableClearable
                  error={Boolean(error)}
                  helperText={error?.message ?? ' '}
                  sx={{ flexGrow: 1 }}
                  {...field}
                  required
                />
              )}
            />
            <Controller
              control={control}
              name="workstationId"
              render={({ field: { onChange, ...field }, fieldState: { error } }) => (
                <GenericAutocomplete
                  data={workstationList || []}
                  loading={loadingWorkstation}
                  loadingError={workstationError}
                  label="Workstation"
                  onChange={(_, value) => onChange(value)}
                  disableClearable
                  error={Boolean(error)}
                  helperText={error?.message ?? ' '}
                  sx={{ flexGrow: 1 }}
                  {...field}
                  required
                />
              )}
            />
          </FormRow>
          <Controller
            control={control}
            name="openingComments"
            render={({ field, fieldState: { error } }) => (
              <TextField
                label="Note di apertura"
                error={Boolean(error)}
                helperText={error?.message || ' '}
                sx={{ flex: 1 }}
                {...field}
              />
            )}
          />
          {createStatus.error && <ErrorAlert error={createStatus.error} />}
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={() => onClose()}>
            Annulla
          </Button>
          <LoadingButton
            type="submit"
            color="primary"
            variant="contained"
            loading={createStatus.isLoading}
            loadingPosition="start"
            startIcon={<SaveIcon />}
          >
            Salva
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
}
