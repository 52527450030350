import { useMemo } from 'react';
import { formatDistance } from 'date-fns';
import { it } from 'date-fns/locale/it';
import { GridColDef } from '@mui/x-data-grid-premium';
import { stringDateColumn } from '../../components/DataGrid/stringDateColumn';
import { userColumn } from '../../components/DataGrid/userColumn';
import { KAIZEN_STATUS } from '../../entities/Kaizen';
import { euro } from '../../utils/currency';
import { useKaizenItColumn, useKaizenSPQDCColumn } from './KaizenTagsComponent';
import { useKaizenStatusDataGridColumn } from './useKaizenStatusDataGridColumn';
import { useKaizenTypeDataGridColumn } from './useKaizenTypeDataGridColumn';

export function useColumns(): GridColDef[] {
  const statusColumn = useKaizenStatusDataGridColumn();
  const typeColumn = useKaizenTypeDataGridColumn();
  const itColumn = useKaizenItColumn();
  const spqdcColumn = useKaizenSPQDCColumn();
  return useMemo<GridColDef[]>(
    () => [
      {
        ...statusColumn,
        width: 120,
      },
      {
        headerName: 'Tempo Risposta',
        field: 'response_time',
        valueGetter: (value, row) => {
          return row.status.id === KAIZEN_STATUS.TAKEN_CHARGE
            ? '–'
            : formatDistance(new Date(row.createDate || Date.now()), new Date(row.lastUpdate || Date.now()), {
                locale: it,
              });
        },
        filterable: false,
        sortable: false,
        width: 120,
      },
      {
        ...stringDateColumn,
        headerName: 'Data',
        field: 'createDate',
      },
      {
        ...userColumn,
        headerName: 'Segnalato da',
        field: 'reportedBy',
        width: 120,
      },
      {
        ...userColumn,
        headerName: 'Aperto da',
        field: 'openBy',
        width: 120,
      },
      {
        headerName: 'Reparto',
        field: 'cell',
        valueGetter: (value, row) => {
          return row.cell.name;
        },
        width: 120,
      },
      {
        ...typeColumn,
        width: 160,
      },
      {
        headerName: 'Risparmio stimato',
        field: 'estimatedSavings',
        type: 'number',
        width: 160,
        valueFormatter: (value) => value && euro.format(value),
      },
      {
        headerName: 'Soluzione proposta',
        field: 'suggestion',
        flex: 1,
      },
      {
        ...itColumn,
      },
      {
        ...spqdcColumn,
      },
    ],
    [itColumn, spqdcColumn, statusColumn, typeColumn]
  );
}
