import { useMemo } from 'react';
import { GridSingleSelectColDef } from '@mui/x-data-grid-premium';
import { Turnback } from '../../entities/Turnback';
import { useReadCellListByPlantIdQuery } from '../../services/cellApi';
import { usePlant } from '../Plant/PlantContext';

export function useTurnbackResponsibleDataGridColumn(): GridSingleSelectColDef {
  const { plant } = usePlant();
  const { data } = useReadCellListByPlantIdQuery(plant.id);

  const valueOptions = useMemo(() => data?.map((v) => ({ value: v.id, label: v.name })), [data]);
  return useMemo(
    () => ({
      field: 'responsibleCellId',
      headerName: 'Reparto responsabile',
      type: 'singleSelect',
      valueOptions,
      valueGetter: (value, row: Turnback) => {
        return row.responsibleCell?.id;
      },
    }),
    [valueOptions]
  );
}

export function useTurnbackCellDataGridColumn(): GridSingleSelectColDef {
  const { plant } = usePlant();
  const { data } = useReadCellListByPlantIdQuery(plant.id);

  const valueOptions = useMemo(() => data?.map((v) => ({ value: v.id, label: v.name })), [data]);
  return useMemo(
    () => ({
      field: 'cellId',
      headerName: 'Reparto',
      type: 'singleSelect',
      valueOptions,
      valueGetter: (value, row: Turnback) => {
        return row.cell.id;
      },
    }),
    [valueOptions]
  );
}
