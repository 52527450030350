import React, { useMemo, useState, useCallback } from 'react';
import { DataGridWrapper } from '@top-solution/microtecnica-mui';
import { AuthGuard, useAuth } from '@top-solution/microtecnica-utils';
import Button from '@mui/material/Button';
import { GridActionsCellItem, GridColDef, GridRowParams, MuiEvent } from '@mui/x-data-grid-premium';
import { DeleteIcon, DetailsIcon, EditIcon } from '../../components/Icons';
import { Layout } from '../../components/Layout';
import { KAIZEN_STATUS, Kaizen } from '../../entities/Kaizen';
import UnauthorizedPage from '../Error/UnauthorizedPage';
import { usePlant } from '../Plant/PlantContext';
import { useColumns } from './columns';
import { KaizenDeleteConfirm } from './KaizenDeleteConfirm';
import { KaizenDetailDialog } from './KaizenDetail';
import { KaizenDialog } from './KaizenDialog';
import KaizenListGrid from './KaizenListGrid';
import { KaizenStatusDialog } from './KaizenStatusDialog';

export default function KaizenListByPlant(): React.ReactElement {
  const { plant } = usePlant();
  const { username, isAdmin } = useAuth();

  const breadcrumbs = useMemo(
    () => [
      {
        title: plant.name,
        url: `/plants/${plant.id}/cells`, // TODO: Store this in section and replace plantId
      },
      { title: 'kaizen' },
    ],
    [plant],
  );

  const kaizenColumns = useColumns();
  const [kaizenToRemove, setKaizenToRemove] = useState<Kaizen | null>(null);
  const [kaizenToUpdate, setKaizenToUpdate] = useState<Kaizen | null>(null);
  const [kaizenToUpdateStatus, setKaizenToUpdateStatus] = useState<Kaizen | null>(null);
  const [kaizenToView, setKaizenToView] = useState<Kaizen | null>(null);

  const handleDetailClick = useCallback(
    ({ row }: GridRowParams<Kaizen>) =>
      (event: MuiEvent<React.MouseEvent>) => {
        event.stopPropagation();
        setKaizenToView(row);
      },
    [],
  );

  const handleEditClick = useCallback(
    ({ row }: GridRowParams<Kaizen>) =>
      (event: MuiEvent<React.MouseEvent>) => {
        event.stopPropagation();
        setKaizenToUpdate(row);
      },
    [],
  );

  const handleDeleteClick = useCallback(
    ({ row }: GridRowParams<Kaizen>) =>
      (event: MuiEvent<React.MouseEvent>) => {
        event.stopPropagation();
        setKaizenToRemove(row);
      },
    [],
  );

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        headerName: 'ID',
        field: 'id',
        width: 60,
        type: 'number',
        renderCell: ({ row }) => (
          <Button size="small" color="primary" onClick={() => setKaizenToView(row)}>
            {row.id}
          </Button>
        ),
      },
      ...kaizenColumns,
      {
        field: 'actions',
        type: 'actions',
        width: 120,
        getActions: (params: GridRowParams<Kaizen>) => [
          params.row.status.id === KAIZEN_STATUS.TAKEN_CHARGE && params.row.openBy === username ? (
            <GridActionsCellItem icon={<EditIcon />} key="edit" label="Modifica" onClick={handleEditClick(params)} />
          ) : (
            <GridActionsCellItem
              icon={<DetailsIcon />}
              key="view"
              label="Dettaglio"
              onClick={handleDetailClick(params)}
            />
          ),
          <GridActionsCellItem
            icon={<DeleteIcon />}
            key="delete"
            label="Elimina"
            onClick={handleDeleteClick(params)}
            disabled={
              !isAdmin && (params.row.status.id !== KAIZEN_STATUS.TAKEN_CHARGE || params.row.openBy !== username)
            }
          />,
        ],
      },
    ],
    [handleDeleteClick, handleDetailClick, handleEditClick, isAdmin, kaizenColumns, username],
  );

  return (
    <AuthGuard unauthorizedFallback={<UnauthorizedPage />}>
      <Layout title={`Kaizen ${plant?.name}`} breadcrumbs={breadcrumbs} maxWidth={false}>
        <DataGridWrapper>
          <KaizenListGrid columns={columns} sessionStorageId={`KaizenListByPlant${plant?.name}`} />
        </DataGridWrapper>
        {kaizenToUpdate && (
          <KaizenDialog
            onClose={() => setKaizenToUpdate(null)}
            open={Boolean(kaizenToUpdate)}
            initialValues={{ ...kaizenToUpdate, typeId: kaizenToUpdate.type.id }}
            kaizen={kaizenToUpdate}
          />
        )}
        {kaizenToRemove && (
          <KaizenDeleteConfirm
            open={Boolean(kaizenToRemove)}
            data={kaizenToRemove}
            onClose={() => setKaizenToRemove(null)}
          />
        )}
        {kaizenToUpdateStatus && (
          <KaizenStatusDialog
            open={Boolean(kaizenToUpdateStatus)}
            kaizen={kaizenToUpdateStatus}
            onClose={() => setKaizenToUpdateStatus(null)}
          />
        )}
        {kaizenToView && (
          <KaizenDetailDialog
            open={Boolean(kaizenToView)}
            kaizen={kaizenToView}
            onClose={() => setKaizenToView(null)}
          />
        )}
      </Layout>
    </AuthGuard>
  );
}
