import React, { useCallback } from 'react';
import { SettingsItemForm } from '../../../../entities/SettingsItem';
import { Workstation } from '../../../../entities/Workstation';
import {
  useCreateWorkstationMutation,
  useReadCellWorkstationListQuery,
  useRemoveWorkstationMutation,
  useUpdateWorkstationMutation,
} from '../../../../services/workstationApi';
import { useCell } from '../../CellContext';
import { ItemAddDialog, ItemEditDialog } from '../ItemDialog';
import SettingsList from '../SettingsList';

export default function WorkstationSettingsList(): React.ReactElement {
  const { cell } = useCell();

  const { data, isLoading, error } = useReadCellWorkstationListQuery(cell.id);
  const [remove, removeState] = useRemoveWorkstationMutation();
  const [create, createState] = useCreateWorkstationMutation();
  const [update, updateState] = useUpdateWorkstationMutation();

  const handleRemove = useCallback(
    async (workstation: Workstation) => {
      return remove({ ...workstation, cellId: cell.id });
    },
    [remove, cell.id],
  );

  const handleUpdate = useCallback(
    async (workstation: Workstation) => {
      return update({ ...workstation, cellId: cell.id });
    },
    [cell.id, update],
  );

  const handleCreate = useCallback(
    async (values: SettingsItemForm) => {
      return create({ ...values, cellId: cell.id });
    },
    [cell.id, create],
  );

  return (
    <SettingsList
      title="Workstation"
      items={data?.filter((i) => !i.isDeleted)}
      readListRequest={{ isLoading, error }}
      itemRemove={handleRemove}
      itemRemoveRequest={removeState}
      addProps={{
        AddDialog: ItemAddDialog,
        submit: handleCreate,
        request: createState,
      }}
      editPros={{
        EditDialog: ItemEditDialog,
        submit: handleUpdate,
        request: updateState,
      }}
    />
  );
}
