import { useMemo } from 'react';
import { GridSingleSelectColDef } from '@mui/x-data-grid-premium';
import { useReadKaizenTypeListQuery } from '../../services/kaizenTypeApi';

export function useKaizenTypeDataGridColumn(): GridSingleSelectColDef {
  const { data } = useReadKaizenTypeListQuery();
  const valueOptions = useMemo(() => data?.map((v) => ({ value: v.id, label: v.name })), [data]);
  return useMemo(
    () => ({
      field: 'kaizenTypeId',
      headerName: 'Tipologia Kaizen',
      type: 'singleSelect',
      valueOptions,
      valueGetter: (value, row) => {
        return row.type.id;
      },
    }),
    [valueOptions]
  );
}
