import { useMemo } from 'react';
import { Duration, formatDuration, intervalToDuration } from 'date-fns';
import { it } from 'date-fns/locale';
import { TypographyProps } from '@mui/material/Typography';
import useTime from './useTime';

function formatDurationShort(duration: Duration): string {
  if (duration.years) return '> 1 anno';
  if (duration.months) return '> 1 mese';
  if (duration.days) return '> 24 hr';
  return `${duration.hours}:${`00${duration.minutes}`.slice(-2)}:${`00${duration.seconds}`.slice(-2)}`;
}

interface RollingTimeProps {
  start: Date;
  shortFormat?: boolean;
}

function RollingTime(props: RollingTimeProps): JSX.Element {
  const { start, shortFormat } = props;
  const time = useTime();
  const duration = useMemo(() => {
    const duration = intervalToDuration({ start, end: time });
    return shortFormat ? formatDurationShort(duration) : formatDuration(duration, { locale: it });
  }, [shortFormat, start, time]);

  return <span>{duration}</span>;
}

type WaitingTimeProps = {
  start?: Date;
  end?: Date;
  shortFormat?: boolean;
} & TypographyProps;

export default function WaitingTime(props: WaitingTimeProps): JSX.Element {
  const { start, end, shortFormat, ...typographyProps } = props;

  if (start && end) {
    const duration = intervalToDuration({ start, end });
    return <span>{shortFormat ? formatDurationShort(duration) : formatDuration(duration, { locale: it })}</span>;
  }

  if (start) {
    return <RollingTime start={start} shortFormat={shortFormat} {...typographyProps} />;
  }

  return <span>–</span>;
}
