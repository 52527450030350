import { useMemo } from 'react';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-premium';
import { Andon } from '../../entities/Andon';
import { TechnicalSupport } from './TechnicalSupport';

export function useAndonContactDataGridColumn(): GridColDef {
  return useMemo(
    () => ({
      headerName: 'Supporto Tecnico',
      field: 'contacts',
      type: 'string',
      renderCell: ({ row }: GridRenderCellParams<Andon>) => {
        return <TechnicalSupport andon={row} />;
      },
    }),
    [],
  );
}
