import { GridColDef } from '@mui/x-data-grid-premium';
import { CheckIcon } from '../../components/Icons';

export function useTurnbackRTPRDataGridColumn(): GridColDef {
  return {
    headerName: 'RTPR',
    field: 'isRtpr',
    width: 100,
    type: 'boolean',
    renderCell: ({ row }) => {
      return row.isRtpr ? <CheckIcon /> : '';
    },
  };
}
