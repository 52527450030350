import { GridFilterOperator, getGridDateOperators } from '@mui/x-data-grid-premium';

const checkFilter = ({ value }: GridFilterOperator) => {
  return value !== 'isEmpty' && value !== 'isNotEmpty' && value !== 'isAnyOf';
};

export const nonEmptyGridDateOperators = () =>
  getGridDateOperators()
    .filter(checkFilter)
    .filter((operator) => operator.value !== 'is' && operator.value !== 'not');
