import React, { useCallback } from 'react';
import { skipToken } from '@reduxjs/toolkit/query';
import { Support, SupportForm } from '../../../../entities/Support';
import {
  useCreateSupportMutation,
  useReadSupportListByProblemTypeIdQuery,
  useRemoveSupportMutation,
  useUpdateSupportMutation,
} from '../../../../services/supportApi';
import { useCell } from '../../CellContext';
import SettingsList from '../SettingsList';
import { SupportEditDialog, SupportAddDialog } from './SupportDialog';

interface SupportSettingsListProps {
  problemId: number | null;
}

function formatDisplayValue(value: Support) {
  return `${value.level.id} - ${value.lastname} ${value.name} (${value.telephone})`;
}

export default function SupportSettingsList(props: SupportSettingsListProps): React.ReactElement {
  const { cell } = useCell();
  const { problemId } = props;

  const { data, isLoading, error } = useReadSupportListByProblemTypeIdQuery(
    problemId
      ? {
          problemTypeId: problemId,
          cellId: cell.id,
        }
      : skipToken
  );
  const [remove, removeState] = useRemoveSupportMutation();
  const [create, createState] = useCreateSupportMutation();
  const [update, updateState] = useUpdateSupportMutation();

  const handleRemove = useCallback(
    async (support: Support) => {
      return remove({ ...support, problemTypeId: problemId!, cellId: cell.id });
    },
    [remove, problemId, cell.id]
  );

  const handleUpdate = useCallback(
    async (support: Support) => {
      return update({
        ...support,
        cellId: cell.id,
        problemTypeId: problemId!,
        levelId: support.level.id,
      });
    },
    [cell.id, problemId, update]
  );

  const handleCreate = useCallback(
    async (values: SupportForm) => {
      return create({ ...values, cellId: cell.id, problemTypeId: problemId! });
    },
    [cell.id, create, problemId]
  );

  return (
    <SettingsList
      title="Support"
      items={data?.filter((i) => !i.isDeleted)}
      readListRequest={{ isLoading, error }}
      itemRemove={handleRemove}
      itemRemoveRequest={removeState}
      formatName={(v) => formatDisplayValue(v)}
      addProps={{
        AddDialog: SupportAddDialog,
        submit: handleCreate,
        request: createState,
      }}
      editPros={{
        EditDialog: SupportEditDialog,
        submit: handleUpdate,
        request: updateState,
      }}
    />
  );
}
