import { z } from 'zod';
import { StepSchema } from './Step';

export const CategorySchema = z.object({
  id: z.number(),
  name: z.string().nonempty(),
  step: StepSchema,
  isDeleted: z.boolean().optional().nullable(),
  orderPosition: z.number().optional(),
});

export type Category = z.infer<typeof CategorySchema>;
