import React, { useCallback, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { ErrorAlert } from '@top-solution/microtecnica-mui';
import { LoadingButton } from '@mui/lab';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { SaveIcon } from '../../../components/Icons';
import { SettingsItem, SettingsItemForm, SettingsItemFormSchema } from '../../../entities/SettingsItem';
import { BasicRequest } from './SettingsList';

type ItemDialogProps = Omit<DialogProps, 'onClose' | 'onSubmit'> & {
  onClose: () => void;
  request: BasicRequest;
};
export type ItemEditDialogProps<T extends SettingsItem> = ItemDialogProps & {
  item: T;
  onSubmit: (data: SettingsItemForm) => void;
};
export type ItemAddDialogProps = ItemDialogProps & {
  onSubmit: (data: SettingsItemForm) => void;
};

function ItemDialog(
  props: ItemDialogProps & {
    defaultValues: { name: string };
    onSubmit: (data: SettingsItemForm) => Promise<void>;
  },
): React.ReactElement {
  const { open, title, defaultValues, request, onSubmit, onClose, ...dialogProps } = props;

  const form = useForm<SettingsItem>({
    defaultValues,
    resolver: zodResolver(SettingsItemFormSchema),
  });

  const onHandleSubmit = useCallback(
    async (data: SettingsItemForm) => {
      onSubmit(data);
      form.reset();
      onClose();
    },
    [form, onClose, onSubmit],
  );

  return (
    <Dialog open={open} {...dialogProps}>
      <form onSubmit={form.handleSubmit(onHandleSubmit)} noValidate>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent sx={{ py: 1 }}>
          <Box sx={{ py: 1 }}>
            <Controller
              control={form.control}
              name="name"
              render={({ field, fieldState: { error } }) => (
                <TextField
                  label="Nome"
                  error={Boolean(error)}
                  helperText={error?.message ?? ' '}
                  {...field}
                  required
                  fullWidth
                />
              )}
            />
          </Box>
          {request.error && <ErrorAlert error={request.error} />}
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={() => onClose()}>
            Annulla
          </Button>
          <LoadingButton
            type="submit"
            color="primary"
            variant="contained"
            loading={request.isLoading}
            loadingPosition="start"
            startIcon={<SaveIcon />}
          >
            Salva
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export function ItemEditDialog<T extends SettingsItem>(props: ItemEditDialogProps<T>): React.ReactElement {
  const { item, onSubmit, ...dialogProps } = props;

  const defaultValues = useMemo(() => ({ name: item?.name || '' }), [item]);

  const onHandleSubmit = useCallback(
    async (data: SettingsItemForm) => {
      await onSubmit({ ...item, ...data });
    },
    [item, onSubmit],
  );

  return <ItemDialog defaultValues={defaultValues} onSubmit={onHandleSubmit} {...dialogProps} />;
}

export function ItemAddDialog(props: ItemAddDialogProps): React.ReactElement {
  const { onSubmit, ...dialogProps } = props;

  const onHandleSubmit = useCallback(
    async (data: SettingsItemForm) => {
      await onSubmit(data);
    },
    [onSubmit],
  );

  return <ItemDialog defaultValues={{ name: '' }} onSubmit={onHandleSubmit} {...dialogProps} />;
}
