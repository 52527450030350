import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import { Term, Value } from '../../components/Layout/Description';
import { UserName } from '../../components/User/UserName';
import { ANDON_STATUS, Andon } from '../../entities/Andon';
import { formatDateTime } from '../../utils/date';
import { TechnicalSupport } from './TechnicalSupport';
import { AndonStatusChip } from './useAndonStatusDataGridColumn';
import WaitingTime from './WaitingTime';

type AndonDetailDialogProps = DialogProps & {
  andon: Andon;
  onClose: (data: Andon | null) => void;
};

export function AndonDetail(props: { andon: Andon; showMore?: boolean }): JSX.Element {
  const { andon, showMore } = props;

  const moreDetails = (
    <>
      <Grid item xs={6}>
        <Term>Arrivato il:</Term>
        <Value>{formatDateTime(andon.arrivalTime) || '-'}</Value>
      </Grid>
      <Grid item xs={6}>
        <Term>Durata intervento:</Term>
        <Value>
          <WaitingTime
            start={andon.arrivalTime ? new Date(andon.arrivalTime) : undefined}
            end={andon.closingTime ? new Date(andon.closingTime) : undefined}
          />
        </Value>
      </Grid>
      <Grid item xs={6}>
        <Term>Chiuso il:</Term>
        <Value>{formatDateTime(andon.closingTime) || '-'}</Value>
      </Grid>
      <Grid item xs={6}>
        <Term>Durata totale:</Term>
        <Value>
          <WaitingTime
            start={andon.status === ANDON_STATUS.CLOSED ? new Date(andon.createDate) : undefined}
            end={andon.closingTime ? new Date(andon.closingTime) : undefined}
          />
        </Value>
      </Grid>
      {andon.closingComments && (
        <Grid item xs={12}>
          <Term>Note chiusura:</Term>
          <Value>{andon.closingComments}</Value>
        </Grid>
      )}
    </>
  );

  return (
    <Grid container component="dl">
      {showMore && (
        <Grid item xs={12}>
          <Term>Stato:</Term>
          <Value>
            <AndonStatusChip status={andon.status} />
          </Value>
        </Grid>
      )}
      <Grid item xs={6}>
        <Term>Seganalto da:</Term>
        <Value>{andon.reportedBy}</Value>
      </Grid>
      <Grid item xs={6}>
        <Term>Aperto da:</Term>
        <Value>
          <UserName username={andon.openBy} />
        </Value>
      </Grid>
      <Grid item xs={6}>
        <Term>Problema:</Term>
        <Value>{andon.problemType.name}</Value>
      </Grid>
      <Grid item xs={6}>
        <Term>Workstation:</Term>
        <Value>{andon.workstation.name}</Value>
      </Grid>
      <Grid item xs={6}>
        <Term>Aperto il:</Term>
        <Value>{formatDateTime(andon.createDate)}</Value>
      </Grid>
      <Grid item xs={6}>
        <Term>Durata attesa:</Term>
        <Value>
          <WaitingTime
            start={new Date(andon.createDate)}
            end={andon.arrivalTime ? new Date(andon.arrivalTime) : undefined}
          />
        </Value>
      </Grid>
      {andon.openingComments && (
        <Grid item xs={12}>
          <Term>Note apertura:</Term>
          <Value>{andon.openingComments}</Value>
        </Grid>
      )}
      <Grid item xs={12}>
        <Term>Supporto Tecnico:</Term>
        <Value>
          <TechnicalSupport andon={andon} />
        </Value>
      </Grid>
      {showMore && moreDetails}
    </Grid>
  );
}

export function AndonDetailDialog(props: AndonDetailDialogProps): JSX.Element {
  const { andon, onClose, ...dialogProps } = props;

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      {...dialogProps}
      TransitionProps={{
        ...dialogProps.TransitionProps,
        onExit: (event) => {
          dialogProps.TransitionProps?.onExit?.(event);
        },
      }}
    >
      <DialogTitle>Dettaglio Andon</DialogTitle>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column' }}>
        <AndonDetail andon={andon} showMore />
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="contained" onClick={() => onClose(null)}>
          Chiudi
        </Button>
      </DialogActions>
    </Dialog>
  );
}
