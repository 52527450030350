import { Cell } from '../entities/Cell';
import { Process } from '../entities/Process';
import { SettingsItemForm } from '../entities/SettingsItem';
import { Step, StepSchema } from '../entities/Step';
import { api, TAG_TYPES } from './baseApi';

const url = 'steps';

const stepApi = api.injectEndpoints({
  endpoints: (builder) => ({
    readStepListByProcessId: builder.query<Step[], Process['id']>({
      query: (id) => ({ url: `${url}?processId=eq:${id}` }),
      transformResponse: (data) => {
        return StepSchema.array()
          .parse(data)
          .sort((a, b) => (a.orderPosition ?? 0) - (b.orderPosition ?? 0));
      },
      providesTags: (_response, _error, id) => [{ type: TAG_TYPES.STEP, id: `PROCESS-STEP-LIST-${id}` }],
      keepUnusedDataFor: 3600,
    }),
    readStepListByCellId: builder.query<Step[], Cell['id']>({
      query: (id) => ({ url: `${url}?cellId=eq:${id}` }),
      transformResponse: (data) => {
        return StepSchema.array().parse(data);
      },
      providesTags: () => [{ type: TAG_TYPES.STEP, id: `PROCESS-STEP-LIST` }],
      keepUnusedDataFor: 3600,
    }),
    updateProcessStepList: builder.mutation<void, { processId: Process['id']; stepList: Step[] }>({
      query: ({ ...body }) => ({
        url: `${url}`,
        method: 'PATCH',
        body: body.stepList.map((p) => ({ id: p.id, orderPosition: p.orderPosition })),
      }),
      invalidatesTags: (_response, _error, { processId }) => [
        { type: TAG_TYPES.PROCESS, id: `PROCESS-STEP-LIST-${processId}` },
        { type: TAG_TYPES.PROCESS, id: `PROCESS-STEP-LIST` },
      ],
      async onQueryStarted({ processId, ...patch }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          stepApi.util.updateQueryData('readStepListByProcessId', processId, (draft) => {
            Object.assign(draft, patch.stepList);
          }),
        );
        queryFulfilled.catch(patchResult.undo);
      },
    }),
    readStepList: builder.query<Step[], void>({
      query: () => ({ url: `${url}` }),
      transformResponse: (data) => {
        return StepSchema.array().parse(data);
      },
      providesTags: () => [{ type: TAG_TYPES.STEP, id: `PROCESS-STEP-LIST` }],
      keepUnusedDataFor: 3600,
    }),
    createStep: builder.mutation<{ id: number }, SettingsItemForm & { processId: Process['id'] }>({
      query: (body) => ({
        url: `${url}`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (_response, _error, { processId }) => [
        { type: TAG_TYPES.STEP, id: `PROCESS-STEP-LIST-${processId}` },
        { type: TAG_TYPES.STEP, id: `PROCESS-STEP-LIST` },
      ],
    }),
    updateStep: builder.mutation<void, SettingsItemForm & { id: Step['id']; processId: Process['id'] }>({
      query: (body) => ({
        url: `${url}/${body.id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (_response, _error, { processId }) => [
        { type: TAG_TYPES.STEP, id: `PROCESS-STEP-LIST-${processId}` },
        { type: TAG_TYPES.STEP, id: `PROCESS-STEP-LIST` },
      ],
    }),
    removeStep: builder.mutation<void, { id: Step['id']; processId: Process['id'] }>({
      query: (body) => ({
        url: `${url}/${body.id}`,
        method: 'DELETE',
        body,
      }),
      invalidatesTags: (_response, _error, { processId }) => [
        { type: TAG_TYPES.STEP, id: `PROCESS-STEP-LIST-${processId}` },
        { type: TAG_TYPES.STEP, id: `PROCESS-STEP-LIST` },
      ],
    }),
  }),
  overrideExisting: false,
});

export const {
  useLazyReadStepListByProcessIdQuery,
  useReadStepListByCellIdQuery,
  useLazyReadStepListByCellIdQuery,
  useLazyReadStepListQuery,
  useReadStepListQuery,
  useCreateStepMutation,
  useRemoveStepMutation,
  useUpdateStepMutation,
  useUpdateProcessStepListMutation,
} = stepApi;
