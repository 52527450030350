import { useCallback, useMemo, useEffect } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { ZodIssueCode } from 'zod';
import LoadingButton from '@mui/lab/LoadingButton';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers-pro';
import { ErrorAlert, IntegerTextField, UserAutocomplete } from '@top-solution/microtecnica-mui';
import { useAuth, useLazySearchPeopleQuery } from '@top-solution/microtecnica-utils';
import AttachmentsField from '../../components/Form/AttachmentField/AttachmentsField';
import FormRow from '../../components/Form/FormRow';
import { GenericAutocomplete } from '../../components/Form/GenericAutocomplete';
import { SaveIcon } from '../../components/Icons';
import { Term, Value } from '../../components/Layout/Description';
import { UserName } from '../../components/User/UserName';
import { Turnback, TurnbackAddForm, TurnbackAddFormSchema } from '../../entities/Turnback';
import { useLazyReadCategoryListByStepIdQuery } from '../../services/categoryApi';
import { useReadCellListByPlantIdQuery } from '../../services/cellApi';
import { useReadCustomerListQuery } from '../../services/customerApi';
import { useReadCellProcessListQuery } from '../../services/processApi';
import { useLazyReadStepListByProcessIdQuery } from '../../services/stepApi';
import { useCreateTurnbackMutation, useUpdateTurnbackMutation } from '../../services/turnbackApi';
import { useReadVendorListQuery } from '../../services/vendorApi';
import { useCell } from '../Cell/CellContext';
import { usePlant } from '../Plant/PlantContext';

type TurnbackDialogProps = DialogProps & {
  initialValues: TurnbackAddForm;
  turnback?: Turnback;
  onClose: () => void;
};

export function TurnbackDialog(props: TurnbackDialogProps): JSX.Element {
  const { initialValues, turnback, onClose, ...dialogProps } = props;
  const { cell } = useCell();
  const { plant } = usePlant();
  const [create, createStatus] = useCreateTurnbackMutation();
  const [update, updateStatus] = useUpdateTurnbackMutation();
  const { data: cellList, isFetching: loadingCells, error: cellsError } = useReadCellListByPlantIdQuery(plant.id);
  const { data: customerList, isFetching: loadingCustomers, error: customersError } = useReadCustomerListQuery();
  const { data: vendorList, isFetching: loadingVendors, error: vendorsError } = useReadVendorListQuery();
  const { data: processList, isFetching: loadingProcess, error: processError } = useReadCellProcessListQuery(cell.id);
  const [getStepList, { data: stepList, error: stepError, isLoading: isLoadingStep }] =
    useLazyReadStepListByProcessIdQuery();
  const [getCategoryList, { data: categoryList, error: categoryError, isLoading: isLoadingCategory }] =
    useLazyReadCategoryListByStepIdQuery();
  const { username } = useAuth();

  const [searchPerson, { data: personList, error: personError, isLoading: isLoadingPerson }] =
    useLazySearchPeopleQuery();

  const form = useForm<TurnbackAddForm>({
    defaultValues: { ...initialValues, reportedBy: initialValues.reportedBy || username || '' },
    resolver: zodResolver(
      TurnbackAddFormSchema.superRefine((data, ctx) => {
        if (data.isRtpr && data.notificationEmailList.length < 1) {
          ctx.addIssue({
            code: ZodIssueCode.custom,
            path: ['notificationEmailList'],
            message: 'Inserire almeno un referente se RCPR abilitato',
          });
        }
      })
    ),
  });
  const { control, watch, setValue } = form;

  const editMode = useMemo(() => Boolean(turnback), [turnback]);

  useEffect(() => {
    if (initialValues.processId) {
      getStepList(initialValues.processId);
    }
    if (initialValues.stepId) {
      getCategoryList(initialValues.stepId);
    }
  }, [getCategoryList, getStepList, initialValues]);

  const onSubmit = useCallback(
    async (data: TurnbackAddForm) => {
      if (editMode && turnback) {
        await update({
          ...turnback,
          ...data,
          id: turnback.id,
          attachmentList: data.attachmentList?.map((a) => a.id),
          partNumber: data.partNumber || undefined,
          createDate: data.createDate.toISOString(),
        }).unwrap();
      } else if (!editMode) {
        await create({
          ...data,
          plantId: plant.id,
          cellId: cell.id,
          attachmentList: data.attachmentList?.map((a) => a.id),
          partNumber: data.partNumber || undefined,
        }).unwrap();
      }
      onClose();
    },
    [editMode, turnback, cell, onClose, update, plant.id, create]
  );

  const responsibleCellId = useWatch({ control, name: 'responsibleCellId' });

  const enableRtpr = useMemo(
    () => cellList?.find((c) => c.id === responsibleCellId)?.isRtpr,
    [cellList, responsibleCellId]
  );

  return (
    <Dialog
      maxWidth="lg"
      fullWidth
      {...dialogProps}
      TransitionProps={{
        ...dialogProps.TransitionProps,
        onExit: (event) => {
          createStatus.reset();
          updateStatus.reset();
          form.reset();
          dialogProps.TransitionProps?.onExit?.(event);
        },
      }}
    >
      <form onSubmit={form.handleSubmit(onSubmit)} noValidate>
        <DialogTitle>{editMode ? 'Modifica Turnback' : 'Nuovo Turnback'}</DialogTitle>
        <DialogContent sx={{ display: 'flex', flexDirection: 'column', '.MuiFormControl-root': { mt: 1 } }}>
          <Box component="dl" sx={{ display: 'flex', flexWrap: 'wrap', gap: 12 }}>
            <Box>
              <Term>ID:</Term>
              <Value>{turnback?.id ?? '-'}</Value>
            </Box>
            <Box>
              <Term>Sito:</Term>
              <Value>{plant.name}</Value>
            </Box>
            <Box>
              <Term>Aperto da:</Term>
              <Value>{username && <UserName username={turnback?.openBy ?? username} />}</Value>
            </Box>
            <Box>
              <Term>Reparto:</Term>
              <Value>{cell.name}</Value>
            </Box>
          </Box>
          <FormRow>
            <Box sx={{ flex: 1 }}>
              <Controller
                control={control}
                name="reportedBy"
                render={({ field: { onChange, ...field }, fieldState: { error } }) => (
                  <UserAutocomplete
                    label="Segnalato da"
                    onChange={(_, value) => onChange(value)}
                    disableClearable
                    error={Boolean(error)}
                    helperText={error?.message ?? ' '}
                    {...field}
                    required
                  />
                )}
              />
            </Box>
            <Box sx={{ flex: 1 }}>
              <Controller
                name="createDate"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <DatePicker
                    label="Aperto il"
                    {...field}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        error: Boolean(error),
                        helperText: error?.message || ' ',
                      },
                    }}
                  />
                )}
              />
            </Box>
            <Controller
              control={control}
              name="responsibleCellId"
              render={({ field: { onChange, ...field }, fieldState: { error } }) => (
                <GenericAutocomplete<false, false, true>
                  data={cellList || []}
                  loading={loadingCells}
                  loadingError={cellsError}
                  label="Reparto Responsabile"
                  onChange={(_, value) => {
                    setValue('isRtpr', false);
                    onChange(value);
                  }}
                  error={Boolean(error)}
                  helperText={error?.message ?? ' '}
                  sx={{ flexGrow: 1 }}
                  {...field}
                />
              )}
            />
          </FormRow>
          {enableRtpr && (
            <FormRow>
              <Controller
                control={control}
                name="isRtpr"
                render={({ field: { value, onChange }, fieldState: { error } }) => (
                  <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', paddingBottom: 2 }}>
                    <FormControl error={Boolean(error)}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={Boolean(value)}
                            onChange={(_e, v) => {
                              onChange(v);
                            }}
                          />
                        }
                        label="Abilita RTPR"
                      />
                    </FormControl>
                  </Box>
                )}
              />
            </FormRow>
          )}
          <FormRow>
            <Controller
              control={control}
              name="processId"
              render={({ field: { onChange, ...field }, fieldState: { error } }) => (
                <GenericAutocomplete<false, false, true>
                  data={processList || []}
                  loading={loadingProcess}
                  loadingError={processError}
                  label="Processo"
                  onChange={(_, value) => {
                    onChange(value);
                    if (value) getStepList(value as number);
                    setValue('stepId', null as unknown as number);
                    setValue('categoryId', null as unknown as number);
                  }}
                  error={Boolean(error)}
                  helperText={error?.message ?? ' '}
                  sx={{ flexGrow: 1 }}
                  {...field}
                  required
                />
              )}
            />
            <Controller
              control={control}
              name="stepId"
              render={({ field: { onChange, ...field }, fieldState: { error } }) => (
                <GenericAutocomplete<false, false, true>
                  data={stepList || []}
                  loading={isLoadingStep}
                  loadingError={stepError}
                  label="Step"
                  onChange={(_, value) => {
                    onChange(value);
                    if (value) getCategoryList(value as number);
                    setValue('categoryId', null as unknown as number);
                  }}
                  error={Boolean(error)}
                  helperText={error?.message ?? ' '}
                  sx={{ flexGrow: 1 }}
                  {...field}
                  required
                  disabled={!watch('processId')}
                />
              )}
            />
            <Controller
              control={control}
              name="categoryId"
              render={({ field: { onChange, ...field }, fieldState: { error } }) => (
                <GenericAutocomplete<false, false, true>
                  data={categoryList || []}
                  loading={isLoadingCategory}
                  loadingError={categoryError}
                  label="Categoria"
                  onChange={(_, value) => onChange(value)}
                  error={Boolean(error)}
                  helperText={error?.message ?? ' '}
                  sx={{ flexGrow: 1 }}
                  {...field}
                  required
                  disabled={!watch('processId') || !watch('stepId')}
                />
              )}
            />
          </FormRow>
          <FormRow>
            <Controller
              control={control}
              name="customerId"
              render={({ field: { onChange, ...field }, fieldState: { error } }) => (
                <GenericAutocomplete<false, false, true>
                  // TODO: this list is a very long one with tons of duplicated items, remove duplicates helps but it's very laggy
                  // we need to improve it with some virtualization https://mui.com/material-ui/react-autocomplete/#virtualization
                  data={customerList?.map((c) => ({ ...c, name: `${c.name} (${c.code})` })) || []}
                  loading={loadingCustomers}
                  loadingError={customersError}
                  label="Customer"
                  onChange={(_, value) => onChange(value)}
                  error={Boolean(error)}
                  helperText={error?.message ?? ' '}
                  sx={{ flexGrow: 1 }}
                  {...field}
                />
              )}
            />
            <Controller
              control={control}
              name="vendorId"
              render={({ field: { onChange, ...field }, fieldState: { error } }) => (
                <GenericAutocomplete<false, false, true>
                  data={vendorList || []}
                  loading={loadingVendors}
                  loadingError={vendorsError}
                  label="Vendor"
                  onChange={(_, value) => onChange(value)}
                  error={Boolean(error)}
                  helperText={error?.message ?? ' '}
                  sx={{ flexGrow: 1 }}
                  {...field}
                />
              )}
            />
          </FormRow>
          <FormRow>
            <Controller
              control={control}
              name="partNumber"
              render={({ field, fieldState: { error } }) => (
                <TextField
                  label="Part number"
                  error={Boolean(error)}
                  helperText={error?.message || ' '}
                  sx={{ flex: 1 }}
                  {...field}
                />
              )}
            />
            <Controller
              control={control}
              name="occurrences"
              render={({ field, fieldState: { error } }) => (
                <IntegerTextField
                  options={{ maximumFractionDigits: 0 }}
                  label="Occorrenze"
                  {...field}
                  error={Boolean(error)}
                  value={field.value ?? ('' as unknown as number)}
                  onChange={(_, value) => field.onChange(value)}
                  helperText={error?.message || 'Numero di ripetizioni del turnback'}
                  required
                />
              )}
            />
          </FormRow>
          <Controller
            control={control}
            name="notificationEmailList"
            render={({ field: { onChange, value, ref, ...field }, fieldState: { error } }) => (
              <Autocomplete
                value={value}
                multiple
                autoComplete
                includeInputInList
                filterSelectedOptions
                loading={isLoadingPerson}
                autoHighlight
                options={[...value, ...(personList?.filter((p) => !!p.email).map((p) => p.email!) || ([] as string[]))]}
                getOptionLabel={(option) => option}
                renderTags={(values: string[], getTagProps) =>
                  values.map((option: string, index: number) => (
                    <Chip variant="filled" color="primary" label={option} {...getTagProps({ index })} key={option} />
                  ))
                }
                onInputChange={(event, newInputValue) => {
                  if (newInputValue.length < 3) return;
                  searchPerson({ needle: newInputValue });
                }}
                disabled={Boolean(personError)}
                onChange={(_, value) => onChange(value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Destinatari Notifiche"
                    placeholder="Aggiungi altre email"
                    error={Boolean(error)}
                    helperText={
                      (Array.isArray(error) ? error.find((item) => Boolean(item))?.message : error?.message) ?? ' '
                    }
                    inputRef={ref}
                  />
                )}
                {...field}
              />
            )}
          />
          <Controller
            control={control}
            name="openingComments"
            render={({ field, fieldState: { error } }) => (
              <TextField
                label="Note apertura"
                error={Boolean(error)}
                helperText={error?.message ?? ' '}
                sx={{ flex: 1 }}
                {...field}
              />
            )}
          />
          <Controller
            name="attachmentList"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <AttachmentsField
                {...field}
                value={field.value ?? []}
                sx={{ flex: 1 }}
                label="Allegati"
                error={Boolean(error)}
                helperText={error?.message ?? ' '}
              />
            )}
          />
          {createStatus.error && <ErrorAlert error={createStatus.error} />}
          {updateStatus.error && <ErrorAlert error={updateStatus.error} />}
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={() => onClose()}>
            Annulla
          </Button>
          <LoadingButton
            type="submit"
            color="primary"
            variant="contained"
            loading={createStatus.isLoading || updateStatus.isLoading}
            loadingPosition="start"
            startIcon={<SaveIcon />}
          >
            Salva
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
}
