import React, { useState } from 'react';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Grid from '@mui/material/Grid';
import ProblemSettingsList from './ProblemSettingsList';
import SupportSettingsList from './SupportSettingsList';
import WorkstationSettingsList from './WorkstationSettingsList';

export default function AndonSettings(): React.ReactElement {
  const [selectedProblemId, setSelectedProblemId] = useState(null as null | number);

  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <WorkstationSettingsList />
      </Grid>
      <Grid item xs={4}>
        <ProblemSettingsList selectedProblemId={selectedProblemId} onSelectedProblemIdChange={setSelectedProblemId} />
        <Alert severity="warning">
          <AlertTitle>I problemi sono condivisi tra celle</AlertTitle>
          Le modifiche a questa lista si ripercuotono su tutte le celle!
        </Alert>
      </Grid>
      <Grid item xs={4}>
        <SupportSettingsList problemId={selectedProblemId} />
      </Grid>
    </Grid>
  );
}
