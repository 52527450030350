import React, { useMemo, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '@top-solution/microtecnica-utils';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { ChevronDownIcon } from '../../../components/Icons';
import { AppContainer } from '../../../components/Layout';
import { usePlant } from '../../Plant/PlantContext';
import { useCell } from '../CellContext';
import { AndonSettings } from './Andon';
import CellDetails from './CellDetails';
import KaizenSettings from './Kaizen/KaizenSettings';
import { TurnbackSettings } from './Turnback';
import { VendorSettings } from './Vendor';

export default function Settings(): React.ReactElement {
  const { isAdmin, username } = useAuth();
  const { cell } = useCell();
  const { plant } = usePlant();
  const [expanded, setExpanded] = useState(0);

  const sections = useMemo(() => {
    const sections = [
      { title: 'Dettagli reparto', content: <CellDetails /> },
      { title: 'Impostazioni Turnback', content: <TurnbackSettings /> },
    ];
    if (isAdmin) {
      sections.push({ title: 'Impostazioni Kaizen', content: <KaizenSettings /> });
    }
    if (cell?.andonEnabled) {
      sections.push({ title: 'Impostazioni Andon', content: <AndonSettings /> });
    }
    sections.push({ title: 'Impostazioni Fornitori', content: <VendorSettings /> });
    return sections;
  }, [cell?.andonEnabled, isAdmin]);

  if (!(isAdmin || (cell?.managerList && cell.managerList.find((manager) => manager === username)))) {
    return <Navigate to={`/plants/${plant?.id}/cells/${cell?.id}`} />;
  }

  return (
    <AppContainer sx={{ py: 4, px: { xs: 0 } }} maxWidth={false}>
      {sections.map(({ title, content }, index) => (
        <Accordion
          key={title}
          expanded={index === expanded}
          onChange={() => setExpanded(index)}
          TransitionProps={{ unmountOnExit: true }}
        >
          <AccordionSummary expandIcon={<ChevronDownIcon />}>
            <strong>{title}</strong>
          </AccordionSummary>
          <AccordionDetails>{content}</AccordionDetails>
        </Accordion>
      ))}
    </AppContainer>
  );
}
