import { FullScreenAndonIcon, PeopleKaizenIcon } from '../components/Icons';
import { AppSection } from '../entities//AppSection';

export const kaizenList: AppSection = {
  url: '/kaizen',
  title: 'Conteggio Kaizen',
  icon: <PeopleKaizenIcon />,
};

export const andonDashboard: AppSection = {
  url: '/andon',
  title: 'Dashboard Andon',
  icon: <FullScreenAndonIcon />,
};
