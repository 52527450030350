import { useMemo } from 'react';
import Chip from '@mui/material/Chip';
import { GridRenderCellParams, GridSingleSelectColDef } from '@mui/x-data-grid-premium';
import { TURNBACK_STATUS } from '../../entities/Turnback';

export function TurnbackStatusChip(props: { status: TURNBACK_STATUS }): React.ReactElement {
  const { status } = props;

  const style = useMemo(() => {
    switch (status) {
      case TURNBACK_STATUS.OPEN:
        return { bgcolor: 'error.main', color: 'error.contrastText' };
      case TURNBACK_STATUS.CLOSED:
        return { bgcolor: 'success.main', color: 'success.contrastText' };
      default:
        return { bgcolor: 'common.white', color: 'common.black', border: 1 };
    }
  }, [status]);

  return (
    <Chip
      label={status}
      sx={{
        textTransform: 'capitalize',
        width: 120,
        ...style,
      }}
    />
  );
}

export function useTurnbackStatusDataGridColumn(): GridSingleSelectColDef {
  const valueOptions = useMemo(() => Object.values(TURNBACK_STATUS).map((s) => ({ value: s, label: s })), []);
  return useMemo(
    () => ({
      field: 'status',
      headerName: 'Stato',
      type: 'singleSelect',
      valueOptions,
      renderCell: ({ value }: GridRenderCellParams) => <TurnbackStatusChip status={value} />,
    }),
    [valueOptions],
  );
}
