import { Plant, createPagedResponseSchema } from '@top-solution/microtecnica-utils';
import { CellSchema, Cell, CellAddForm } from '../entities/Cell';
import { api, TAG_TYPES } from './baseApi';

const url = 'cells';

const cellApi = api.injectEndpoints({
  endpoints: (builder) => ({
    readCellList: builder.query<Cell[], void>({
      query: () => ({ url: `${url}` }),
      transformResponse: (data) => {
        return createPagedResponseSchema(CellSchema)
          .parse(data)
          .data.filter((c) => !c.isDeleted);
      },
      providesTags: () => [{ type: TAG_TYPES.CELL, id: `LIST` }],
      keepUnusedDataFor: 3600,
    }),
    readCellListByPlantId: builder.query<Cell[], Plant['id']>({
      query: (id) => ({ url: `${url}?plantId=eq:${id}` }),
      transformResponse: (data) => {
        return createPagedResponseSchema(CellSchema)
          .parse(data)
          .data.filter((c) => !c.isDeleted);
      },
      providesTags: (_response, _error, id) => [{ type: TAG_TYPES.CELL, id: `LIST-${id}` }],
      keepUnusedDataFor: 3600,
    }),
    readCellById: builder.query<Cell, Cell['id']>({
      query: (id) => ({ url: `${url}/${id}` }),
      transformResponse: (data) => {
        return CellSchema.parse(data);
      },
      providesTags: (_response, _error, id) => [{ type: TAG_TYPES.CELL, id: `CELL-${id}` }],
      keepUnusedDataFor: 3600,
    }),
    createCell: builder.mutation<{ id: string }, CellAddForm>({
      query: (body) => ({
        url,
        method: 'POST',
        body,
      }),
      invalidatesTags: (_response, _error, { plantIdList }) => [
        { type: TAG_TYPES.CELL, id: `LIST` },
        ...plantIdList.map((p) => ({ type: TAG_TYPES.CELL, id: `LIST-${p}` })),
      ],
    }),
    updateCell: builder.mutation<void, CellAddForm & { id: Cell['id'] }>({
      query: (body) => ({
        url: `${url}/${body.id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (_response, _error, { id, plantIdList }) => [
        { type: TAG_TYPES.CELL, id: `LIST` },
        ...plantIdList
          .map((p) => [
            { type: TAG_TYPES.CELL, id: `LIST-${p}` },
            { type: TAG_TYPES.CELL, id: `CELL-${id}` },
          ])
          .flat(),
        TAG_TYPES.TURNBACK,
      ],
    }),
    removeCell: builder.mutation<void, Cell>({
      query: (body) => ({
        url: `${url}/${body.id}`,
        method: 'DELETE',
        body,
      }),
      invalidatesTags: (_response, _error, { id, plantList }) => [
        { type: TAG_TYPES.CELL, id: `LIST` },
        ...plantList
          .map((p) => [
            { type: TAG_TYPES.CELL, id: `LIST-${p}` },
            { type: TAG_TYPES.CELL, id: `CELL-${id}` },
          ])
          .flat(),
      ],
    }),
  }),
  overrideExisting: false,
});

export const {
  useReadCellListByPlantIdQuery,
  useReadCellListQuery,
  useCreateCellMutation,
  useReadCellByIdQuery,
  useRemoveCellMutation,
  useUpdateCellMutation,
} = cellApi;
