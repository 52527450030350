import { PlantSchema } from '@top-solution/microtecnica-utils';
import { z } from 'zod';
import { ProblemSchema } from './Problem';
import { SupportSchema } from './Support';
import { WorkstationSchema } from './Workstation';

export enum ANDON_STATUS {
  OPEN = 'aperto',
  IN_PROGRESS = 'in carico',
  CLOSED = 'chiuso',
}

export const AndonSchema = z.object({
  id: z.number(),
  plant: PlantSchema.pick({ name: true }),
  arrivalTime: z.string().optional(),
  createDate: z.string(),
  assignedTo: z.string().nullish(),
  closingTime: z.string().optional(),
  closedBy: z.string().optional(),
  closingComments: z.string().optional(),
  openBy: z.string(),
  openingComments: z.string().optional(),
  contacts: z.array(SupportSchema),
  status: z.nativeEnum(ANDON_STATUS),
  workstation: WorkstationSchema,
  problemType: ProblemSchema,
  reportedBy: z.string().nonempty(),
});

export const AndonAddFormSchema = AndonSchema.pick({
  reportedBy: true,
  openingComments: true,
}).extend({
  workstationId: z.number(),
  problemTypeId: z.number(),
});

export const AndonStatusFormSchema = AndonSchema.pick({
  status: true,
  closingComments: true,
  openingComments: true,
});

export type Andon = z.infer<typeof AndonSchema>;
export type AndonAddForm = z.infer<typeof AndonAddFormSchema>;
export type AndonStatusForm = z.infer<typeof AndonStatusFormSchema>;
