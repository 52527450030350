import { Cell } from '../entities/Cell';
import { Support, SupportForm, SupportSchema } from '../entities/Support';

import { api, TAG_TYPES } from './baseApi';

const url = 'technical-support-contacts';

const supportApi = api.injectEndpoints({
  endpoints: (builder) => ({
    readSupportListByProblemTypeId: builder.query<Support[], { problemTypeId: number; cellId: Cell['id'] }>({
      query: (body) => ({ url: `${url}?problemTypeId=eq:${body.problemTypeId}&cellId=eq:${body.cellId}` }),
      transformResponse: (data) => {
        return SupportSchema.array().parse(data);
      },
      providesTags: (_response, _error, body) => [
        { type: TAG_TYPES.SUPPORT, id: `CELL-${body.cellId}-PROBLEM-SUPPORT-LIST-${body.problemTypeId}` },
      ],
      keepUnusedDataFor: 3600,
    }),
    createSupport: builder.mutation<{ id: number }, SupportForm & { problemTypeId: number; cellId: Cell['id'] }>({
      query: (body) => ({
        url: `${url}`,
        method: 'POST',
        body: body,
      }),
      invalidatesTags: (_response, _error, body) => [
        { type: TAG_TYPES.SUPPORT, id: `CELL-${body.cellId}-PROBLEM-SUPPORT-LIST-${body.problemTypeId}` },
      ],
    }),
    updateSupport: builder.mutation<
      void,
      SupportForm & { id: Support['id']; problemTypeId: number; cellId: Cell['id'] }
    >({
      query: (body) => ({
        url: `${url}/${body.id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (_response, _error, body) => [
        { type: TAG_TYPES.SUPPORT, id: `CELL-${body.cellId}-PROBLEM-SUPPORT-LIST-${body.problemTypeId}` },
      ],
    }),
    removeSupport: builder.mutation<Support, { id: Support['id']; problemTypeId: number; cellId: Cell['id'] }>({
      query: (body) => ({
        url: `${url}/${body.id}`,
        method: 'DELETE',
        body,
      }),
      invalidatesTags: (_response, _error, body) => [
        { type: TAG_TYPES.SUPPORT, id: `CELL-${body.cellId}-PROBLEM-SUPPORT-LIST-${body.problemTypeId}` },
      ],
    }),
  }),
  overrideExisting: false,
});

export const {
  useReadSupportListByProblemTypeIdQuery,
  useLazyReadSupportListByProblemTypeIdQuery,
  useCreateSupportMutation,
  useRemoveSupportMutation,
  useUpdateSupportMutation,
} = supportApi;
