import { z } from 'zod';
import { ContactSchema } from './Contact';
import { ProblemSchema } from './Problem';

export const SupportSchema = ContactSchema.extend({
  problemType: ProblemSchema.optional().nullish(),
  isDeleted: z.boolean().optional().nullable(),
});

export const SupportFormSchema = SupportSchema.omit({
  id: true,
  level: true,
  problemType: true,
}).extend({
  levelId: z.number(),
});

export type Support = z.infer<typeof SupportSchema>;
export type SupportForm = z.infer<typeof SupportFormSchema>;
