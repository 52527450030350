import { z } from 'zod';
import { Problem, ProblemSchema } from '../entities/Problem';
import { SettingsItemForm } from '../entities/SettingsItem';

import { api, TAG_TYPES } from './baseApi';

const url = 'problem-types';

const problemApi = api.injectEndpoints({
  endpoints: (builder) => ({
    readProblemList: builder.query<Problem[], void>({
      query: () => ({ url }),
      transformResponse: (data) => {
        return z.array(ProblemSchema).parse(data);
      },
      providesTags: () => [{ type: TAG_TYPES.PROBLEM, id: `LIST` }],
      keepUnusedDataFor: 3600,
    }),
    createProblem: builder.mutation<{ id: number }, SettingsItemForm>({
      query: (body) => ({
        url,
        method: 'POST',
        body,
      }),
      invalidatesTags: () => [{ type: TAG_TYPES.PROBLEM, id: `LIST` }],
    }),
    updateProblem: builder.mutation<void, Problem>({
      query: (body) => ({
        url: `${url}/${body.id}`,
        method: 'PUT',
        body: { name: body.name },
      }),
      invalidatesTags: () => [{ type: TAG_TYPES.PROBLEM, id: `LIST` }],
    }),
    removeProblem: builder.mutation<void, Problem>({
      query: (body) => ({
        url: `${url}/${body.id}`,
        method: 'DELETE',
        body,
      }),
      invalidatesTags: () => [{ type: TAG_TYPES.PROBLEM, id: `LIST` }],
    }),
  }),
  overrideExisting: false,
});

export const { useCreateProblemMutation, useReadProblemListQuery, useRemoveProblemMutation, useUpdateProblemMutation } =
  problemApi;
