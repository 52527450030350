import { z } from 'zod';
import { Customer, CustomerSchema } from '../entities/Customer';
import { api, TAG_TYPES } from './baseApi';

const url = 'customers';

const customerApi = api.injectEndpoints({
  endpoints: (builder) => ({
    readCustomerList: builder.query<Customer[], void>({
      query: () => ({ url }),
      transformResponse: (data) => {
        return z.array(CustomerSchema).parse(data);
      },
      providesTags: () => [{ type: TAG_TYPES.CUSTOMER, id: `LIST` }],
      keepUnusedDataFor: 3600,
    }),
  }),
  overrideExisting: false,
});

export const { useReadCustomerListQuery } = customerApi;
