import React from 'react';
import { Link } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/query';
// eslint-disable-next-line import/no-unresolved
import * as changeCase from 'change-case';
import Fab from '@mui/material/Fab';
import Tooltip from '@mui/material/Tooltip';
import { useAuth } from '@top-solution/microtecnica-utils';
import { ChooseGrid, ChooseGridButton } from '../../components/ChooseGrid';
import { FullScreenAndonIcon, PlantKaizenIcon } from '../../components/Icons';
import { Layout } from '../../components/Layout';
import { useReadCellListByPlantIdQuery } from '../../services/cellApi';
import AddCellDialog from './CellAdd/AddCellDialog';
import { usePlant } from './PlantContext';

export default function PlantPage(): React.ReactElement {
  const { plant } = usePlant();
  const { isAdmin } = useAuth();
  const { error, isLoading, data } = useReadCellListByPlantIdQuery(plant?.id ?? skipToken);

  const breadcrumbs = [{ title: plant?.name }];

  return (
    <Layout title="Selezione reparto" error={error} inProgress={isLoading} breadcrumbs={breadcrumbs} maxWidth={false}>
      <ChooseGrid>
        {data?.map((cell) => (
          <ChooseGridButton
            key={cell.id}
            label={cell.name}
            icon={`/icons/${changeCase.snakeCase(cell.name)}.png`}
            to={`/plants/${plant?.id}/cells/${cell.id}`}
            sx={{ fontSize: 'h6.fontSize' }}
          />
        ))}
      </ChooseGrid>
      <Tooltip title="Kaizen Sito" placement="right" arrow>
        <Fab sx={{ position: 'fixed', bottom: 88, left: 16 }} component={Link} to={`/plants/${plant?.id}/kaizen`}>
          <PlantKaizenIcon />
        </Fab>
      </Tooltip>
      <Tooltip title="Dashboard Andon" placement="right" arrow>
        <Fab sx={{ position: 'fixed', bottom: 16, left: 16 }} component={Link} to={`/plants/${plant?.id}/andon`}>
          <FullScreenAndonIcon />
        </Fab>
      </Tooltip>
      {isAdmin && <AddCellDialog />}
    </Layout>
  );
}
