import { useCallback, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { formatDistance } from 'date-fns';
import { it } from 'date-fns/locale';
import LoadingButton from '@mui/lab/LoadingButton';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { DecimalTextField, ErrorAlert, UserAutocomplete } from '@top-solution/microtecnica-mui';
import { useAuth } from '@top-solution/microtecnica-utils';
import FormRow from '../../components/Form/FormRow';
import { GenericAutocomplete } from '../../components/Form/GenericAutocomplete';
import { SaveIcon } from '../../components/Icons';
import { Term, Value } from '../../components/Layout/Description';
import { UserName } from '../../components/User/UserName';
import { Cell } from '../../entities/Cell';
import { Kaizen, KaizenAddForm, KaizenAddFormSchema } from '../../entities/Kaizen';
import { useCreateKaizenMutation, useUpdateKaizenMutation } from '../../services/kaizenApi';
import { useReadKaizenITListQuery } from '../../services/kaizenITApi';
import { useReadKaizenSPQDCListQuery } from '../../services/kaizenSPQDCApi';
import { useReadKaizenTypeListQuery } from '../../services/kaizenTypeApi';
import { formatDateShort } from '../../utils/date';
import { usePlant } from '../Plant/PlantContext';
import { KaizenImageComponent } from './KaizenTagsComponent';

type KaizenDialogProps = DialogProps & {
  initialValues: KaizenAddForm;
  cell?: Cell;
  kaizen?: Kaizen;
  onClose: () => void;
};

export function KaizenDialog(props: KaizenDialogProps): JSX.Element {
  const { initialValues, kaizen, cell, onClose, ...dialogProps } = props;
  const { plant } = usePlant();
  const [create, createStatus] = useCreateKaizenMutation();
  const [update, updateStatus] = useUpdateKaizenMutation();
  const { data: typeList, isFetching: loadingTypes, error: typesError } = useReadKaizenTypeListQuery();
  const { data: iTList, isFetching: loadingIT, error: itError } = useReadKaizenITListQuery();
  const { data: spqdcList, isFetching: loadingSPQDC, error: spqdcError } = useReadKaizenSPQDCListQuery();
  const { username } = useAuth();

  const form = useForm<KaizenAddForm>({
    defaultValues: { ...initialValues, reportedBy: initialValues.reportedBy || username || '' },
    resolver: zodResolver(KaizenAddFormSchema),
  });
  const { control, setValue } = form;

  const editMode = useMemo(() => Boolean(kaizen), [kaizen]);

  const disabledITListValues = useMemo(() => {
    if (iTList) {
      return initialValues.it.filter((v) => !iTList.map((i) => i.name).includes(v));
    }
    return initialValues.it;
  }, [iTList, initialValues.it]);

  const disabledSPQDCListValues = useMemo(() => {
    if (spqdcList) {
      return initialValues.spqdc.filter((v) => !spqdcList.map((i) => i.name).includes(v));
    }
    return initialValues.spqdc;
  }, [spqdcList, initialValues.spqdc]);

  const onSubmit = useCallback(
    async (data: KaizenAddForm) => {
      if (editMode && kaizen) {
        await update({ ...kaizen, ...data, plantId: plant.id }).unwrap();
      } else if (!editMode && cell) {
        await create({ ...data, plantId: plant.id, cellId: cell.id }).unwrap();
      }
      onClose();
    },
    [cell, create, editMode, kaizen, onClose, plant.id, update]
  );

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      {...dialogProps}
      TransitionProps={{
        ...dialogProps.TransitionProps,
        onExit: (event) => {
          createStatus.reset();
          updateStatus.reset();
          form.reset();
          dialogProps.TransitionProps?.onExit?.(event);
        },
      }}
    >
      <form onSubmit={form.handleSubmit(onSubmit)} noValidate>
        <DialogTitle>{editMode ? 'Modifica Kaizen' : 'Nuovo Kaizen'}</DialogTitle>
        <DialogContent sx={{ display: 'flex', flexDirection: 'column', '.MuiFormControl-root': { mt: 1 } }}>
          <Grid container component="dl">
            <Grid item xs={6}>
              <Term>Data:</Term>
              <Value>{formatDateShort(kaizen?.createDate || new Date())}</Value>
            </Grid>
            <Grid item xs={6}>
              <Term>Creato da:</Term>
              <Value>{username && <UserName username={username} />}</Value>
            </Grid>
            <Grid item xs={6}>
              <Term>Reparto:</Term>
              <Value>{kaizen?.cell.name || cell?.name}</Value>
            </Grid>
            {Boolean(kaizen) && kaizen?.reviewer && (
              <>
                <Grid item xs={6}>
                  <Term>Tempo Risposta:</Term>
                  <Value>
                    {formatDistance(
                      new Date(kaizen.createDate || Date.now()),
                      new Date(kaizen.lastUpdate || Date.now()),
                      { locale: it }
                    )}
                  </Value>
                </Grid>
                <Grid item xs={6}>
                  <Term>Ultima Modifica:</Term>
                  <Value>{kaizen?.reviewer}</Value>
                </Grid>
              </>
            )}
          </Grid>
          <Controller
            control={control}
            name="reportedBy"
            render={({ field: { onChange, ...field }, fieldState: { error } }) => (
              <UserAutocomplete
                label="Segnalato da"
                onChange={(_, value) => onChange(value)}
                disableClearable
                error={Boolean(error)}
                helperText={error?.message ?? ' '}
                {...field}
                required
              />
            )}
          />
          <FormRow>
            <Controller
              control={control}
              name="typeId"
              render={({ field: { onChange, value, ...field }, fieldState: { error } }) => (
                <GenericAutocomplete
                  data={typeList || []}
                  loading={loadingTypes}
                  loadingError={typesError}
                  label="Tipologia"
                  onChange={(_, value) => onChange(value)}
                  disableClearable
                  error={Boolean(error)}
                  helperText={error?.message ?? ' '}
                  sx={{ flexGrow: 1 }}
                  value={value}
                  {...field}
                  required
                />
              )}
            />
          </FormRow>
          <FormRow>
            <Controller
              control={control}
              name="opportunity"
              render={({ field, fieldState: { error } }) => (
                <TextField
                  label="Descrizione opportunità miglioramento"
                  error={Boolean(error)}
                  helperText={error?.message || ' '}
                  sx={{ flex: 1 }}
                  required
                  {...field}
                />
              )}
            />
            <Controller
              control={control}
              name="estimatedSavings"
              render={({ field, fieldState: { error } }) => (
                <DecimalTextField
                  options={{ maximumFractionDigits: 0 }}
                  label="Risparmio stimato in €"
                  error={Boolean(error)}
                  helperText={error?.message || ' '}
                  sx={{ flex: 1 }}
                  {...field}
                  value={field.value || null}
                />
              )}
            />
          </FormRow>
          <Controller
            control={control}
            name="suggestion"
            render={({ field, fieldState: { error } }) => (
              <TextField
                label="Descrizione della soluzione proposta"
                error={Boolean(error)}
                helperText={error?.message || ' '}
                sx={{ flex: 1 }}
                required
                {...field}
              />
            )}
          />
          <FormRow sx={{ alignItems: 'center' }}>
            <KaizenImageComponent type={'itImage'} />
            <Controller
              control={control}
              name="it"
              render={({ field: { onChange, value, ref, ...field }, fieldState: { error } }) => (
                <Autocomplete
                  value={value || []}
                  multiple
                  options={[
                    ...disabledITListValues,
                    ...(iTList ? iTList.filter((i) => !i.isDeleted).map((i) => i.name) : []),
                  ]}
                  getOptionDisabled={(o) => disabledITListValues.includes(o)}
                  getOptionLabel={(option) => option}
                  renderTags={(values: string[], getTagProps) =>
                    values.map((option: string, index: number) => (
                      <Chip variant="filled" color="primary" label={option} {...getTagProps({ index })} key={option} />
                    ))
                  }
                  onChange={(_, value) => onChange(value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="IT"
                      error={Boolean(error)}
                      helperText={
                        (Array.isArray(error) ? error.find((item) => Boolean(item))?.message : error?.message) ??
                        (itError || ' ')
                      }
                      inputRef={ref}
                      onBlur={(event) => {
                        if (event.target.value) {
                          setValue('it', [...value, event.target.value]);
                        }
                      }}
                    />
                  )}
                  clearOnBlur
                  loading={loadingIT}
                  sx={{ flex: 1 }}
                  {...field}
                />
              )}
            />
          </FormRow>
          <FormRow sx={{ alignItems: 'center' }}>
            <KaizenImageComponent type={'spqdcImage'} />
            <Controller
              control={control}
              name="spqdc"
              render={({ field: { onChange, value, ref, ...field }, fieldState: { error } }) => (
                <Autocomplete
                  value={value || []}
                  multiple
                  options={[
                    ...disabledSPQDCListValues,
                    ...(spqdcList ? spqdcList.filter((i) => !i.isDeleted).map((i) => i.name) : []),
                  ]}
                  getOptionDisabled={(o) => disabledSPQDCListValues.includes(o)}
                  getOptionLabel={(option) => option}
                  renderTags={(values: string[], getTagProps) =>
                    values.map((option: string, index: number) => (
                      <Chip variant="filled" color="primary" label={option} {...getTagProps({ index })} key={option} />
                    ))
                  }
                  onChange={(_, value) => onChange(value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="SPQDC"
                      error={Boolean(error)}
                      helperText={
                        (Array.isArray(error) ? error.find((item) => Boolean(item))?.message : error?.message) ??
                        (spqdcError || ' ')
                      }
                      inputRef={ref}
                      onBlur={(event) => {
                        if (event.target.value) {
                          setValue('spqdc', [...value, event.target.value]);
                        }
                      }}
                    />
                  )}
                  clearOnBlur
                  loading={loadingSPQDC}
                  sx={{ flex: 1 }}
                  {...field}
                />
              )}
            />
          </FormRow>

          {createStatus.error && <ErrorAlert error={createStatus.error} />}
          {updateStatus.error && <ErrorAlert error={updateStatus.error} />}
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={() => onClose()}>
            Annulla
          </Button>
          <LoadingButton
            type="submit"
            color="primary"
            variant="contained"
            loading={createStatus.isLoading || updateStatus.isLoading}
            loadingPosition="start"
            startIcon={<SaveIcon />}
          >
            Salva
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
}
