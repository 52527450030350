import { SettingsItemForm } from '../entities/SettingsItem';
import { Vendor, VendorSchema } from '../entities/Vendor';
import { api, TAG_TYPES } from './baseApi';

const url = 'vendors';

const vendorApi = api.injectEndpoints({
  endpoints: (builder) => ({
    readVendorList: builder.query<Vendor[], void>({
      query: () => ({ url }),
      transformResponse: (data) => {
        return VendorSchema.array().parse(data);
      },
      providesTags: () => [{ type: TAG_TYPES.VENDOR, id: `LIST` }],
      keepUnusedDataFor: 3600,
    }),
    createVendor: builder.mutation<{ id: number }, SettingsItemForm>({
      query: (body) => ({
        url,
        method: 'POST',
        body: { name: body.name },
      }),
      invalidatesTags: () => [{ type: TAG_TYPES.VENDOR, id: `LIST` }],
    }),
    updateVendor: builder.mutation<void, Vendor>({
      query: (body) => ({
        url: `${url}/${body.id}`,
        method: 'PUT',
        body: { name: body.name },
      }),
      invalidatesTags: () => [{ type: TAG_TYPES.VENDOR, id: `LIST` }],
    }),
    removeVendor: builder.mutation<void, Vendor>({
      query: (body) => ({
        url: `${url}/${body.id}`,
        method: 'DELETE',
        body,
      }),
      invalidatesTags: () => [{ type: TAG_TYPES.VENDOR, id: `LIST` }],
    }),
  }),
  overrideExisting: false,
});

export const { useCreateVendorMutation, useReadVendorListQuery, useRemoveVendorMutation, useUpdateVendorMutation } =
  vendorApi;
