import { useCallback, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { ErrorAlert } from '@top-solution/microtecnica-mui';
import { useAuth } from '@top-solution/microtecnica-utils';
import { format } from 'date-fns';
import { z } from 'zod';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Fab from '@mui/material/Fab';
import FormControlLabel from '@mui/material/FormControlLabel';
import Tooltip from '@mui/material/Tooltip';
import { DateRangePicker } from '@mui/x-date-pickers-pro';
import { GenericAutocomplete } from '../../../../components/Form/GenericAutocomplete';
import { PlantAutocomplete } from '../../../../components/Form/PlantAutocomplete';
import { ExcelFileIcon } from '../../../../components/Icons';
import { useReadCellListQuery } from '../../../../services/cellApi';

export const ExportFormSchema = z.object({
  interval: z.tuple([z.date(), z.date()]),
  plantIdList: z.array(z.number()),
  cellId: z.number().nullish(),
  includeResponsible: z.boolean(),
});

export type ExportForm = z.infer<typeof ExportFormSchema>;

const defaultValues: ExportForm = {
  interval: [null, null],
  plantIdList: [],
  cellId: null,
  includeResponsible: false,
} as unknown as ExportForm;

export function ExportDialog(): JSX.Element {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [downloadInProgress, setDownloadInProgress] = useState(false);
  const { data: cellList, isFetching: loadingCells, error: cellsError } = useReadCellListQuery();
  const [error, setError] = useState<Error | null>(null);
  const { token } = useAuth();
  const form = useForm<ExportForm>({
    defaultValues: defaultValues,
    resolver: zodResolver(ExportFormSchema),
  });

  const onSubmit = useCallback(
    async (data: ExportForm) => {
      if (token) {
        setDownloadInProgress(true);
        setError(null);
        const response = await fetch(
          `/api/reports?${new URLSearchParams({
            ...(data.plantIdList.length ? { plant: `in:${data.plantIdList.toString()}` } : {}),
            startDate: `${format(data.interval[0], 'yyyy-MM-dd')}`,
            endDate: `${format(data.interval[1], 'yyyy-MM-dd')}`,
            ...(data.cellId ? { cell: data.cellId.toString() } : {}),
            ...(data.cellId && data.includeResponsible ? { includeResponsible: 'true' } : {}),
          })}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              Accept: 'application/vnd.ms-excel',
            },
          },
        );
        if (response.status >= 400) {
          setError(new Error(`Error: ${response.status} ${response.statusText}`));
        } else {
          const excelData: Blob = await response.blob();
          const a = document.createElement('a');
          a.href = URL.createObjectURL(excelData);
          a.download = `Report ${format(data.interval[0], 'yyyy-MM-dd')}-${format(
            data.interval[1],
            'yyyy-MM-dd',
          )}.xlsx`;
          a.click();
          setDialogOpen(false);
        }
        setDownloadInProgress(false);
      }
    },
    [token],
  );

  const { control, watch, setValue } = form;

  return (
    <>
      <Tooltip title="Export" placement="right" arrow>
        <Fab sx={{ position: 'fixed', bottom: 16, right: 16 }} onClick={() => setDialogOpen(true)}>
          <ExcelFileIcon />
        </Fab>
      </Tooltip>
      <Dialog
        open={dialogOpen}
        maxWidth="sm"
        fullWidth
        TransitionProps={{
          onExit: () => {
            form.reset();
          },
        }}
      >
        <form onSubmit={form.handleSubmit(onSubmit)} noValidate>
          <DialogTitle>Export Report</DialogTitle>
          <DialogContent sx={{ display: 'flex', flexDirection: 'column', '.MuiFormControl-root': { mt: 1 } }}>
            <Controller
              name="interval"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <DateRangePicker
                  localeText={{ start: 'Inzio', end: 'Fine' }}
                  disableFuture
                  {...field}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      error: Boolean(error),
                      helperText: error?.message ?? ' ',
                    },
                  }}
                />
              )}
            />
            <Controller
              control={control}
              name="plantIdList"
              render={({ field: { onChange, ...field }, fieldState: { error } }) => (
                <PlantAutocomplete
                  label="Stabilimento"
                  multiple
                  onChange={(_, value) => onChange(value)}
                  disableClearable
                  error={Boolean(error)}
                  helperText={error?.message ?? ' '}
                  {...field}
                />
              )}
            />
            <Controller
              control={control}
              name="cellId"
              render={({ field: { onChange, value, ...field }, fieldState: { error } }) => (
                <GenericAutocomplete<false, false, true>
                  data={cellList?.map((c) => ({ id: c.id, name: `${c.name} (${c.id})` })) || []}
                  loading={loadingCells}
                  loadingError={cellsError}
                  label="Cella"
                  onChange={(_, value) => {
                    setValue('includeResponsible', false);
                    onChange(value);
                  }}
                  error={Boolean(error)}
                  helperText={error?.message ?? 'Seleziona una cella per filtrarne i risultati'}
                  sx={{ flexGrow: 1 }}
                  value={value || null}
                  {...field}
                />
              )}
            />
            <Controller
              control={control}
              name="includeResponsible"
              render={({ field: { value, ...field } }) => (
                <FormControlLabel
                  control={
                    <Checkbox checked={value || false} value={value || false} {...field} disabled={!watch('cellId')} />
                  }
                  label="Includi Turnback con cella selezionata come responsabile"
                />
              )}
            />
            {error && <ErrorAlert error={error} />}
          </DialogContent>
          <DialogActions>
            <Button
              color="secondary"
              onClick={() => {
                setDialogOpen(false);
                setError(null);
              }}
            >
              Annulla
            </Button>
            <LoadingButton
              type="submit"
              color="primary"
              variant="contained"
              loading={downloadInProgress}
              loadingPosition="start"
              startIcon={<ExcelFileIcon />}
            >
              Scarica
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
