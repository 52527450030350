import { useCallback, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { ErrorAlert } from '@top-solution/microtecnica-mui';
import { ZodIssueCode } from 'zod';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { SaveIcon } from '../../components/Icons';
import { Kaizen, KaizenStatusFormSchema, KaizenStatusForm } from '../../entities/Kaizen';
import { useReadKaizenStatusListQuery, useUpdateKaizenStatusMutation } from '../../services/kaizenApi';
import { KaizenDetail } from './KaizenDetail';

type KaizenStatusDialogProps = DialogProps & {
  kaizen: Kaizen;
  onClose: () => void;
};

export function KaizenStatusDialog(props: KaizenStatusDialogProps): JSX.Element {
  const { kaizen, onClose, ...dialogProps } = props;
  const [update, updateStatus] = useUpdateKaizenStatusMutation();
  const { data: statusList } = useReadKaizenStatusListQuery();

  const KaizenStatusFormSchemaWithRefine = useMemo(
    () =>
      KaizenStatusFormSchema.superRefine((data, ctx) => {
        if (statusList?.find((s) => s.id === data.statusId)?.needsComment && (data.checkedComment?.length ?? 0) < 5) {
          ctx.addIssue({
            code: ZodIssueCode.too_small,
            minimum: 5,
            inclusive: true,
            type: 'string',
            path: ['checkedComment'],
            message: 'Il commento è obbligatorio per questo stato e deve essere più lungo di 5 caratteri',
          });
        }
      }),
    [statusList],
  );

  const form = useForm<KaizenStatusForm>({
    defaultValues: { checkedComment: kaizen.checkedComment || '', statusId: kaizen.status.id },
    resolver: zodResolver(KaizenStatusFormSchemaWithRefine),
  });
  const { control } = form;

  const onSubmit = useCallback(
    async (data: KaizenStatusForm) => {
      await update({ ...data, id: kaizen.id }).unwrap();

      onClose();
    },
    [kaizen, onClose, update],
  );

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      {...dialogProps}
      TransitionProps={{
        ...dialogProps.TransitionProps,
        onExit: (event) => {
          updateStatus.reset();
          form.reset();
          dialogProps.TransitionProps?.onExit?.(event);
        },
      }}
    >
      <form onSubmit={form.handleSubmit(onSubmit)} noValidate>
        <DialogTitle>Aggiorna Stato</DialogTitle>
        <DialogContent sx={{ display: 'flex', flexDirection: 'column', '.MuiFormControl-root': { mt: 1 } }}>
          <KaizenDetail kaizen={kaizen} />
          <Controller
            control={control}
            name="statusId"
            render={({ field: { onChange, ref, ...field }, fieldState: { error } }) => (
              <TextField
                label="Stato"
                onChange={(e) => onChange(e.target.value)}
                select
                SelectProps={{ ref }}
                error={Boolean(error)}
                helperText={error?.message || ' '}
                {...field}
              >
                {statusList?.map(({ id, name }) => (
                  <MenuItem key={id} value={id}>
                    {name}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
          <Controller
            control={control}
            name="checkedComment"
            render={({ field, fieldState: { error } }) => (
              <TextField
                label="Commento"
                error={Boolean(error)}
                helperText={error?.message || ' '}
                sx={{ flex: 1 }}
                {...field}
              />
            )}
          />
          {updateStatus.error && <ErrorAlert error={updateStatus.error} />}
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={() => onClose()}>
            Annulla
          </Button>
          <LoadingButton
            type="submit"
            color="primary"
            variant="contained"
            loading={updateStatus.isLoading}
            loadingPosition="start"
            startIcon={<SaveIcon />}
          >
            Salva
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
}
