import { formatDistance } from 'date-fns';
import { it } from 'date-fns/locale/it';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import { Term, Value } from '../../components/Layout/Description';
import { UserName } from '../../components/User/UserName';
import { Kaizen } from '../../entities/Kaizen';
import { euro } from '../../utils/currency';
import { formatDateShort } from '../../utils/date';
import { KaizenStatusChip } from './useKaizenStatusDataGridColumn';

type KaizenDetailDialogProps = DialogProps & {
  kaizen: Kaizen;
  onClose: (data: Kaizen | null) => void;
};

export function KaizenDetail(props: { kaizen: Kaizen; showMore?: boolean }): JSX.Element {
  const { kaizen, showMore } = props;

  const moreDetails = (
    <>
      <Grid item xs={6}>
        <Term>Seganalto da:</Term>
        <Value>{kaizen.reportedBy}</Value>
      </Grid>
      <Grid item xs={6}>
        <Term>Tipologia:</Term>
        <Value>{kaizen.type.name}</Value>
      </Grid>
      <Grid item xs={6}>
        <Term>Risparmio stimato:</Term>
        <Value>{kaizen.estimatedSavings ? euro.format(kaizen.estimatedSavings) : '-'}</Value>
      </Grid>
      <Grid item xs={12}>
        <Term>Opportunità miglioramento:</Term>
        <Value>{kaizen.opportunity}</Value>
      </Grid>
      <Grid item xs={12}>
        <Term>Soluzione proposta:</Term>
        <Value>{kaizen.suggestion}</Value>
      </Grid>
      <Grid item xs={12}>
        <Term>IT:</Term>
        <Value>{kaizen.it.length > 0 ? kaizen.it.map((i) => <Chip label={i} key={i} />) : '-'}</Value>
      </Grid>
      <Grid item xs={12}>
        <Term>SPQDC:</Term>
        <Value>{kaizen.spqdc.length > 0 ? kaizen.spqdc.map((i) => <Chip label={i} key={i} />) : '-'}</Value>
      </Grid>
    </>
  );

  return (
    <Grid container component="dl">
      {showMore && (
        <Grid item xs={12}>
          <Term>Stato:</Term>
          <Value>
            <KaizenStatusChip status={kaizen.status.id} />
          </Value>
        </Grid>
      )}
      <Grid item xs={6}>
        <Term>Data:</Term>
        <Value>{formatDateShort(kaizen.createDate)}</Value>
      </Grid>
      <Grid item xs={6}>
        <Term>Creato da:</Term>
        <Value>
          <UserName username={kaizen.openBy} />
        </Value>
      </Grid>
      <Grid item xs={6}>
        <Term>Reparto:</Term>
        <Value>{kaizen.cell.name}</Value>
      </Grid>
      {kaizen.reviewer && (
        <>
          <Grid item xs={6}>
            <Term>Tempo Risposta:</Term>
            <Value>
              {formatDistance(new Date(kaizen.createDate || Date.now()), new Date(kaizen.lastUpdate || Date.now()), {
                locale: it,
              })}
            </Value>
          </Grid>
          <Grid item xs={6}>
            <Term>Ultima Modifica:</Term>
            <Value>{kaizen?.reviewer}</Value>
          </Grid>
          {kaizen.checkedComment && showMore && (
            <Grid item xs={12}>
              <Term>Commento:</Term>
              <Value>{kaizen.checkedComment}</Value>
            </Grid>
          )}
        </>
      )}
      {showMore && moreDetails}
    </Grid>
  );
}

export function KaizenDetailDialog(props: KaizenDetailDialogProps): JSX.Element {
  const { kaizen, onClose, ...dialogProps } = props;

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      {...dialogProps}
      TransitionProps={{
        ...dialogProps.TransitionProps,
        onExit: (event) => {
          dialogProps.TransitionProps?.onExit?.(event);
        },
      }}
    >
      <DialogTitle>Dettaglio Kaizen</DialogTitle>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column' }}>
        <KaizenDetail kaizen={kaizen} showMore />
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="contained" onClick={() => onClose(null)}>
          Chiudi
        </Button>
      </DialogActions>
    </Dialog>
  );
}
