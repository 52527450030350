import React, { useMemo } from 'react';
import Alert from '@mui/material/Alert';
import Box, { BoxProps } from '@mui/material/Box';
import { ANDON_STATUS, Andon } from '../../entities/Andon';
import useTime from './useTime';

type TechnicalSupportProps = BoxProps & {
  andon: Andon;
};

export function TechnicalSupport(props: TechnicalSupportProps): React.ReactElement {
  const { andon, sx, ...boxProps } = props;
  const time = useTime();
  const techSupport = useMemo(() => {
    if (andon.createDate && andon.contacts) {
      const delta = time.getTime() - new Date(andon.createDate).getTime();
      const contacts = andon.contacts
        ?.filter((contact) => delta > contact.level.time * 60000)
        .sort((a, b) => b.level.id - a.level.id);
      const currentLevel = contacts.reduce(
        (currentLevel, { level }) => (level.id > currentLevel ? level.id : currentLevel),
        0,
      );
      return { contacts, currentLevel };
    }
  }, [andon, time]);

  if (andon.status === ANDON_STATUS.IN_PROGRESS) {
    return (
      <Box sx={sx} {...boxProps}>
        Supporto arrivato
      </Box>
    );
  }

  if (andon.status === ANDON_STATUS.CLOSED) {
    return (
      <Box sx={sx} {...boxProps}>
        Problema risolto
      </Box>
    );
  }

  if (!andon.contacts?.length) {
    return (
      <Alert severity="warning" sx={{ py: 0, px: 0.5, ...sx }}>
        Nessun tecnico specificato
      </Alert>
    );
  }

  return (
    <Box sx={{ '& > div': { whiteSpace: 'nowrap' }, ...sx }} {...boxProps}>
      {techSupport?.contacts?.map((contact) => (
        <Box key={contact.id} sx={{ opacity: contact.level.id === techSupport.currentLevel ? 1 : 0.6 }}>
          <strong>{contact.telephone}</strong> {contact.name} {contact.lastname}
        </Box>
      ))}
    </Box>
  );
}
