import { KaizenType, KaizenTypeSchema } from '../entities/Kaizen';
import { SettingsItemForm } from '../entities/SettingsItem';
import { api, TAG_TYPES } from './baseApi';

const url = 'kaizen-types';

const kaizenTypeApi = api.injectEndpoints({
  endpoints: (builder) => ({
    readKaizenTypeList: builder.query<KaizenType[], void>({
      query: () => ({ url }),
      transformResponse: (data) => {
        return KaizenTypeSchema.array().parse(data);
      },
      providesTags: () => [{ type: TAG_TYPES.KAIZEN_TYPE, id: `LIST` }],
      keepUnusedDataFor: 3600,
    }),
    createKaizenType: builder.mutation<{ id: number }, SettingsItemForm>({
      query: (body) => ({
        url,
        method: 'POST',
        body: { name: body.name },
      }),
      invalidatesTags: () => [{ type: TAG_TYPES.KAIZEN_TYPE, id: `LIST` }],
    }),
    updateKaizenType: builder.mutation<void, KaizenType>({
      query: (body) => ({
        url: `${url}/${body.id}`,
        method: 'PUT',
        body: { name: body.name },
      }),
      invalidatesTags: () => [{ type: TAG_TYPES.KAIZEN_TYPE, id: `LIST` }],
    }),
    removeKaizenType: builder.mutation<void, KaizenType>({
      query: (body) => ({
        url: `${url}/${body.id}`,
        method: 'DELETE',
        body,
      }),
      invalidatesTags: () => [{ type: TAG_TYPES.KAIZEN_TYPE, id: `LIST` }],
    }),
  }),
  overrideExisting: false,
});

export const {
  useCreateKaizenTypeMutation,
  useReadKaizenTypeListQuery,
  useRemoveKaizenTypeMutation,
  useUpdateKaizenTypeMutation,
} = kaizenTypeApi;
