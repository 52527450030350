import React, { useMemo, useState, useCallback } from 'react';
import { DataGridWrapper } from '@top-solution/microtecnica-mui';
import { useAuth } from '@top-solution/microtecnica-utils';
import Button from '@mui/material/Button';
import { GridActionsCellItem, GridColDef, GridRowParams, MuiEvent } from '@mui/x-data-grid-premium';
import { DeleteIcon, DetailsIcon, StateMachineIcon } from '../../components/Icons';
import { Andon } from '../../entities/Andon';
import { useCell } from '../Cell/CellContext';
import { AndonAddButton } from './AndonAddButton';
import { AndonDeleteConfirm } from './AndonDeleteConfirm';
import { AndonDetailDialog } from './AndonDetail';
import AndonListGrid from './AndonListGrid';
import { AndonStatusDialog } from './AndonStatusDialog';
import { useColumns } from './columns';

export default function AndonListByCell(): React.ReactElement {
  const { username } = useAuth();
  const { cell } = useCell();

  const andonColumns = useColumns();
  const [andonToRemove, setAndonToRemove] = useState<Andon | null>(null);
  const [andonToUpdateStatus, setAndonToUpdateStatus] = useState<Andon | null>(null);
  const [andonToView, setAndonToView] = useState<Andon | null>(null);

  const isManager = useMemo(
    () => Boolean(cell.managerList?.find((responsible) => responsible === username)),
    [cell, username],
  );

  const handleDetailClick = useCallback(
    ({ row }: GridRowParams<Andon>) =>
      (event: MuiEvent<React.MouseEvent>) => {
        event.stopPropagation();
        setAndonToView(row);
      },
    [],
  );

  const handleStatusUpdateClick = useCallback(
    ({ row }: GridRowParams<Andon>) =>
      (event: MuiEvent<React.MouseEvent>) => {
        event.stopPropagation();
        setAndonToUpdateStatus(row);
      },
    [],
  );

  const handleDeleteClick = useCallback(
    ({ row }: GridRowParams<Andon>) =>
      (event: MuiEvent<React.MouseEvent>) => {
        event.stopPropagation();
        setAndonToRemove(row);
      },
    [],
  );

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        headerName: 'ID',
        field: 'id',
        width: 60,
        type: 'number',
        renderCell: ({ row }) => (
          <Button size="small" color="primary" onClick={() => setAndonToView(row)}>
            {row.id}
          </Button>
        ),
      },
      ...andonColumns,
      {
        field: 'actions',
        type: 'actions',
        width: 120,
        renderHeader: () => <AndonAddButton />,
        getActions: (params: GridRowParams<Andon>) => [
          <GridActionsCellItem
            icon={<DetailsIcon />}
            key="view"
            label="Dettaglio"
            onClick={handleDetailClick(params)}
          />,
          <GridActionsCellItem
            icon={<StateMachineIcon />}
            key="edit-status"
            label="Modifica Status"
            onClick={handleStatusUpdateClick(params)}
            disabled={params.row.openBy !== username && !isManager}
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            key="delete"
            label="Elimina"
            onClick={handleDeleteClick(params)}
            disabled={params.row.openBy !== username && !isManager}
          />,
        ],
      },
    ],
    [andonColumns, handleDetailClick, handleStatusUpdateClick, handleDeleteClick, username, isManager],
  );

  return (
    <>
      <DataGridWrapper>
        <AndonListGrid
          columns={columns}
          sessionStorageId={`AndonListByCellId-${cell.id}`}
          cell={cell}
          density="comfortable"
        />
      </DataGridWrapper>
      {andonToUpdateStatus && (
        <AndonStatusDialog
          open={Boolean(andonToUpdateStatus)}
          andon={andonToUpdateStatus}
          onClose={() => setAndonToUpdateStatus(null)}
        />
      )}
      {andonToRemove && (
        <AndonDeleteConfirm open={Boolean(andonToRemove)} data={andonToRemove} onClose={() => setAndonToRemove(null)} />
      )}
      {andonToView && (
        <AndonDetailDialog open={Boolean(andonToView)} andon={andonToView} onClose={() => setAndonToView(null)} />
      )}
    </>
  );
}
