import { SettingsItem, SettingsItemForm, SettingsItemSchema } from '../entities/SettingsItem';
import { api, TAG_TYPES } from './baseApi';

const url = 'kaizen-it';

const kaizenITApi = api.injectEndpoints({
  endpoints: (builder) => ({
    readKaizenITList: builder.query<SettingsItem[], void>({
      query: () => ({ url }),
      transformResponse: (data) => {
        return SettingsItemSchema.array().parse(data);
      },
      providesTags: () => [{ type: TAG_TYPES.KAIZEN_IT, id: `LIST` }],
      keepUnusedDataFor: 3600,
    }),
    createKaizenIT: builder.mutation<{ id: number }, SettingsItemForm>({
      query: (body) => ({
        url,
        method: 'POST',
        body: { name: body.name },
      }),
      invalidatesTags: () => [{ type: TAG_TYPES.KAIZEN_IT, id: `LIST` }],
    }),
    updateKaizenIT: builder.mutation<void, SettingsItem>({
      query: (body) => ({
        url: `${url}/${body.id}`,
        method: 'PUT',
        body: { name: body.name },
      }),
      invalidatesTags: () => [{ type: TAG_TYPES.KAIZEN_IT, id: `LIST` }],
    }),
    removeKaizenIT: builder.mutation<void, SettingsItem>({
      query: (body) => ({
        url: `${url}/${body.id}`,
        method: 'DELETE',
        body,
      }),
      invalidatesTags: () => [{ type: TAG_TYPES.KAIZEN_IT, id: `LIST` }],
    }),
  }),
  overrideExisting: false,
});

export const {
  useCreateKaizenITMutation,
  useReadKaizenITListQuery,
  useRemoveKaizenITMutation,
  useUpdateKaizenITMutation,
} = kaizenITApi;
