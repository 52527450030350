import { Controller, UseFormReturn } from 'react-hook-form';
import { UserAutocomplete } from '@top-solution/microtecnica-mui';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import { PlantAutocomplete } from '../../../components/Form/PlantAutocomplete';
import { CellAddForm } from '../../../entities/Cell';

export interface CellAddFormProps {
  form: UseFormReturn<CellAddForm>;
}

export function CellForm(props: CellAddFormProps): JSX.Element {
  const { form } = props;

  const { control } = form;

  return (
    <>
      <Controller
        control={control}
        name="name"
        render={({ field, fieldState: { error } }) => (
          <TextField
            label="Nome"
            error={Boolean(error)}
            helperText={error?.message ?? ' '}
            {...field}
            required
            fullWidth
          />
        )}
      />
      <Controller
        control={control}
        name="plantIdList"
        render={({ field: { onChange, ...field }, fieldState: { error } }) => (
          <PlantAutocomplete
            label="Stabilimento"
            multiple
            onChange={(_, value) => onChange(value)}
            disableClearable
            error={Boolean(error)}
            helperText={error?.message ?? ' '}
            {...field}
            required
          />
        )}
      />
      <Controller
        control={control}
        name="managerList"
        render={({ field: { onChange, ...field }, fieldState: { error } }) => (
          <UserAutocomplete
            label="Responsabili Cella"
            multiple
            onChange={(_, value) => onChange(value)}
            disableClearable
            error={Boolean(error)}
            helperText={error?.message ?? ' '}
            {...field}
            required
          />
        )}
      />
      <Controller
        control={control}
        name="kaizensAdminList"
        render={({ field: { onChange, ...field }, fieldState: { error } }) => (
          <UserAutocomplete
            label="Responsabili Kaizen"
            multiple
            onChange={(_, value) => onChange(value)}
            disableClearable
            error={Boolean(error)}
            helperText={error?.message ?? ' '}
            {...field}
          />
        )}
      />
      <Controller
        control={control}
        name="andonEnabled"
        render={({ field: { value, ...field } }) => (
          <FormControlLabel
            control={<Checkbox checked={value || false} value={value || false} {...field} />}
            label="Abilita ANDON"
          />
        )}
      />
      <Controller
        control={control}
        name="isRtpr"
        render={({ field: { value, ...field } }) => (
          <FormControlLabel
            control={<Checkbox checked={value || false} value={value || false} {...field} />}
            label="Abilita RTPR"
          />
        )}
      />
    </>
  );
}
