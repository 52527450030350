import React, { useCallback, useMemo, useState } from 'react';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import { CancelIcon, SearchIcon } from '../../../../components/Icons';
import { SettingsItemForm } from '../../../../entities/SettingsItem';
import { Vendor } from '../../../../entities/Vendor';
import {
  useCreateVendorMutation,
  useReadVendorListQuery,
  useRemoveVendorMutation,
  useUpdateVendorMutation,
} from '../../../../services/vendorApi';
import { ItemAddDialog, ItemEditDialog } from '../ItemDialog';
import SettingsList from '../SettingsList';

export default function VendorSettings(): React.ReactElement {
  const [needle, setNeedle] = useState('');
  const [selectedVendorId, setSelectedVendorId] = useState<null | number>(null);

  const { data, isLoading, error } = useReadVendorListQuery();
  const [remove, removeState] = useRemoveVendorMutation();
  const [create, createState] = useCreateVendorMutation();
  const [update, updateState] = useUpdateVendorMutation();

  const results = useMemo(() => {
    if (data && needle.length > 1) {
      return (
        data?.filter(
          ({ name, isDeleted }) => name.toLocaleLowerCase().indexOf(needle.toLocaleLowerCase()) > -1 && !isDeleted,
        ) || []
      );
    }
    return data;
  }, [needle, data]);

  const selectedVendor = useMemo(() => data?.find(({ id }) => id === selectedVendorId), [data, selectedVendorId]);

  const handleRemove = useCallback(
    async (vendor: Vendor) => {
      return remove(vendor);
    },
    [remove],
  );

  const handleUpdate = useCallback(
    async (vendor: Vendor) => {
      return update(vendor);
    },
    [update],
  );

  const handleCreate = useCallback(
    async (values: SettingsItemForm) => {
      return create({ ...values });
    },
    [create],
  );

  return (
    <>
      <Grid container spacing={1}>
        <Grid item sm={6}>
          {data && data.length > 10 && (
            <TextField
              placeholder="Cerca fornitore…"
              value={needle}
              onChange={(event) => setNeedle(event.target.value)}
              fullWidth
              type="text"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                endAdornment: needle && (
                  <IconButton aria-label="clear" onClick={() => setNeedle('')}>
                    <CancelIcon />
                  </IconButton>
                ),
              }}
            />
          )}
        </Grid>
        <Grid item sm={6}>
          <Alert severity="warning">
            <AlertTitle>I fornitori sono condivisi tra celle</AlertTitle>
            Le modifiche a questa lista si ripercuotono su tutte le celle!
          </Alert>
        </Grid>
      </Grid>
      <Box>
        <SettingsList
          title="Fornitori"
          items={results}
          selectedItem={selectedVendor}
          onSelectedItemChange={({ id }) => setSelectedVendorId(id)}
          readListRequest={{ isLoading, error }}
          itemRemove={handleRemove}
          itemRemoveRequest={removeState}
          height={500}
          addProps={{
            AddDialog: ItemAddDialog,
            submit: handleCreate,
            request: createState,
          }}
          editPros={{
            EditDialog: ItemEditDialog,
            submit: handleUpdate,
            request: updateState,
          }}
        />
      </Box>
    </>
  );
}
