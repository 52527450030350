import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from '../store';

export const TAG_TYPES = {
  KAIZEN: 'Kaizen',
  ANDON: 'Andon',
  CELL: 'Cell',
  PROBLEM: 'Problem',
  VENDOR: 'Vendor',
  TURNBACK: 'Turnback',
  CUSTOMER: 'Customer',
  PROCESS: 'Process',
  STEP: 'Step',
  CATEGORY: 'Category',
  WORKSTATION: 'Workstation',
  SUPPORT: 'Support',
  KAIZEN_TYPE: 'KaizenType',
  KAIZEN_IT: 'KaizenIT',
  KAIZEN_SPQDC: 'KaizenSPQDC',
};

export const api = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: (import.meta.env.VITE_API_BASE_URL as string) || '/api/',
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.token;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: Object.values(TAG_TYPES),
  endpoints: () => ({}),
});
