import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { ErrorAlert } from '@top-solution/microtecnica-mui';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Fab from '@mui/material/Fab';
import Tooltip from '@mui/material/Tooltip';
import { AddIcon, SaveIcon } from '../../../components/Icons';
import { CellAddForm, CellAddFormSchema } from '../../../entities/Cell';
import { useCreateCellMutation } from '../../../services/cellApi';
import { usePlant } from '../PlantContext';
import { CellForm } from './CellForm';

const defaultValues: CellAddForm = {
  name: '',
  andonEnabled: false,
  isRtpr: false,
  plantIdList: [],
  managerList: [],
  kaizensAdminList: [],
};

export default function AddCellDialog(): React.ReactElement {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [create, createStatus] = useCreateCellMutation();
  const { plant } = usePlant();

  const form = useForm<CellAddForm>({
    defaultValues: defaultValues,
    resolver: zodResolver(CellAddFormSchema),
  });

  const onSubmit = useCallback(
    async (data: CellAddForm) => {
      const createdCell = await create(data).unwrap();
      setOpen(false);
      navigate(`/plants/${plant?.id}/cells/${createdCell.id}/settings`);
    },
    [create, navigate, plant?.id],
  );

  return (
    <>
      <Tooltip title="Aggiungi reparto" placement="left" arrow>
        <Fab color="primary" sx={{ position: 'fixed', bottom: 16, right: 16 }} onClick={() => setOpen(true)}>
          <AddIcon />
        </Fab>
      </Tooltip>
      {open && (
        <Dialog
          open={open}
          maxWidth="sm"
          fullWidth
          TransitionProps={{
            onExit: () => {
              createStatus.reset();
              form.reset();
            },
          }}
        >
          <form onSubmit={form.handleSubmit(onSubmit)} noValidate>
            <DialogTitle>Nuovo reparto</DialogTitle>
            <DialogContent
              dividers
              sx={{ display: 'flex', flexDirection: 'column', '.MuiFormControl-root': { mt: 1 } }}
            >
              <CellForm form={form} />
              {createStatus.error && <ErrorAlert error={createStatus.error} />}
            </DialogContent>
            <DialogActions>
              <Button
                color="secondary"
                onClick={() => {
                  createStatus.reset();
                  form.reset();
                  setOpen(false);
                }}
              >
                Annulla
              </Button>
              <LoadingButton
                type="submit"
                color="primary"
                variant="contained"
                loading={createStatus.isLoading}
                loadingPosition="start"
                startIcon={<SaveIcon />}
              >
                Salva
              </LoadingButton>
            </DialogActions>
          </form>
        </Dialog>
      )}
    </>
  );
}
