import { PanelResizeHandle } from 'react-resizable-panels';

import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { SplitVerticalIcon } from '../../../components/Icons';

export const ResizeHandle = () => {
  const theme = useTheme();

  return (
    <PanelResizeHandle
      style={{
        background: theme.palette.background.paper,
        // '&[data-resize-handle-active]': {
        //   background: theme.palette.primary.main,
        // },
        transition: 'all 0.2s linear',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          width: 16,
          flexDirection: 'column',
        }}
      >
        <SplitVerticalIcon color="primary" />
      </Box>
    </PanelResizeHandle>
  );
};
