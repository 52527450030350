import { Route, Routes, useNavigate } from 'react-router-dom';
import { AuthCallback } from '@top-solution/microtecnica-utils';
import { Home, NotFoundPage, UnauthorizedPage } from './pages';
import { AndonDashboard } from './pages/Andon';
import { KaizenListByPerson } from './pages/Kaizen';
import { PlantRouter } from './pages/Plant';

export function App(): JSX.Element {
  const navigate = useNavigate();

  return (
    <Routes>
      <Route index element={<Home />} />
      <Route
        path="auth"
        element={
          <AuthCallback
            onAuthenticationSuccess={navigate}
            ErrorComponent={(props) => <UnauthorizedPage description={props.error} />}
          />
        }
      />
      <Route path="kaizen" element={<KaizenListByPerson />} />
      <Route path="andon" element={<AndonDashboard />} />
      <Route path="plants/*" element={<PlantRouter />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}
