import React, { useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { ErrorAlert } from '@top-solution/microtecnica-mui';
import { LoadingButton } from '@mui/lab';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import AttachmentsField from '../../../../components/Form/AttachmentField/AttachmentsField';
import { SaveIcon } from '../../../../components/Icons';
import { KaizenSettingsForm, KaizenSettingsFormSchema } from '../../../../entities/Kaizen';
import { useUpdateKaizenSettingsMutation } from '../../../../services/kaizenApi';

export default function ImageUploadSection(props: { defaultValues: KaizenSettingsForm }): React.ReactElement {
  const { defaultValues } = props;
  const form = useForm<KaizenSettingsForm>({
    defaultValues,
    resolver: zodResolver(KaizenSettingsFormSchema),
  });
  const theme = useTheme();

  const [update, updateStatus] = useUpdateKaizenSettingsMutation();

  const onSubmit = useCallback(
    async (data: KaizenSettingsForm) => {
      await update({
        ...(data.itImage.length ? { itImageId: data.itImage[0].id } : {}),
        ...(data.spqdcImage.length ? { spqdcImageId: data.spqdcImage[0].id } : {}),
      }).unwrap();
    },
    [update],
  );

  const { control } = form;
  return (
    <form onSubmit={form.handleSubmit(onSubmit)} noValidate>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="body1" sx={{ fontWeight: 500 }}>
            Upload Immagini
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="itImage"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <AttachmentsField
                {...field}
                value={field.value ?? []}
                sx={{ flex: 1 }}
                label={'Immagine It'}
                error={Boolean(error)}
                helperText={error?.message ?? ' '}
                maxAttachmentsCount={1}
                acceptedFormats="image/*"
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="spqdcImage"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <AttachmentsField
                {...field}
                value={field.value ?? []}
                sx={{ flex: 1 }}
                label={'Immagine Spqdc'}
                error={Boolean(error)}
                helperText={error?.message ?? ' '}
                maxAttachmentsCount={1}
                acceptedFormats="image/*"
              />
            )}
          />
        </Grid>
      </Grid>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
          marginBottom: theme.spacing(1),
        }}
      >
        {updateStatus.error && <ErrorAlert error={updateStatus.error} />}
        <LoadingButton
          type="submit"
          color="primary"
          variant="contained"
          loading={updateStatus.isLoading}
          loadingPosition="start"
          startIcon={<SaveIcon />}
        >
          Salva
        </LoadingButton>
      </Box>
    </form>
  );
}
