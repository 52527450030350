import React from 'react';
import { Link } from 'react-router-dom';
import { useReadPlantsQuery } from '@top-solution/microtecnica-utils';
import Fab from '@mui/material/Fab';
import Tooltip from '@mui/material/Tooltip';
import { ChooseGrid, ChooseGridButton } from '../../../components/ChooseGrid';
import { FullScreenAndonIcon, PeopleKaizenIcon } from '../../../components/Icons';
import { Layout } from '../../../components/Layout';
import { ExportDialog } from './ExportDialog/ExportDialog';

export default function Plant(): React.ReactElement {
  const { isLoading, error, data } = useReadPlantsQuery();

  return (
    <Layout title="Selezione stabilimento | QCPC" inProgress={isLoading} error={error} disableGutters>
      <ChooseGrid>
        {data?.list.map((plant) => (
          <ChooseGridButton
            key={plant.id}
            label={plant.name}
            icon={`/icons/${plant.slug}.png`}
            to={`/plants/${plant.id}/cells`}
            sx={{ fontSize: 'h5.fontSize' }}
          />
        ))}
      </ChooseGrid>
      <ExportDialog />
      <Tooltip title="Conteggio Kaizen" placement="right" arrow>
        <Fab sx={{ position: 'fixed', bottom: 88, left: 16 }} component={Link} to="/kaizen">
          <PeopleKaizenIcon />
        </Fab>
      </Tooltip>
      <Tooltip title="Dashboard Andon" placement="right" arrow>
        <Fab sx={{ position: 'fixed', bottom: 16, left: 16 }} component={Link} to="/andon">
          <FullScreenAndonIcon />
        </Fab>
      </Tooltip>
    </Layout>
  );
}
