import { z } from 'zod';
import {
  PagedRequestParams,
  PagedResponse,
  Plant,
  createPagedResponseSchema,
  formatQueryParams,
} from '@top-solution/microtecnica-utils';
import { AndonSchema, Andon, AndonAddForm, AndonStatusForm } from '../entities/Andon';
import { Cell } from '../entities/Cell';
import { api, TAG_TYPES } from './baseApi';
import { combineSearchParams } from './utils';

const url = 'andons';

const wsUrl = import.meta.env.DEV
  ? 'wss://qcpc2.windows.topsolution.dev/realtime/andons'
  : `wss://${window.location.hostname}/realtime/andons`;

const WSResponseSchema = z.object({
  data: z.any(),
  timestamp: z.string(),
  type: z.string(),
});

function camelCase(str: string) {
  if (str === 'ID') return 'id';
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, '');
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function camelizeKeys(obj: Record<string, any>): Record<string, any> {
  if (Array.isArray(obj)) {
    return obj.map((v) => camelizeKeys(v));
  } else if (obj != null && obj.constructor === Object) {
    return Object.keys(obj).reduce(
      (result, key) => ({
        ...result,
        [camelCase(key)]: camelizeKeys(obj[key]),
      }),
      {}
    );
  }
  return obj;
}

const andonApi = api.injectEndpoints({
  endpoints: (builder) => ({
    readAndonDashboardList: builder.query<PagedResponse<Andon>, void>({
      query: () => ({ url: `${url}?limit=3&offset=0&sort=-createDate&status=eq:aperto` }),
      transformResponse: (data) => createPagedResponseSchema(AndonSchema).parse(data),
      providesTags: () => [{ type: TAG_TYPES.ANDON, id: 'DASHBOARD-LIST' }],
      async onCacheEntryAdded(_arg, { cacheDataLoaded, cacheEntryRemoved, dispatch }) {
        const ws = new WebSocket(wsUrl);
        try {
          await cacheDataLoaded;
          const listener = (event: MessageEvent) => {
            try {
              const parsedEventData = JSON.parse(event.data);
              const eventData = WSResponseSchema.parse(parsedEventData);
              const camelizedAndon = camelizeKeys(eventData.data);
              AndonSchema.parse(camelizedAndon);

              const type = eventData.type;
              if (type === 'create-andon' || type === 'update-andon') {
                dispatch(api.util.invalidateTags([{ type: TAG_TYPES.ANDON, id: 'DASHBOARD-LIST' }]));
              }
            } catch (error) {
              return;
            }
          };

          ws.addEventListener('message', listener);
        } catch {
          return;
        }
        await cacheEntryRemoved;
        ws.close();
      },
      keepUnusedDataFor: 1,
    }),
    readAndonDashboardPlantList: builder.query<PagedResponse<Andon>, Plant['id']>({
      query: (id) => ({ url: `${url}?limit=3&offset=0&sort=-createDate&status=eq:aperto&plantId=eq:${id}` }),
      transformResponse: (data) => createPagedResponseSchema(AndonSchema).parse(data),
      providesTags: (_response, _error, id) => [{ type: TAG_TYPES.ANDON, id: `DASHBOARD-LIST-${id}` }],
      async onCacheEntryAdded(arg, { cacheDataLoaded, cacheEntryRemoved, dispatch }) {
        const ws = new WebSocket(wsUrl);
        try {
          await cacheDataLoaded;
          const listener = (event: MessageEvent) => {
            try {
              const parsedEventData = JSON.parse(event.data);
              const eventData = WSResponseSchema.parse(parsedEventData);
              const camelizedAndon = camelizeKeys(eventData.data);
              AndonSchema.parse(camelizedAndon);

              const type = eventData.type;
              if (type === 'create-andon' || type === 'update-andon') {
                dispatch(api.util.invalidateTags([{ type: TAG_TYPES.ANDON, id: `DASHBOARD-LIST-${arg}` }]));
              }
            } catch (error) {
              return;
            }
          };

          ws.addEventListener('message', listener);
        } catch {
          return;
        }
        await cacheEntryRemoved;
        ws.close();
      },
      keepUnusedDataFor: 1,
    }),
    readAndonList: builder.query<PagedResponse<Andon>, PagedRequestParams & { cellId: Cell['id'] }>({
      query: ({ cellId, ...params }) => {
        const cellParam = new URLSearchParams(`?cellId=eq:${cellId}`);
        return {
          url: `${url}`,
          params: combineSearchParams(formatQueryParams(params), cellParam),
        };
      },
      transformResponse: (data) => createPagedResponseSchema(AndonSchema).parse(data),
      providesTags: (_response, _error, params) => {
        if (params.filters) {
          const cellId = params.filters.find((p) => p.field === 'cellId' && p.operator === 'eq')?.value;

          let id = 'LIST';

          if (cellId) id = id.concat(`-BY-CELL-${cellId}`);

          return [{ type: TAG_TYPES.ANDON, id }];
        }
        return [{ type: TAG_TYPES.ANDON, id: `LIST` }];
      },
    }),
    createAndon: builder.mutation<{ id: number }, AndonAddForm & { plantId: Plant['id']; cellId: Cell['id'] }>({
      query: (body) => ({
        url,
        method: 'POST',
        body,
      }),
      invalidatesTags: (_response, _error, body) => [
        { type: TAG_TYPES.ANDON, id: `LIST-BY-CELL-${body.cellId}` },
        { type: TAG_TYPES.ANDON, id: `LIST` },
      ],
    }),
    updateAndon: builder.mutation<void, Andon & { cellId: Cell['id'] }>({
      query: (body) => ({
        url: `${url}/${body.id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (_response, _error, body) => [
        { type: TAG_TYPES.ANDON, id: `LIST-BY-CELL-${body.cellId}` },
        { type: TAG_TYPES.ANDON, id: `LIST` },
      ],
    }),
    updateAndonStatus: builder.mutation<void, AndonStatusForm & { id: Andon['id']; cellId: Cell['id'] }>({
      query: (body) => ({
        url: `${url}/${body.id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: (_response, _error, body) => [
        { type: TAG_TYPES.ANDON, id: `LIST-BY-CELL-${body.cellId}` },
        { type: TAG_TYPES.ANDON, id: `LIST` },
      ],
    }),
    removeAndon: builder.mutation<void, Andon & { plantId: Plant['id']; cellId: Cell['id'] }>({
      query: (body) => ({
        url: `${url}/${body.id}`,
        method: 'DELETE',
        body,
      }),
      invalidatesTags: (_response, _error, body) => [
        { type: TAG_TYPES.ANDON, id: `LIST-BY-CELL-${body.cellId}` },
        { type: TAG_TYPES.ANDON, id: `LIST` },
      ],
    }),
  }),
  overrideExisting: false,
});

export const {
  useReadAndonDashboardListQuery,
  useReadAndonDashboardPlantListQuery,
  useReadAndonListQuery,
  useLazyReadAndonListQuery,
  useRemoveAndonMutation,
  useCreateAndonMutation,
  useUpdateAndonMutation,
  useUpdateAndonStatusMutation,
} = andonApi;
