import { useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { Header, Breadcrumb } from '@top-solution/microtecnica-mui';
import { ApiError } from '@top-solution/microtecnica-utils';
import { ContainerProps } from '@mui/material/Container';
import { useTheme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import AppContainer from './AppContainer';

interface AppLayoutProps extends ContainerProps {
  children?: React.ReactNode | React.ReactNode[];
  headerChildren?: React.ReactNode | React.ReactNode[];
  title?: string;
  className?: string;
  breadcrumbs?: Breadcrumb[];
  error?: ApiError | Error;
  inProgress?: boolean;
  progressIndicator?: React.ReactNode;
}
const APP_NAME = import.meta.env.VITE_NAME as string;

export default function Layout(props: AppLayoutProps): JSX.Element {
  const { children, title, breadcrumbs, inProgress, error, progressIndicator, headerChildren, ...containerProps } =
    props;

  const theme = useTheme();

  const pageTitle = useMemo(() => {
    let pageTitle;
    if (title) {
      pageTitle = title;
    } else if (breadcrumbs?.length) {
      pageTitle = breadcrumbs[breadcrumbs.length - 1].title;
    }
    if (pageTitle) {
      return `${pageTitle} | ${APP_NAME}`;
    }
    return APP_NAME;
  }, [breadcrumbs, title]);

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Header
        logo={{ title: APP_NAME }}
        position="fixed"
        breadcrumbs={breadcrumbs}
        smallScreenBreakpoint={theme.breakpoints.values.sm}
      >
        {headerChildren}
      </Header>
      <Toolbar />
      <AppContainer error={error} inProgress={inProgress} progressIndicator={progressIndicator} {...containerProps}>
        {children}
      </AppContainer>
    </>
  );
}
