import React from 'react';
import { ErrorAlert } from '@top-solution/microtecnica-mui';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Grid from '@mui/material/Grid';
import { FullPageProgress } from '../../../../components/Progress';
import { useReadKaizenSettingsQuery } from '../../../../services/kaizenApi';
import ImageUploadSection from './ImageUpload';
import KaizenITList from './KaizenITList';
import KaizenSPQDCList from './KaizenSPQDCList';
import KaizenTypeList from './KaizenTypeList';

export default function KaizenSettings(): React.ReactElement {
  const { data: settings, isLoading: loadingSettings, error: settingsError } = useReadKaizenSettingsQuery();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Alert severity="warning">
          <AlertTitle>Le modifiche Kaizen sono condivise tra celle</AlertTitle>
          Le modifiche a queste impostazioni si ripercuotono su tutte le celle!
        </Alert>
      </Grid>
      <Grid item xs={3}>
        <KaizenTypeList />
      </Grid>
      <Grid item xs={3}>
        <KaizenITList />
      </Grid>
      <Grid item xs={3}>
        <KaizenSPQDCList />
      </Grid>
      <Grid item xs={3}>
        {settingsError && <ErrorAlert error={settingsError} />}
        {loadingSettings && <FullPageProgress sx={{ minHeight: 0 }} />}
        {settings && !settingsError && (
          <ImageUploadSection
            defaultValues={{
              itImage: settings?.itImage ? [settings.itImage] : [],
              spqdcImage: settings?.spqdcImage ? [settings.spqdcImage] : [],
            }}
          />
        )}
      </Grid>
    </Grid>
  );
}
