import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/query';
import { useReadCellByIdQuery } from '../../services/cellApi';

export const useCellFromUrl = () => {
  const { cellId } = useParams<{ cellId: string }>();
  const navigate = useNavigate();
  const { data: cell, isLoading, error } = useReadCellByIdQuery(Number(cellId) ?? skipToken);

  useEffect(() => {
    if (!cellId || isNaN(Number(cellId))) {
      navigate('/');
    }
  }, [navigate, error, cellId]);

  return { cell, isLoading, error };
};
