import React, { useCallback, useState } from 'react';
import { FixedSizeList, ListChildComponentProps, areEqual } from 'react-window';
import { Draggable, Droppable, DragDropContext } from '@hello-pangea/dnd';
import type {
  DraggableProvided,
  DraggableRubric,
  DraggableStateSnapshot,
  DropResult,
  DroppableProvided,
} from '@hello-pangea/dnd';
import Box from '@mui/material/Box';
import { DialogProps } from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';

import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { DeleteConfirmDialog, ErrorAlert } from '@top-solution/microtecnica-mui';
import { ApiError } from '@top-solution/microtecnica-utils';
import { AddIcon, DeleteIcon, DragIcon, EditIcon } from '../../../components/Icons';
import { FullPageProgress } from '../../../components/Progress';
import { SettingsItem } from '../../../entities/SettingsItem';

export interface BasicRequest {
  isLoading: boolean;
  error?: ApiError | Error;
}
type AddDialogProps = Omit<DialogProps, 'onSubmit' | 'onClose'> & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSubmit: (item: any) => void; //TODO: solve this any
  request: BasicRequest;
  onClose: () => void;
};
type EditDialogProps<T extends SettingsItem> = AddDialogProps & {
  item: T;
};

interface SettingsListProps<T extends SettingsItem> {
  title: string;
  emptyText?: string;
  items?: T[] | null;
  readListRequest: BasicRequest;
  selectedItem?: T | null;
  onSelectedItemChange?: (item: T) => void;
  itemRemove: (item: T) => void;
  itemRemoveRequest: BasicRequest;
  height?: string | number;
  formatName?: (item: T) => string;
  addProps: {
    AddDialog: React.JSXElementConstructor<AddDialogProps>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    submit: (item: any) => void; //TODO: solve this any
    request: BasicRequest;
  };
  editPros: {
    EditDialog: React.JSXElementConstructor<EditDialogProps<T>>;
    submit: (item: T) => void;
    request: BasicRequest;
  };
  isDroppable?: boolean;
  onDragEnd?: (result: DropResult) => void;
}

function AddIconButton<T extends SettingsItem>(props: {
  title: string;
  request: BasicRequest;
  AddDialog: React.JSXElementConstructor<AddDialogProps>;
  submit: (item: T) => void;
}): React.ReactElement {
  const { title, submit, request, AddDialog } = props;
  const [open, setOpen] = useState(false);

  return (
    <>
      <Tooltip title={`Aggiungi ${title}`} placement="top" arrow>
        <IconButton onClick={() => setOpen(true)}>
          <AddIcon />
        </IconButton>
      </Tooltip>
      {open && (
        <AddDialog
          open={open}
          onClose={() => setOpen(false)}
          onSubmit={submit}
          title={`Nuovo ${title}`}
          request={request}
          maxWidth="xs"
          fullWidth
        />
      )}
    </>
  );
}

function RenderListItem<T extends SettingsItem>(props: {
  index: number;
  style: React.CSSProperties;
  item: T;
  selectedItem?: T | null;
  onSelectedItemChange?: (item: T) => void;
  itemRemove: (item: T) => void;
  itemRemoveRequest: BasicRequest;
  formatName?: (item: T) => string;
  editPros: {
    EditDialog: React.JSXElementConstructor<EditDialogProps<T>>;
    submit: (item: T) => void;
    request: BasicRequest;
  };
  provided?: DraggableProvided;
  isDragging?: boolean;
  isDraggable?: boolean;
}): React.ReactElement {
  const {
    index,
    style,
    item,
    itemRemove,
    itemRemoveRequest,
    selectedItem,
    onSelectedItemChange,
    editPros,
    formatName,
    provided,
    isDragging,
    isDraggable,
  } = props;
  const [itemToDelete, setItemToDelete] = useState(null as null | T);
  const [itemToUpdate, setItemToUpdate] = useState(null as null | T);

  const handleDelete = useCallback(() => {
    if (itemToDelete) {
      itemRemove(itemToDelete);
      setItemToDelete(null);
    }
  }, [itemRemove, itemToDelete]);

  return (
    <>
      <ListItem
        style={{ ...style, border: isDragging ? '1px solid' : 'none' }}
        key={index}
        disablePadding
        ref={provided?.innerRef}
        {...provided?.draggableProps}
        {...provided?.dragHandleProps}
      >
        <ListItemButton
          selected={item === selectedItem}
          onClick={() => (onSelectedItemChange ? onSelectedItemChange(item) : {})}
        >
          {isDraggable && (
            <ListItemIcon sx={{ minWidth: '24px' }}>
              <DragIcon />
            </ListItemIcon>
          )}
          <Tooltip title={formatName ? formatName(item) : item.name}>
            <ListItemText
              primaryTypographyProps={{
                sx: {
                  width: '80%',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                },
              }}
            >
              {formatName ? formatName(item) : item.name}
            </ListItemText>
          </Tooltip>
          <ListItemSecondaryAction>
            <IconButton onClick={() => setItemToDelete(item)} title="elimina">
              <DeleteIcon />
            </IconButton>
            <IconButton edge="end" onClick={() => setItemToUpdate(item)} title="modifica">
              <EditIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItemButton>
      </ListItem>
      {Boolean(itemToDelete) && (
        <DeleteConfirmDialog
          open={Boolean(itemToDelete)}
          onClose={() => setItemToDelete(null)}
          confirmText="confermo"
          title={`Vuoi davvero eliminare l'elemento “${itemToDelete?.name}”?`}
          description="Verranno eliminati tutti i dati associati. Questa operazione non è annullabile."
          inProgress={itemRemoveRequest.isLoading}
          error={itemRemoveRequest.error}
          onConfirm={handleDelete}
        />
      )}
      {Boolean(itemToUpdate) && (
        <editPros.EditDialog
          open={Boolean(itemToUpdate)}
          onClose={() => setItemToUpdate(null)}
          onSubmit={editPros.submit}
          title={`Modifica`}
          request={editPros.request}
          maxWidth="xs"
          item={itemToUpdate as T}
          fullWidth
        />
      )}
    </>
  );
}

// eslint-disable-next-line react/display-name
const Row = React.memo(({ data, index, style }: ListChildComponentProps) => {
  const item = data.items[index];

  return (
    <Draggable draggableId={item.id.toString()} index={index} key={item.id}>
      {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
        <div style={style}>
          <RenderListItem
            provided={provided}
            item={item}
            index={index}
            isDraggable={true}
            isDragging={snapshot.isDragging}
            {...data}
          />
        </div>
      )}
    </Draggable>
  );
}, areEqual);

function renderRow(props: ListChildComponentProps) {
  const { index, style, data } = props;
  const item = data.items[index];

  return <RenderListItem style={style} item={item} {...data} index={index} />;
}

export default function SettingsList<T extends SettingsItem>(props: SettingsListProps<T>): React.ReactElement {
  const {
    title,
    emptyText,
    items,
    readListRequest,
    selectedItem,
    onSelectedItemChange,
    itemRemove,
    itemRemoveRequest,
    formatName,
    addProps,
    editPros,
    height,
    isDroppable,
    onDragEnd,
  } = props;

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', minHeight: 48, paddingX: 2 }}>
        <Typography sx={{ fontWeight: 500, marginRight: 1 }}>{title}</Typography>
        {items && <AddIconButton title={title} {...addProps} />}
      </Box>
      {readListRequest.error && <ErrorAlert error={readListRequest.error} />}
      {readListRequest.isLoading && <FullPageProgress sx={{ minHeight: 0 }} />}
      {items ? (
        isDroppable && onDragEnd ? (
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable
              droppableId="droppable"
              mode="virtual"
              renderClone={(provided: DraggableProvided, snapshot: DraggableStateSnapshot, rubric: DraggableRubric) => (
                <RenderListItem
                  provided={provided}
                  style={{ margin: 0 }}
                  index={rubric.source.index}
                  item={items[rubric.source.index]}
                  itemRemove={itemRemove}
                  itemRemoveRequest={itemRemoveRequest}
                  editPros={editPros}
                  isDraggable
                />
              )}
            >
              {(droppableProvided: DroppableProvided) => (
                <FixedSizeList
                  height={200}
                  width={'100%'}
                  itemSize={48}
                  itemCount={items.length}
                  overscanCount={1000}
                  itemData={{
                    items,
                    selectedItem,
                    onSelectedItemChange,
                    itemRemove,
                    itemRemoveRequest,
                    editPros,
                    formatName,
                  }}
                  outerRef={droppableProvided.innerRef}
                >
                  {Row}
                </FixedSizeList>
              )}
            </Droppable>
          </DragDropContext>
        ) : (
          <FixedSizeList
            height={height ?? 200}
            width={'100%'}
            itemSize={48}
            itemCount={items.length}
            overscanCount={5}
            itemData={{
              items,
              selectedItem,
              onSelectedItemChange,
              itemRemove,
              itemRemoveRequest,
              editPros,
              formatName,
            }}
          >
            {renderRow}
          </FixedSizeList>
        )
      ) : (
        emptyText && (
          <Typography
            variant="body2"
            sx={{
              display: 'block',
              m: 2,
              p: 2,
              border: 2,
              borderColor: 'grey.300',
              borderStyle: 'dashed',
              borderRadius: 2,
              color: 'grey.600',
            }}
          >
            {emptyText}
          </Typography>
        )
      )}
    </>
  );
}
