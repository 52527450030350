import { PlantSchema } from '@top-solution/microtecnica-utils';
import { z } from 'zod';

export const CellSchema = z.object({
  id: z.number(),
  name: z.string().nonempty(),
  andonEnabled: z.boolean(),
  plantList: z.array(PlantSchema.omit({ slug: true })).min(1),
  isRtpr: z.boolean(),
  isDeleted: z.boolean().optional().nullable(),
  managerList: z.array(z.string().nonempty()).optional(),
  kaizensAdminList: z.array(z.string().nonempty()).optional(),
});

export const CellAddFormSchema = CellSchema.omit({
  id: true,
  plantList: true,
}).extend({
  managerList: z.array(z.string().nonempty()).min(1),
  plantIdList: z.array(z.number()).min(1),
});

export type Cell = z.infer<typeof CellSchema>;
export type CellAddForm = z.infer<typeof CellAddFormSchema>;
