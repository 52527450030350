import { ReactNode, createContext, useContext } from 'react';
import { Plant } from '@top-solution/microtecnica-utils';

interface PlantContextType {
  plant: Plant;
}

export const PlantContext = createContext<PlantContextType>({
  plant: null as unknown as Plant,
});

export function PlantProvider(props: { plant: Plant; children: ReactNode | ReactNode[] }): JSX.Element {
  return <PlantContext.Provider value={{ plant: props.plant }}>{props.children}</PlantContext.Provider>;
}

export const usePlant = () => {
  return useContext(PlantContext);
};
