export function combineSearchParams(searchParams1: URLSearchParams, searchParams2: URLSearchParams) {
  const result = new URLSearchParams('');

  [searchParams1, searchParams2].map((params) => {
    for (const pair of params.entries()) {
      result.append(pair[0], pair[1]);
    }
  });

  return result;
}
