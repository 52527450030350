import { format as formatFns } from 'date-fns/format';
import { it } from 'date-fns/locale';

export const defaultMinDate = new Date(1929, 11, 5);

export const DATE_SHORT = 'dd/MM/yyyy';
export const DATE_MED = 'd MMM yyyy';
export const DATE_FULL = 'd MMMM yyyy';
export const TIME_SHORT = 'HH:mm';
export const TIME_FULL = 'HH:mm:ss';
export const DATE_TIME = `${DATE_SHORT} ${TIME_SHORT}`;

function format(date: Date | string | undefined | null, format: string): string {
  if (!date) {
    return '–';
  }
  return formatFns(typeof date === 'string' ? new Date(date) : date, format, { locale: it });
}

export function formatDateShort(date: Date | string | undefined | null): string {
  return format(date, DATE_SHORT);
}

export function formatDateMedium(date: Date | string | undefined | null): string {
  return format(date, DATE_MED);
}

export function formatDateFull(date: Date | string | undefined | null): string {
  return format(date, DATE_MED);
}

export function formatTimeShort(date: Date | string | undefined | null): string {
  return format(date, TIME_SHORT);
}

export function formatTimeFull(date: Date | string | undefined | null): string {
  return format(date, TIME_FULL);
}

export function formatDateTime(date: Date | string | undefined | null): string {
  return format(date, DATE_TIME);
}
