import { useCallback } from 'react';
import { ConfirmDialogProps, DeleteConfirmDialog } from '@top-solution/microtecnica-mui';
import { Kaizen } from '../../entities/Kaizen';
import { useRemoveKaizenMutation } from '../../services/kaizenApi';

type KaizenDeleteConfirmProps = Omit<ConfirmDialogProps, 'onClose' | 'title' | 'onConfirm'> & {
  data: Kaizen;
  onClose: (value?: Kaizen) => void;
};

export function KaizenDeleteConfirm(props: KaizenDeleteConfirmProps): JSX.Element {
  const { data, onClose, TransitionProps, ...confirmProps } = props;
  const [remove, removeStatus] = useRemoveKaizenMutation();

  const handleConfirm = useCallback(async () => {
    await remove({ id: data.id }).unwrap();
    onClose(data);
  }, [remove, data, onClose]);

  return (
    <DeleteConfirmDialog
      confirmText="confermo"
      title="Eliminazione Kaizen"
      description={`Il Kaizen con la proposta “${data.opportunity}” verrà eliminata`}
      inProgress={removeStatus.isLoading}
      error={removeStatus.error}
      onConfirm={handleConfirm}
      onClose={() => onClose()}
      TransitionProps={{
        ...TransitionProps,
        onExit: (node) => {
          removeStatus.reset();
          TransitionProps?.onExit?.(node);
        },
      }}
      {...confirmProps}
    />
  );
}
