import { useMemo } from 'react';
import { GridColDef } from '@mui/x-data-grid-premium';
import { usePlantColDef } from '@top-solution/microtecnica-mui';
import { userColumn } from '../../components/DataGrid/userColumn';
import { Turnback } from '../../entities/Turnback';
import { nonEmptyGridDateOperators } from '../../utils/datagrid';
import { formatDateTime } from '../../utils/date';
import { useTurnbackCustomerDataGridColumn } from './useTurnbackCustomerDataGridColumn';
import {
  useTurnbackCategoryDataGridColumn,
  useTurnbackProcessDataGridColumn,
  useTurnbackStepDataGridColumn,
} from './useTurnbackProcessDataGridColumn';
import {
  useTurnbackCellDataGridColumn,
  useTurnbackResponsibleDataGridColumn,
} from './useTurnbackResponisbleDataGridColumn';
import { useTurnbackRTPRDataGridColumn } from './useTurnbackRTPRDataGridColumn';
import { useTurnbackStatusDataGridColumn } from './useTurnbackStatusDataGridColumn';
import { useTurnbackVendorDataGridColumn } from './useTurnbackVendorDataGridColumn';

export function useColumns(isResponsibleCell?: boolean): GridColDef[] {
  const statusColumn = useTurnbackStatusDataGridColumn();
  const processColumn = useTurnbackProcessDataGridColumn(isResponsibleCell);
  const stepColumn = useTurnbackStepDataGridColumn(isResponsibleCell);
  const categoryColumn = useTurnbackCategoryDataGridColumn(isResponsibleCell);
  const customerColumn = useTurnbackCustomerDataGridColumn();
  const vendorColumn = useTurnbackVendorDataGridColumn();
  const responsibleColumn = useTurnbackResponsibleDataGridColumn();
  const cellColumn = useTurnbackCellDataGridColumn();
  const plantColumn = usePlantColDef();
  const rtprColumn = useTurnbackRTPRDataGridColumn();

  return useMemo<GridColDef[]>(
    () => [
      {
        ...statusColumn,
        width: 120,
      },
      { ...rtprColumn },
      { ...cellColumn, width: 120 },
      {
        headerName: 'Occorrenze',
        field: 'occurrences',
        width: 100,
        type: 'number',
      },
      {
        ...plantColumn,
        valueGetter: (value, row: Turnback) => {
          return row.plant.id;
        },
        width: 80,
      },
      {
        ...processColumn,
        width: 200,
      },
      {
        ...stepColumn,
        width: 200,
      },
      {
        ...categoryColumn,
        width: 200,
      },
      {
        ...customerColumn,
        width: 200,
      },
      {
        ...vendorColumn,
        width: 200,
      },
      {
        ...responsibleColumn,
        width: 200,
      },
      {
        headerName: 'Part number',
        field: 'partNumber',
        width: 120,
      },
      {
        ...userColumn,
        headerName: 'Segnalato da',
        field: 'reportedBy',
        width: 120,
      },
      {
        ...userColumn,
        headerName: 'Aperto da',
        field: 'openBy',
        width: 120,
      },
      {
        field: 'createDate',
        headerName: 'Apertura',
        type: 'date',
        valueGetter: (value: string) => value && new Date(value),
        valueFormatter: (value: Date) => value && formatDateTime(value),
        width: 140,
        filterOperators: nonEmptyGridDateOperators(),
      },
      {
        field: 'openingComments',
        headerName: 'Note Apertura',
        width: 140,
      },
      {
        headerName: 'Chiuso da',
        field: 'closedBy',
        width: 120,
      },
      {
        field: 'closeDate',
        headerName: 'Chiusura',
        type: 'date',
        valueGetter: (value: string) => value && new Date(value),
        valueFormatter: (value: Date) => value && formatDateTime(value),
        width: 140,
        filterOperators: nonEmptyGridDateOperators(),
      },
      {
        field: 'closingComments',
        headerName: 'Note Chiusura',
        width: 140,
      },
    ],
    [
      categoryColumn,
      cellColumn,
      customerColumn,
      plantColumn,
      processColumn,
      responsibleColumn,
      rtprColumn,
      statusColumn,
      stepColumn,
      vendorColumn,
    ]
  );
}
