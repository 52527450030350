import { useCallback, useMemo } from 'react';
import pluralize from 'pluralize';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import { useCurrentPng } from 'recharts-to-png';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { TurnbackStat } from '../../../entities/Turnback';
import { useChartContext } from './ChartContext';
import { COLORS } from './utils';

export default function GenericChart(props: { properties: keyof TurnbackStat }) {
  const { data, selected } = useChartContext();
  const { properties } = props;

  const property = useMemo(() => pluralize.singular(properties), [properties]);

  const filteredData = useMemo(() => {
    const arrayData = [] as Record<string, string | number>[];
    const map = new Map<string, Record<string, string | number>>();
    for (const month of data) {
      for (const prop of month[properties] as { pn?: string; id: number; name: string; totalTb: number }[]) {
        const propId = prop.pn || prop.id;
        const propName = prop.pn || prop.name;
        if (map.has(`${property}-${propId}`)) {
          const p = map.get(`${property}-${propId}`)!;
          map.set(`${property}-${propId}`, { ...p, [month.month]: prop.totalTb });
        } else map.set(`${property}-${propId}`, { label: propName, [month.month]: prop.totalTb });
      }
    }
    for (const [key, value] of map.entries()) {
      if (selected.includes(key)) arrayData.push({ label: key, ...value });
    }
    return arrayData;
  }, [data, properties, property, selected]);

  const [getAreaPng, { ref }] = useCurrentPng();
  const handleDownload = useCallback(async () => {
    const png = await getAreaPng();
    if (png) {
      const download = document.createElement('a');
      download.href = png;
      download.download = `${properties}.png`;
      download.click();
    }
  }, [getAreaPng, properties]);

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography sx={{ textTransform: 'capitalize' }}>{properties}</Typography>
        <Button color="primary" variant="contained" size="small" onClick={() => handleDownload()}>
          Salva
        </Button>
      </Box>
      <ResponsiveContainer width="100%" height="100%">
        {filteredData.length ? (
          <BarChart
            data={filteredData}
            margin={{
              top: 5,
              right: 0,
              left: 0,
              bottom: 30,
            }}
            ref={ref}
          >
            <Legend verticalAlign="top" height={36} />
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="label" />
            <YAxis />
            <Tooltip />

            {data.map((entry, index) => {
              return <Bar dataKey={entry.month} key={index} fill={COLORS[index % COLORS.length]}></Bar>;
            })}
          </BarChart>
        ) : (
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Typography>Nessun dato per la selezione corrente</Typography>
          </Box>
        )}
      </ResponsiveContainer>
    </>
  );
}
