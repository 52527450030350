import { useMemo } from 'react';
import { GridColDef } from '@mui/x-data-grid-premium';
import { stringDateColumn } from '../../components/DataGrid/stringDateColumn';
import { userColumn } from '../../components/DataGrid/userColumn';
import { formatTimeShort } from '../../utils/date';
import { useAndonContactDataGridColumn } from './useAndonContactColumns';
import { useAndonProblemDataGridColumn } from './useAndonProblemDataGridColumn';
import { useAndonStatusDataGridColumn } from './useAndonStatusDataGridColumn';
import { useAndonWorkstationDataGridColumn } from './useAndonWorkstationDataGridColumn';

export function useColumns(): GridColDef[] {
  const statusColumn = useAndonStatusDataGridColumn();
  const workstationColumn = useAndonWorkstationDataGridColumn();
  const problemColumn = useAndonProblemDataGridColumn();
  const contactColumn = useAndonContactDataGridColumn();
  return useMemo<GridColDef[]>(
    () => [
      {
        ...statusColumn,
        width: 120,
      },
      { ...workstationColumn, width: 120 },
      { ...problemColumn, width: 120 },
      {
        ...userColumn,
        headerName: 'Segnalato da',
        field: 'reportedBy',
        width: 150,
      },
      {
        ...userColumn,
        headerName: 'Aperto da',
        field: 'openBy',
        width: 120,
      },
      {
        ...stringDateColumn,
        field: 'createDate',
        headerName: 'Apertura',
      },
      {
        field: 'openingTime',
        headerName: 'Ora Apertura',
        valueGetter: (value, row) => {
          return row.createDate;
        },
        valueFormatter: (value: string) => (value ? formatTimeShort(value) : '-'),
        sortable: false,
        filterable: false,
      },
      {
        ...stringDateColumn,
        field: 'closingTime',
        headerName: 'Chiusura',
        type: 'date',
      },
      {
        field: 'closingHours',
        headerName: 'Ora Chiusura',
        valueGetter: (value, row) => {
          return row.closingTime;
        },
        valueFormatter: (value: string) => (value ? formatTimeShort(value) : '-'),
        sortable: false,
        filterable: false,
      },
      {
        ...contactColumn,
        filterable: false,
        sortable: false,
        width: 200,
      },
      {
        headerName: 'Note chiusura',
        field: 'closingComments',
        width: 200,
      },
      {
        headerName: 'Note apertura',
        field: 'openingComments',
        width: 200,
      },
    ],
    [contactColumn, problemColumn, statusColumn, workstationColumn]
  );
}
