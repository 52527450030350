import { z } from 'zod';
import {
  PagedRequestParams,
  PagedResponse,
  Plant,
  createPagedResponseSchema,
  formatQueryParams,
} from '@top-solution/microtecnica-utils';
import { Cell } from '../entities/Cell';
import {
  UserKaizenCountSchema,
  KaizenCount,
  Kaizen,
  KaizenAddForm,
  KaizenStatus,
  KaizenStatusSchema,
  KaizenStatusForm,
  KaizenSchema,
  KaizenSettings,
  KaizenSettingsSchema,
} from '../entities/Kaizen';
import { api, TAG_TYPES } from './baseApi';
import { combineSearchParams } from './utils';

const url = 'kaizens';

const kaizenApi = api.injectEndpoints({
  endpoints: (builder) => ({
    readKaizenListByUser: builder.query<KaizenCount, void>({
      query: () => ({ url: `${url}/byUser` }),
      transformResponse: (data) => {
        const parsedData = z.array(UserKaizenCountSchema).parse(data);
        return {
          total: parsedData.reduce((sum, kaizen) => sum + kaizen.counter, 0),
          byUser: parsedData,
        };
      },
      providesTags: () => [{ type: TAG_TYPES.KAIZEN, id: 'LIST-BY-USER' }],
    }),
    readKaizenList: builder.query<
      PagedResponse<Kaizen>,
      PagedRequestParams & { cellId?: Cell['id']; plantId?: Plant['id'] }
    >({
      query: ({ cellId, plantId, ...params }) => {
        const param = new URLSearchParams(cellId ? `?cellId=eq:${cellId}` : `?plantId=eq:${plantId}`);
        return {
          url: `${url}`,
          params: combineSearchParams(formatQueryParams(params), param),
        };
      },
      transformResponse: (data) => createPagedResponseSchema(KaizenSchema).parse(data),
      providesTags: () => {
        return [{ type: TAG_TYPES.KAIZEN, id: `LIST` }];
      },
    }),
    readKaizenStatusList: builder.query<KaizenStatus[], void>({
      query: () => ({ url: 'kaizen-statuses' }),
      transformResponse: (data) => z.array(KaizenStatusSchema).parse(data),
      providesTags: () => [{ type: TAG_TYPES.KAIZEN, id: 'STATUS_LIST' }],
    }),
    createKaizen: builder.mutation<{ id: number }, KaizenAddForm & { plantId: Plant['id']; cellId: Cell['id'] }>({
      query: (body) => ({
        url: `${url}`,
        method: 'POST',
        body,
      }),
      invalidatesTags: () => [{ type: TAG_TYPES.KAIZEN, id: `LIST` }],
    }),
    updateKaizen: builder.mutation<void, Kaizen & { plantId: Plant['id'] }>({
      query: (body) => ({
        url: `${url}/${body.id}`,
        method: 'PUT',
        body: { ...body, cellId: body.cell.id, plantId: body.plantId },
      }),
      invalidatesTags: () => [{ type: TAG_TYPES.KAIZEN, id: `LIST` }],
    }),
    updateKaizenStatus: builder.mutation<void, KaizenStatusForm & { id: Kaizen['id'] }>({
      query: (body) => ({
        url: `${url}/${body.id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: () => [{ type: TAG_TYPES.KAIZEN, id: `LIST` }],
    }),
    removeKaizen: builder.mutation<void, { id: Kaizen['id'] }>({
      query: (body) => ({
        url: `${url}/${body.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => [{ type: TAG_TYPES.KAIZEN, id: `LIST` }],
    }),
    readKaizenSettings: builder.query<KaizenSettings, void>({
      query: () => ({ url: `${url}/settings` }),
      transformResponse: (data) => KaizenSettingsSchema.parse(data),
      providesTags: () => [{ type: TAG_TYPES.KAIZEN, id: 'SETTINGS' }],
    }),
    updateKaizenSettings: builder.mutation<void, { itImageId?: number; spqdcImageId?: number }>({
      query: (body) => ({
        url: `${url}/settings`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: () => [{ type: TAG_TYPES.KAIZEN, id: `SETTINGS` }],
    }),
  }),
  overrideExisting: false,
});

export const {
  useReadKaizenListByUserQuery,
  useReadKaizenListQuery,
  useLazyReadKaizenListQuery,
  useReadKaizenStatusListQuery,
  useCreateKaizenMutation,
  useRemoveKaizenMutation,
  useUpdateKaizenMutation,
  useUpdateKaizenStatusMutation,
  useReadKaizenSettingsQuery,
  useUpdateKaizenSettingsMutation,
} = kaizenApi;
