import { useMemo } from 'react';
import { GridSingleSelectColDef } from '@mui/x-data-grid-premium';
import { Workstation } from '../../entities/Workstation';
import { useReadCellWorkstationListQuery } from '../../services/workstationApi';
import { useCell } from '../Cell/CellContext';

export function useAndonWorkstationDataGridColumn(): GridSingleSelectColDef {
  const { cell } = useCell();
  const { data } = useReadCellWorkstationListQuery(cell.id);

  const valueOptions = useMemo(() => data?.map((v) => ({ value: v.id, label: v.name })), [data]);
  return useMemo(
    () => ({
      field: 'workstation',
      headerName: 'Postazione/Macchina',
      type: 'singleSelect',
      valueGetter: (value: Workstation) => value?.id,
      valueOptions,
    }),
    [valueOptions]
  );
}
