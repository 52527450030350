import { Cell } from '../entities/Cell';
import { SettingsItemForm } from '../entities/SettingsItem';
import { Workstation, WorkstationSchema } from '../entities/Workstation';

import { api, TAG_TYPES } from './baseApi';

const url = 'andon-workstations';

const workstationApi = api.injectEndpoints({
  endpoints: (builder) => ({
    readCellWorkstationList: builder.query<Workstation[], Cell['id']>({
      query: (id) => ({ url: `${url}?cellId=eq:${id}` }),
      transformResponse: (data) => {
        return WorkstationSchema.array().parse(data);
      },
      providesTags: (_response, _error, id) => [{ type: TAG_TYPES.WORKSTATION, id: `CELL-WORKSTATION-LIST-${id}` }],
      keepUnusedDataFor: 3600,
    }),
    createWorkstation: builder.mutation<{ id: number }, SettingsItemForm & { cellId: Cell['id'] }>({
      query: (body) => ({
        url: `${url}`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (_response, _error, { cellId }) => [
        { type: TAG_TYPES.WORKSTATION, id: `CELL-WORKSTATION-LIST-${cellId}` },
      ],
    }),
    updateWorkstation: builder.mutation<void, SettingsItemForm & { id: Workstation['id']; cellId: Cell['id'] }>({
      query: (body) => ({
        url: `${url}/${body.id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (_response, _error, { cellId }) => [
        { type: TAG_TYPES.WORKSTATION, id: `CELL-WORKSTATION-LIST-${cellId}` },
      ],
    }),
    removeWorkstation: builder.mutation<void, { id: Workstation['id']; cellId: Cell['id'] }>({
      query: (body) => ({
        url: `${url}/${body.id}`,
        method: 'DELETE',
        body,
      }),
      invalidatesTags: (_response, _error, { cellId }) => [
        { type: TAG_TYPES.WORKSTATION, id: `CELL-WORKSTATION-LIST-${cellId}` },
      ],
    }),
  }),
  overrideExisting: false,
});

export const {
  useReadCellWorkstationListQuery,
  useCreateWorkstationMutation,
  useRemoveWorkstationMutation,
  useUpdateWorkstationMutation,
} = workstationApi;
