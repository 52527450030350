import { useMemo } from 'react';
import { skipToken } from '@reduxjs/toolkit/query';
import { GridColDef } from '@mui/x-data-grid-premium';
import { Turnback } from '../../entities/Turnback';
import { useReadCategoryListByCellIdQuery } from '../../services/categoryApi';
import { useReadCellProcessListQuery } from '../../services/processApi';
import { useReadStepListByCellIdQuery } from '../../services/stepApi';
import { useCell } from '../Cell/CellContext';

export function useTurnbackProcessDataGridColumn(isResponsibleCell?: boolean) {
  const { cell } = useCell();

  const readCellProcessList = useReadCellProcessListQuery(isResponsibleCell ? skipToken : cell.id);

  return useMemo<GridColDef<Turnback>>(() => {
    if (isResponsibleCell)
      return {
        field: 'processName',
        headerName: 'Processo',
        type: 'string',
        valueGetter: (value, row) => {
          return row.category.step.process.name;
        },
      };
    return {
      field: 'processId',
      headerName: 'Processo',
      type: 'singleSelect',
      valueOptions: readCellProcessList.data?.map((v) => ({ value: v.id, label: v.name })),
      valueGetter: (value, row) => {
        return row.category.step.process.id;
      },
      renderCell: ({ formattedValue, row }) => {
        if (readCellProcessList.isLoading) return row.category.step.process.name;
        return formattedValue;
      },
    };
  }, [readCellProcessList, isResponsibleCell]);
}

export function useTurnbackStepDataGridColumn(isResponsibleCell?: boolean) {
  const { cell } = useCell();

  const readCellProcessList = useReadStepListByCellIdQuery(isResponsibleCell ? skipToken : cell.id);

  return useMemo<GridColDef<Turnback>>(() => {
    if (isResponsibleCell)
      return {
        field: 'stepName',
        headerName: 'Step',
        type: 'string',
        valueGetter: (value, row) => {
          return row.category.step.name;
        },
      };
    return {
      field: 'stepId',
      headerName: 'Step',
      type: 'singleSelect',
      valueOptions: readCellProcessList.data?.map((v) => ({ value: v.id, label: v.name })),
      valueGetter: (value, row) => {
        return row.category.step.id;
      },
      renderCell: ({ formattedValue, row }) => {
        if (readCellProcessList.isLoading) return row.category.step.id;
        return formattedValue;
      },
    };
  }, [readCellProcessList, isResponsibleCell]);
}

export function useTurnbackCategoryDataGridColumn(isResponsibleCell?: boolean) {
  const { cell } = useCell();

  const readCellProcessList = useReadCategoryListByCellIdQuery(isResponsibleCell ? skipToken : cell.id);

  return useMemo<GridColDef<Turnback>>(() => {
    if (isResponsibleCell)
      return {
        field: 'categoryName',
        headerName: 'Categoria',
        type: 'string',
        valueGetter: (value, row) => {
          return row.category.name;
        },
      };
    return {
      field: 'categoryId',
      headerName: 'Categoria',
      type: 'singleSelect',
      valueOptions: readCellProcessList.data?.map((v) => ({ value: v.id, label: v.name })),
      valueGetter: (value, row) => {
        return row.category.id;
      },
      renderCell: ({ formattedValue, row }) => {
        if (readCellProcessList.isLoading) return row.category.id;
        return formattedValue;
      },
    };
  }, [readCellProcessList, isResponsibleCell]);
}
