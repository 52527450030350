import { useMemo } from 'react';
import { GridSingleSelectColDef } from '@mui/x-data-grid-premium';
import { Problem } from '../../entities/Problem';
import { useReadProblemListQuery } from '../../services/problemApi';

export function useAndonProblemDataGridColumn(): GridSingleSelectColDef {
  const { data } = useReadProblemListQuery();

  const valueOptions = useMemo(() => data?.map((v) => ({ value: v.id, label: v.name })), [data]);
  return useMemo(
    () => ({
      field: 'problemType',
      headerName: 'Tipo Problema',
      type: 'singleSelect',
      valueGetter: (value: Problem) => value?.id,
      valueOptions,
    }),
    [valueOptions]
  );
}
