import { useMemo } from 'react';
import { GridSingleSelectColDef } from '@mui/x-data-grid-premium';
import { Turnback } from '../../entities/Turnback';
import { useReadVendorListQuery } from '../../services/vendorApi';

export function useTurnbackVendorDataGridColumn(): GridSingleSelectColDef {
  const { data } = useReadVendorListQuery();

  const valueOptions = useMemo(() => data?.map((v) => ({ value: v.id, label: v.name })), [data]);
  return useMemo(
    () => ({
      field: 'vendorId',
      headerName: 'Vendor',
      type: 'singleSelect',
      valueOptions,
      valueGetter: (value, row: Turnback) => {
        return row.vendor?.id;
      },
    }),
    [valueOptions]
  );
}
