import { Navigate, Route, Routes } from 'react-router-dom';
import { AuthGuard } from '@top-solution/microtecnica-utils';
import { KaizenListByCell, NotFoundPage, UnauthorizedPage } from '..';
import { AndonGuardRoute } from '../Andon/AndonGuardRoute';
import AndonListByCell from '../Andon/AndonListByCell';
import { TurnbackRouter } from '../Turnback';
import CellPage from './CellPage';
import Settings from './Settings/SettingsPage';

export default function CellRouter(): JSX.Element {
  return (
    <AuthGuard unauthorizedFallback={<UnauthorizedPage />}>
      <Routes>
        <Route path="/:cellId" element={<CellPage />}>
          <Route path="settings" element={<Settings />} />
          <Route path="kaizen" element={<KaizenListByCell />} />
          <Route element={<AndonGuardRoute />}>
            <Route path="andon" element={<AndonListByCell />} />
          </Route>
          <Route path="turnback/*" element={<TurnbackRouter />} />
          <Route index element={<Navigate to="turnback" replace={true} />} />
        </Route>
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </AuthGuard>
  );
}
