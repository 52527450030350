import React, { useCallback } from 'react';
import { KaizenType } from '../../../../entities/Kaizen';
import { SettingsItemForm } from '../../../../entities/SettingsItem';
import {
  useCreateKaizenTypeMutation,
  useReadKaizenTypeListQuery,
  useRemoveKaizenTypeMutation,
  useUpdateKaizenTypeMutation,
} from '../../../../services/kaizenTypeApi';
import { ItemAddDialog, ItemEditDialog } from '../ItemDialog';
import SettingsList from '../SettingsList';

export default function KaizenTypeList(): React.ReactElement {
  const { data, isLoading, error } = useReadKaizenTypeListQuery();
  const [remove, removeState] = useRemoveKaizenTypeMutation();
  const [create, createState] = useCreateKaizenTypeMutation();
  const [update, updateState] = useUpdateKaizenTypeMutation();

  const handleRemove = useCallback(
    async (vendor: KaizenType) => {
      return remove(vendor);
    },
    [remove],
  );

  const handleUpdate = useCallback(
    async (vendor: KaizenType) => {
      return update(vendor);
    },
    [update],
  );

  const handleCreate = useCallback(
    async (values: SettingsItemForm) => {
      return create({ ...values });
    },
    [create],
  );
  return (
    <SettingsList
      title="Tipologie"
      items={data?.filter((i) => !i.isDeleted)}
      readListRequest={{ isLoading, error }}
      itemRemove={handleRemove}
      itemRemoveRequest={removeState}
      addProps={{
        AddDialog: ItemAddDialog,
        submit: handleCreate,
        request: createState,
      }}
      editPros={{
        EditDialog: ItemEditDialog,
        submit: handleUpdate,
        request: updateState,
      }}
    />
  );
}
