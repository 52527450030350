import { GridColDef } from '@mui/x-data-grid-premium';
import { nonEmptyGridDateOperators } from '../../utils/datagrid';

export const stringDateColumn: GridColDef = {
  field: 'date',
  headerName: 'Data',
  type: 'date',
  valueGetter: (value) => value && new Date(value),
  valueFormatter: (value: Date) =>
    value && value.toLocaleDateString(undefined, { day: '2-digit', month: '2-digit', year: 'numeric' }),
  filterOperators: nonEmptyGridDateOperators(),
};
