import { ServerErrorBox } from '@top-solution/microtecnica-mui';
import { ApiError } from '@top-solution/microtecnica-utils';
import Container, { ContainerProps } from '@mui/material/Container';

import { FullPageProgress } from '../Progress';

interface AppContainerProps extends ContainerProps {
  children?: React.ReactNode | React.ReactNode[];
  error?: ApiError | Error;
  inProgress?: boolean;
  progressIndicator?: React.ReactNode;
}

export default function AppContainer(props: AppContainerProps): JSX.Element {
  const { children, inProgress, error, progressIndicator, sx, ...containerProps } = props;

  return (
    <Container
      sx={{
        flex: '1 1 auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'stretch',
        ...sx,
      }}
      {...containerProps}
    >
      {inProgress ? (
        progressIndicator || <FullPageProgress />
      ) : error ? (
        <ServerErrorBox error={error} sx={{ marginX: 'auto' }} />
      ) : (
        children
      )}
    </Container>
  );
}
