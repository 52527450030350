import React from 'react';
import SplitVertical from '@mdi/svg/svg/arrow-split-vertical.svg?react';
import Check from '@mdi/svg/svg/check.svg?react';
import ChevronDown from '@mdi/svg/svg/chevron-down.svg?react';
import ChevronRight from '@mdi/svg/svg/chevron-right.svg?react';
import Close from '@mdi/svg/svg/close.svg?react';
import Copy from '@mdi/svg/svg/content-copy.svg?react';
import ContentSave from '@mdi/svg/svg/content-save.svg?react';
import Drag from '@mdi/svg/svg/drag.svg?react';
import ExcelFile from '@mdi/svg/svg/file-excel.svg?react';
import Upload from '@mdi/svg/svg/file-upload.svg?react';
import File from '@mdi/svg/svg/file.svg?react';
import Help from '@mdi/svg/svg/help.svg?react';
import PeopleKaizen from '@mdi/svg/svg/lightbulb-group.svg?react';
import PlantKaizen from '@mdi/svg/svg/lightbulb.svg?react';
import Search from '@mdi/svg/svg/magnify.svg?react';
import Pencil from '@mdi/svg/svg/pencil.svg?react';
import Add from '@mdi/svg/svg/plus.svg?react';
import StateMachine from '@mdi/svg/svg/state-machine.svg?react';
import Details from '@mdi/svg/svg/text-box-search.svg?react';
import FullScreenAndon from '@mdi/svg/svg/timeline-alert.svg?react';
import TrashCan from '@mdi/svg/svg/trash-can.svg?react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const createIcon = (icon: React.ReactNode) =>
  function Icon(props: SvgIconProps): JSX.Element {
    return <SvgIcon {...props}>{icon}</SvgIcon>;
  };

export const PeopleKaizenIcon = createIcon(<PeopleKaizen />);
export const PlantKaizenIcon = createIcon(<PlantKaizen />);
export const FullScreenAndonIcon = createIcon(<FullScreenAndon />);
export const CheckIcon = createIcon(<Check />);
export const DetailsIcon = createIcon(<Details />);
export const AddIcon = createIcon(<Add />);
export const SaveIcon = createIcon(<ContentSave />);
export const ChevronDownIcon = createIcon(<ChevronDown />);
export const ChevronRightIcon = createIcon(<ChevronRight />);
export const EditIcon = createIcon(<Pencil />);
export const DeleteIcon = createIcon(<TrashCan />);
export const SearchIcon = createIcon(<Search />);
export const CancelIcon = createIcon(<Close />);
export const StateMachineIcon = createIcon(<StateMachine />);
export const FileIcon = createIcon(<File />);
export const UploadIcon = createIcon(<Upload />);
export const ExcelFileIcon = createIcon(<ExcelFile />);
export const HelpIcon = createIcon(<Help />);
export const SplitVerticalIcon = createIcon(<SplitVertical />);
export const CopyIcon = createIcon(<Copy />);
export const DragIcon = createIcon(<Drag />);
