import { useState } from 'react';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { AddIcon } from '../../components/Icons';
import { KaizenAddForm } from '../../entities/Kaizen';
import { useCell } from '../Cell/CellContext';
import { KaizenDialog } from './KaizenDialog';

const initialValues: KaizenAddForm = {
  estimatedSavings: null,
  opportunity: '',
  plantId: null,
  reportedBy: '',
  suggestion: '',
  typeId: null,
  it: [],
  spqdc: [],
} as unknown as KaizenAddForm;

export function KaizenAddButton(props: IconButtonProps): JSX.Element {
  const [dialogOpen, setDialogOpen] = useState(false);
  const { cell } = useCell();

  return (
    <>
      <Tooltip title="Aggiungi Kaizen">
        <IconButton color="primary" onClick={() => setDialogOpen(true)} {...props}>
          <AddIcon />
        </IconButton>
      </Tooltip>
      {dialogOpen && (
        <KaizenDialog
          open={dialogOpen}
          initialValues={initialValues}
          onClose={() => setDialogOpen(false)}
          cell={cell}
        />
      )}
    </>
  );
}
