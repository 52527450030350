import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useReadPlantsQuery } from '@top-solution/microtecnica-utils';

export const usePlantFromUrl = () => {
  const { plantId } = useParams<{ plantId: string }>();
  const navigate = useNavigate();
  const { data: plants, isLoading, error } = useReadPlantsQuery();

  useEffect(() => {
    if (!plantId || isNaN(Number(plantId))) {
      navigate('/');
    }
  }, [navigate, error, plantId]);

  return { plant: plants?.list.find((p) => p.id === Number(plantId)), isLoading, error };
};
