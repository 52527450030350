import React from 'react';
import Box, { BoxProps } from '@mui/material/Box';

export default function ChooseGrid(props: BoxProps): React.ReactElement {
  const { children, sx, ...boxProps } = props;

  return (
    <Box
      sx={{
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'center',
        px: 2,
        ...sx,
      }}
      {...boxProps}
    >
      {children}
    </Box>
  );
}
