import { useState } from 'react';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { AddIcon } from '../../components/Icons';
import { TurnbackAddForm } from '../../entities/Turnback';
import { TurnbackDialog } from './TurnbackDialog';

const initialValues: TurnbackAddForm = {
  categoryId: null,
  customerId: null,
  processId: null,
  stepId: null,
  attachmentList: [],
  notificationEmailList: [],
  occurrences: 1,
  openingComments: '',
  partNumber: '',
  reportedBy: '',
  vendorId: null,
  isRtpr: false,
  createDate: new Date(),
  responsibleCellId: null,
} as unknown as TurnbackAddForm;

export function TurnbackAddButton(props: IconButtonProps): JSX.Element {
  const [dialogOpen, setDialogOpen] = useState(false);

  return (
    <>
      <Tooltip title="Aggiungi Turnback">
        <IconButton color="primary" onClick={() => setDialogOpen(true)} {...props}>
          <AddIcon />
        </IconButton>
      </Tooltip>
      {dialogOpen && (
        <TurnbackDialog open={dialogOpen} initialValues={initialValues} onClose={() => setDialogOpen(false)} />
      )}
    </>
  );
}
