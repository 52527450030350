import { SettingsItem, SettingsItemForm, SettingsItemSchema } from '../entities/SettingsItem';
import { api, TAG_TYPES } from './baseApi';

const url = 'kaizen-spqdc';

const kaizenSPQDCApi = api.injectEndpoints({
  endpoints: (builder) => ({
    readKaizenSPQDCList: builder.query<SettingsItem[], void>({
      query: () => ({ url }),
      transformResponse: (data) => {
        return SettingsItemSchema.array().parse(data);
      },
      providesTags: () => [{ type: TAG_TYPES.KAIZEN_SPQDC, id: `LIST` }],
      keepUnusedDataFor: 3600,
    }),
    createKaizenSPQDC: builder.mutation<{ id: number }, SettingsItemForm>({
      query: (body) => ({
        url,
        method: 'POST',
        body: { name: body.name },
      }),
      invalidatesTags: () => [{ type: TAG_TYPES.KAIZEN_SPQDC, id: `LIST` }],
    }),
    updateKaizenSPQDC: builder.mutation<void, SettingsItem>({
      query: (body) => ({
        url: `${url}/${body.id}`,
        method: 'PUT',
        body: { name: body.name },
      }),
      invalidatesTags: () => [{ type: TAG_TYPES.KAIZEN_SPQDC, id: `LIST` }],
    }),
    removeKaizenSPQDC: builder.mutation<void, SettingsItem>({
      query: (body) => ({
        url: `${url}/${body.id}`,
        method: 'DELETE',
        body,
      }),
      invalidatesTags: () => [{ type: TAG_TYPES.KAIZEN_SPQDC, id: `LIST` }],
    }),
  }),
  overrideExisting: false,
});

export const {
  useCreateKaizenSPQDCMutation,
  useReadKaizenSPQDCListQuery,
  useRemoveKaizenSPQDCMutation,
  useUpdateKaizenSPQDCMutation,
} = kaizenSPQDCApi;
