import { useCallback } from 'react';
import { ConfirmDialogProps, DeleteConfirmDialog } from '@top-solution/microtecnica-mui';
import { Andon } from '../../entities/Andon';
import { useRemoveAndonMutation } from '../../services/andonApi';
import { useCell } from '../Cell/CellContext';
import { usePlant } from '../Plant/PlantContext';

type AndonDeleteConfirmProps = Omit<ConfirmDialogProps, 'onClose' | 'title' | 'onConfirm'> & {
  data: Andon;
  onClose: (value?: Andon) => void;
};

export function AndonDeleteConfirm(props: AndonDeleteConfirmProps): JSX.Element {
  const { data, onClose, TransitionProps, ...confirmProps } = props;
  const [remove, removeStatus] = useRemoveAndonMutation();
  const { plant } = usePlant();
  const { cell } = useCell();

  const handleConfirm = useCallback(async () => {
    await remove({ ...data, plantId: plant.id, cellId: cell.id }).unwrap();
    onClose(data);
  }, [remove, data, plant.id, cell.id, onClose]);

  return (
    <DeleteConfirmDialog
      confirmText="confermo"
      title="Eliminazione Andon"
      description={`L' Andon con id “${data.id}” sarà eliminato`}
      inProgress={removeStatus.isLoading}
      error={removeStatus.error}
      onConfirm={handleConfirm}
      onClose={() => onClose()}
      TransitionProps={{
        ...TransitionProps,
        onExit: (node) => {
          removeStatus.reset();
          TransitionProps?.onExit?.(node);
        },
      }}
      {...confirmProps}
    />
  );
}
