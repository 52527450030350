import { ReactNode, createContext, useContext, useMemo, useState } from 'react';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { ErrorAlert } from '@top-solution/microtecnica-mui';
import { FullPageProgress } from '../../../components/Progress';
import { TurnbackStat } from '../../../entities/Turnback';

export interface StatsMap {
  id: string;
  name: string;
  type?: string;
  parentId?: number;
  children?: StatsMap[];
}

export interface ChartContextType {
  stats?: StatsMap;
  selected: string[];
  setSelected: (value: string[]) => void;
  data: TurnbackStat[];
}

export const ChartContext = createContext<ChartContextType>({} as ChartContextType);

export function useChartContext() {
  return useContext(ChartContext);
}

export function ChartContextProvider(props: {
  children: ReactNode | ReactNode[];
  data?: TurnbackStat[];
  isLoading: boolean;
  error?: FetchBaseQueryError | SerializedError;
}): JSX.Element {
  const { children, data, isLoading, error } = props;
  const [selected, setSelected] = useState<string[]>([]);

  const stats = useMemo(() => {
    if (!data) return undefined;
    const defaultSelected = new Set<string>();
    const processMap = new Map<number, StatsMap>();
    const stepMap = new Map<number, StatsMap>();
    const categoryMap = new Map<number, StatsMap>();
    const partNumberMap = new Map<string, StatsMap>();

    for (const month of data) {
      for (const process of month.processes) {
        defaultSelected.add(`process-${process.id}`);
        processMap.set(process.id, { id: `process-${process.id}`, name: process.name, type: 'process' });
      }
      for (const step of month.steps) {
        defaultSelected.add(`step-${step.id}`);
        stepMap.set(step.id, { id: `step-${step.id}`, name: step.name, type: 'step', parentId: step.processId });
      }
      for (const category of month.categories) {
        defaultSelected.add(`category-${category.id}`);
        categoryMap.set(category.id, {
          id: `category-${category.id}`,
          name: category.name,
          type: 'category',
          parentId: category.stepId,
        });
      }
      for (const partNumber of month.partNumbers) {
        defaultSelected.add(`partNumber-${partNumber.pn}`);
        partNumberMap.set(partNumber.pn, {
          id: `partNumber-${partNumber.pn}`,
          name: partNumber.pn,
          type: 'partNumber',
          parentId: partNumber.categoryId,
        });
      }
    }

    for (const value of partNumberMap.values()) {
      if (categoryMap.has(value.parentId!)) {
        const category = categoryMap.get(value.parentId!)!;
        categoryMap.set(value.parentId!, { ...category, children: [...(category.children || []), { ...value }] });
      }
    }
    for (const value of categoryMap.values()) {
      if (stepMap.has(value.parentId!)) {
        const step = stepMap.get(value.parentId!)!;
        stepMap.set(value.parentId!, { ...step, children: [...(step.children || []), { ...value }] });
      }
    }
    for (const value of stepMap.values()) {
      if (processMap.has(value.parentId!)) {
        const process = processMap.get(value.parentId!)!;
        processMap.set(value.parentId!, { ...process, children: [...(process.children || []), { ...value }] });
      }
    }

    setSelected(['all', ...Array.from(defaultSelected)]);
    return { id: 'all', name: 'Tutti', children: Array.from(processMap.values()) };
  }, [data]);

  const values: ChartContextType = useMemo(
    () => ({
      stats,
      selected,
      setSelected,
      data: data || [],
    }),
    [data, selected, stats],
  );
  return (
    <ChartContext.Provider value={values}>
      {isLoading && <FullPageProgress />}
      {error && <ErrorAlert sx={{ mt: 2 }} error={error} />}
      {!isLoading && !error && children}
    </ChartContext.Provider>
  );
}
