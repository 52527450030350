import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { DeleteConfirmDialog, ErrorAlert } from '@top-solution/microtecnica-mui';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { SaveIcon } from '../../../components/Icons';
import { CellAddForm, CellAddFormSchema } from '../../../entities/Cell';
import { useRemoveCellMutation, useUpdateCellMutation } from '../../../services/cellApi';
import { CellForm } from '../../Plant/CellAdd/CellForm';
import { useCell } from '../CellContext';

function DeleteButton(): React.ReactElement {
  const [deleteOpen, setDeleteOpen] = useState(false);
  const { cell } = useCell();
  const [remove, removeStatus] = useRemoveCellMutation();
  const navigate = useNavigate();

  const handleDelete = useCallback(async () => {
    if (cell) {
      await remove(cell);
      setDeleteOpen(true);
      navigate('/');
    }
  }, [cell, navigate, remove]);

  return (
    <>
      <Tooltip title="Elimina reparto">
        <Button
          variant="contained"
          onClick={() => setDeleteOpen(true)}
          sx={{ bgcolor: 'error.main', color: 'error.contrastText', '&:hover': { bgcolor: 'error.dark' } }}
        >
          Elimina
        </Button>
      </Tooltip>
      <DeleteConfirmDialog
        open={deleteOpen}
        onClose={() => setDeleteOpen(false)}
        confirmText="confermo"
        title="Eliminazione reparto"
        description={`Il reparto “${cell?.name}” verrà eliminato`}
        inProgress={removeStatus.isLoading}
        error={removeStatus.error}
        onConfirm={handleDelete}
      />
    </>
  );
}

export default function CellDetails(): React.ReactElement {
  const { cell } = useCell();
  const [update, updateStatus] = useUpdateCellMutation();

  const form = useForm<CellAddForm>({
    defaultValues: { ...cell, plantIdList: cell.plantList.map((p) => p.id) },
    resolver: zodResolver(CellAddFormSchema),
  });

  const onSubmit = useCallback(
    async (data: CellAddForm) => {
      if (cell) await update({ id: cell?.id, ...data }).unwrap();
    },
    [cell, update],
  );

  return (
    <>
      <form onSubmit={form.handleSubmit(onSubmit)} noValidate>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: 2,
            '& > div': { flex: '1 0 49%', marginX: 0, marginBottom: 1 },
            '& > label': { flex: '1 0 49%', marginX: 0, marginBottom: 3 },
          }}
        >
          <CellForm form={form} />
        </Box>
        {updateStatus.error && <ErrorAlert error={updateStatus.error} />}
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <LoadingButton
            type="submit"
            color="primary"
            variant="contained"
            loading={updateStatus.isLoading}
            loadingPosition="start"
            startIcon={<SaveIcon />}
          >
            Aggiorna
          </LoadingButton>
          <DeleteButton />
        </Box>
      </form>
    </>
  );
}
