import { useMemo } from 'react';
import { Link, Outlet, matchPath, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import { yellow } from '@mui/material/colors';
import { useTheme } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { Layout } from '../../components/Layout';
import { usePlant } from '../Plant/PlantContext';
import { CellProvider } from './CellContext';
import { useCellFromUrl } from './useCellFromUrl';

function useRouteMatch(patterns: readonly string[]) {
  const { pathname } = useLocation();

  for (const pattern of patterns) {
    const possibleMatch = matchPath(`/plants/:plantId/cells/:cellId/${pattern}`, pathname);
    if (possibleMatch !== null) {
      return pattern;
    }
  }

  return null;
}

export default function CellPage(): JSX.Element {
  const currentTab = useRouteMatch(['andon', 'kaizen', 'settings', 'turnback']);
  const { cell, isLoading, error } = useCellFromUrl();
  const { plant } = usePlant();
  const theme = useTheme();

  const breadcrumbs = useMemo(
    () => [
      {
        title: plant?.name,
        url: `/plants/${plant.id}/cells`, // TODO: Store this in section and replace plantId
      },
      { title: cell?.name },
    ],
    [cell?.name, plant.id, plant?.name],
  );

  return (
    <Layout
      title={cell?.name}
      maxWidth={false}
      breadcrumbs={breadcrumbs}
      inProgress={isLoading}
      error={error}
      headerChildren={
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }} mb={1}>
          <Tabs
            value={currentTab || 'turnback'}
            aria-label="Tab cell navigation"
            textColor="secondary"
            indicatorColor="primary"
            color={theme.palette.common.white}
            TabIndicatorProps={{
              style: {
                backgroundColor: yellow[500],
              },
            }}
            sx={{
              '.Mui-selected': {
                color: `${yellow[500]} !important`,
              },
            }}
          >
            <Tab
              label="Turnback"
              value="turnback"
              component={Link}
              to="turnback"
              sx={{
                color: theme.palette.common.white,
              }}
            />
            <Tab
              label="Andon"
              value="andon"
              component={Link}
              to="andon"
              sx={{ display: cell?.andonEnabled ? 'flex' : 'none', color: theme.palette.common.white }}
            />
            <Tab
              label="Kaizen"
              value="kaizen"
              component={Link}
              to="kaizen"
              sx={{ color: theme.palette.common.white }}
            />
            <Tab
              label="Impostazioni"
              value="settings"
              component={Link}
              to="settings"
              sx={{ color: theme.palette.common.white }}
            />
          </Tabs>
        </Box>
      }
    >
      {cell && (
        <CellProvider cell={cell}>
          <Outlet />
        </CellProvider>
      )}
    </Layout>
  );
}
