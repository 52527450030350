import { useCallback, useEffect, useMemo, useState } from 'react';
import { filterDeep } from 'deepdash-es/standalone';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import { CancelIcon, SearchIcon } from '../../../components/Icons';
import { StatsMap, useChartContext } from './ChartContext';

const getExpandedDomain = (tree: StatsMap | undefined): string[] => {
  if (!tree || !tree.children) return [];
  return [tree.id, ...tree.children.map((t) => getExpandedDomain(t)).flat()];
};

export default function ChartFilters() {
  const { selected, setSelected, stats } = useChartContext();
  const [needle, setNeedle] = useState('');
  const [expanded, setExpanded] = useState<string[]>(['all']);
  const selectedSet = useMemo(() => new Set(selected), [selected]);

  const getAllChild = useCallback((childNode: StatsMap | null, collectedNodes: string[] = []) => {
    if (childNode === null) return collectedNodes;

    collectedNodes.push(childNode.id);

    if (Array.isArray(childNode.children)) {
      for (const node of childNode.children) {
        getAllChild(node, collectedNodes);
      }
    }

    return collectedNodes;
  }, []);

  const goThroughAllNodes = useCallback(
    (nodes: StatsMap, map: Record<string, string[]> = {}) => {
      if (!nodes.children) {
        return null;
      }

      map[nodes.id] = getAllChild(nodes).splice(1);

      for (const childNode of nodes.children) {
        goThroughAllNodes(childNode, map);
      }

      return map;
    },
    [getAllChild]
  );

  const parentMap = useMemo(() => {
    if (!stats) return null;
    return goThroughAllNodes(stats);
  }, [goThroughAllNodes, stats]);

  const getChildById = (nodes: StatsMap, id: string) => {
    const array: string[] = [];
    const path: string[] = [];

    // recursive DFS
    function getNodeById(node: StatsMap, id: string, parentsPath: string[]): StatsMap | null {
      let result = null;

      if (node.id === id) {
        return node;
      } else if (Array.isArray(node.children)) {
        for (const childNode of node.children) {
          result = getNodeById(childNode, id, parentsPath);

          if (result) {
            parentsPath.push(node.id);
            return result;
          }
        }

        return result;
      }

      return result;
    }

    const nodeToToggle = getNodeById(nodes, id, path);

    return { childNodesToToggle: getAllChild(nodeToToggle, array), path };
  };

  function getOnChange(checked: boolean, nodes: StatsMap) {
    if (!stats) return;
    const { childNodesToToggle, path } = getChildById(stats, nodes.id);

    let array = checked
      ? [...selected, ...childNodesToToggle]
      : selected.filter((value) => !childNodesToToggle.includes(value)).filter((value) => !path.includes(value));

    array = array.filter((v, i) => array.indexOf(v) === i);

    setSelected(array);
  }

  const renderTree = (nodes: StatsMap) => {
    if (!parentMap) return <></>;
    const allSelectedChildren = parentMap[nodes.id]?.every((childNodeId: string) => selectedSet.has(childNodeId));
    const checked = selectedSet.has(nodes.id) || allSelectedChildren || false;

    const indeterminate = parentMap[nodes.id]?.some((childNodeId: string) => selectedSet.has(childNodeId)) || false;

    if (allSelectedChildren && !selectedSet.has(nodes.id)) {
      setSelected([...selected, nodes.id]);
    }

    return (
      <TreeItem
        key={nodes.id}
        itemId={nodes.id}
        label={
          <FormControlLabel
            control={
              <Checkbox
                checked={checked}
                indeterminate={!checked && indeterminate}
                onChange={(event) => getOnChange(event.currentTarget.checked, nodes)}
                onClick={(e) => e.stopPropagation()}
              />
            }
            label={<>{nodes.name}</>}
            key={nodes.id}
          />
        }
      >
        {Array.isArray(nodes.children) ? nodes.children.map((node) => renderTree(node)) : null}
      </TreeItem>
    );
  };

  const filteredStats: StatsMap | null = useMemo(
    () =>
      stats
        ? filterDeep(
            stats,
            (value: StatsMap) => {
              return value.name.toLocaleLowerCase().includes(needle.toLocaleLowerCase());
            },
            {
              childrenPath: ['children'],
            }
          )
        : null,
    [needle, stats]
  );

  useEffect(() => {
    if (!needle) setExpanded(['all']);
    else if (filteredStats) setExpanded(getExpandedDomain(filteredStats));
  }, [filteredStats, needle]);

  const handleToggle = (_event: React.SyntheticEvent, nodeIds: string[]) => {
    setExpanded(nodeIds);
  };

  if (!stats || !parentMap) return <></>;

  return (
    <>
      <TextField
        placeholder="Cerca.."
        value={needle}
        onChange={(event) => setNeedle(event.target.value)}
        fullWidth
        type="text"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: needle && (
            <IconButton aria-label="clear" onClick={() => setNeedle('')}>
              <CancelIcon />
            </IconButton>
          ),
        }}
      />
      {filteredStats ? (
        <SimpleTreeView expandedItems={expanded} onExpandedItemsChange={handleToggle}>
          {renderTree(filteredStats)}
        </SimpleTreeView>
      ) : (
        <Typography>Nessun elemento trovato per la selezione corrente</Typography>
      )}
    </>
  );
}
