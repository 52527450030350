import { z } from 'zod';
import { CellSchema } from './Cell';

export enum KAIZEN_STATUS {
  OPEN = 1,
  TAKEN_CHARGE = 2,
  SUSPENDED = 3,
  NOT_IMPLEMENTABLE = 4,
  IMPLEMENTABLE = 5,
}

export const KaizenStatusSchema = z.object({
  id: z.nativeEnum(KAIZEN_STATUS),
  name: z.string(),
  needsComment: z.boolean(),
});

export const UserKaizenCountSchema = z.object({
  counter: z.number(),
  username: z.string(),
});

export const KaizenCountSchema = z.object({
  total: z.number(),
  byUser: z.array(UserKaizenCountSchema),
});

export const KaizenTypeSchema = z.object({
  id: z.number(),
  name: z.string(),
  isDeleted: z.boolean().optional().nullable(),
});

export const KaizenSchema = z.object({
  id: z.number(),
  opportunity: z.string().nonempty(),
  suggestion: z.string().nonempty(),
  type: KaizenTypeSchema,
  createDate: z.string().optional(),
  lastUpdate: z.string().optional(),
  openBy: z.string(),
  viewed: z.boolean(),
  status: KaizenStatusSchema,
  checkedComment: z.string().optional(),
  reportedBy: z.string().nonempty(),
  reviewer: z.string().optional(),
  cell: CellSchema,
  estimatedSavings: z.number().optional().nullable(),
  it: z.array(z.string()),
  spqdc: z.array(z.string()),
});

export const KaizenAddFormSchema = KaizenSchema.pick({
  estimatedSavings: true,
  opportunity: true,
  reportedBy: true,
  suggestion: true,
}).extend({
  typeId: z.number(),
  it: z.array(z.string()).min(1, 'Selezionare almeno un IT'),
  spqdc: z.array(z.string()).min(1, 'Selezionare almeno un SPQDC'),
});

export const KaizenStatusFormSchema = KaizenSchema.pick({
  checkedComment: true,
}).extend({
  statusId: z.number(),
});

export const KaizenSettingsImageSchema = z.object({
  id: z.number(),
  name: z.string(),
  path: z.string(),
});

export const KaizenSettingsSchema = z.object({
  itImage: KaizenSettingsImageSchema.optional(),
  spqdcImage: KaizenSettingsImageSchema.optional(),
});

export const KaizenSettingsFormSchema = z.object({
  itImage: z.array(KaizenSettingsImageSchema),
  spqdcImage: z.array(KaizenSettingsImageSchema),
});

export type KaizenStatus = z.infer<typeof KaizenStatusSchema>;
export type Kaizen = z.infer<typeof KaizenSchema>;
export type UserKaizenCount = z.infer<typeof UserKaizenCountSchema>;
export type KaizenCount = z.infer<typeof KaizenCountSchema>;
export type KaizenType = z.infer<typeof KaizenTypeSchema>;
export type KaizenAddForm = z.infer<typeof KaizenAddFormSchema>;
export type KaizenStatusForm = z.infer<typeof KaizenStatusFormSchema>;
export type KaizenSettings = z.infer<typeof KaizenSettingsSchema>;
export type KaizenSettingsForm = z.infer<typeof KaizenSettingsFormSchema>;
